import moment from 'moment';
import 'moment/locale/fr-ca';
import 'moment/locale/en-ca';
import 'moment/locale/es';

// Change some defaults for moment library
moment.defineLocale('en-moment', {
	parentLocale: 'en',
	calendar: {
		lastDay: '[yesterday at] LT',
		sameDay: '[today at] LT',
		nextDay: '[tomorrow at] LT',
		sameElse: '[at] LL',
	},
});

moment.defineLocale('fr-moment', {
	parentLocale: 'fr',
	calendar: {
		sameDay: '[aujourd’hui à] LT',
		nextDay: '[demain à] LT',
		nextWeek: '[le] dddd [à] LT',
		lastDay: '[hier à] LT',
		sameElse: '[le] LL',
	},
});

moment.defineLocale('es-moment', {
	parentLocale: 'es',
	calendar: {
		nextWeek: function() {
			return (
				'[el] dddd [a la' + ((this as any).hours() !== 1 ? 's' : '') + '] LT'
			);
		},
		sameElse: '[el] LL',
	},
});

export const GLOBAL_FALLBACK = 'fr_CA';
const SUPPORTED_VARIANTS = ['en_CA', 'es_ES', 'fr_CA'];
const DEFAULT_VARIANTS: { [prefix: string]: string } = {
	en: 'en_CA',
	fr: 'fr_CA',
	es: 'es_ES',
	// while waiting for Spain's variants, default all of them to es_ES:
	ca: 'es_ES',
	eu: 'es_ES',
	gl: 'es_ES',
};

export const GLOBAL_FALLBACK_FOR_MOMENT = 'fr_moment';
const DEFAULT_VARIANTS_FOR_MOMENT: { [prefix: string]: string } = {
	en: 'en_moment',
	fr: 'fr_moment',
	es: 'es_moment',
	// while waiting for Spain's variants, default all of them to es_ES:
	ca: 'es_moment',
	eu: 'es_moment',
	gl: 'es_moment',
};

function isVariantSupported(lang: string): boolean {
	return SUPPORTED_VARIANTS.includes(lang);
}

export function getFallbackVariantForLanguageCode(
	lang: string | undefined
): string {
	if (lang == null) {
		return GLOBAL_FALLBACK;
	}
	if (isVariantSupported(lang)) {
		return lang;
	} else {
		if (DEFAULT_VARIANTS[lang.substring(0, 2)] != null) {
			return DEFAULT_VARIANTS[lang.substring(0, 2)];
		} else {
			return GLOBAL_FALLBACK;
		}
	}
}

export function getFallbackVariantForMomentLocale(
	lang: string | undefined
): string {
	if (lang == null) {
		return GLOBAL_FALLBACK_FOR_MOMENT;
	}
	if (DEFAULT_VARIANTS_FOR_MOMENT[lang.substring(0, 2)] != null) {
		return DEFAULT_VARIANTS_FOR_MOMENT[lang.substring(0, 2)];
	} else {
		return GLOBAL_FALLBACK_FOR_MOMENT;
	}
}
