import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import * as jwt from 'jsonwebtoken';
import 'material-design-icons-iconfont';
// importing moment and its locales
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/es';
import {
  getFallbackVariantForLanguageCode,
  getFallbackVariantForMomentLocale,
  GLOBAL_FALLBACK,
} from './lang/fallbacks';
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import ColibrioReader from './components/reader/colibrio-reader.vue';
import { EXECUTION_ENV, VERSION } from './constants';
import './index.scss';
import i18n from './lang/lang';
import { BrowserDetector } from './lib/colibrio-publishing-framework/colibrio-core-base';
import './lib/colibrio-publishing-framework/colibrio-polyfill-dom';
import './lib/colibrio-publishing-framework/colibrio-polyfill-es6';
import './lib/colibrio-publishing-framework/colibrio-polyfill-textencoding';
import './lib/colibrio-publishing-framework/colibrio-polyfill-url';
import './lib/colibrio-publishing-framework/colibrio-polyfill-webanimation';
import { safe_atob } from './lib/tools';
import { createSession } from './model/api';
import { readerQueryInput } from './model/ReaderInput';
import { getSessionToken, setSessionToken } from './model/SessionManager';
import { IosNativePinchZoomPreventer } from './utils/platform-workarounds/IosNativePinchZoomPreventer';
import ReturnBookIcon from './components/return-icon.vue';
import { initPosthog } from './model/posthog';

initPosthog();

moment.locale(getFallbackVariantForMomentLocale(readerQueryInput.lang));

Vue.use(Vuetify, {
  iconfont: 'md',
  theme: {},
  customProperties: true,
});

Vue.filter('formatCalendar', (value: string) => {
  return value && value !== '' ? moment(value).calendar() : '';
});

// Max number of errors to report per session.
let errorsToReport = 5;

Sentry.init({
  dsn: 'https://89234139908443749e76e5c35912b049@sentry.io/1275988',
  maxBreadcrumbs: 50,
  release: VERSION,
  environment: EXECUTION_ENV,
  integrations: [
    new Integrations.ExtraErrorData(),
    new Integrations.Vue({ Vue, attachProps: true }),
  ],
  beforeSend(event: any, hint: any) {
    // if no hint of if errorsToReport has been atteined.
    if (hint && errorsToReport > 0) {
      const error = hint.originalException;
      const errorType = error && error.errorType;
      if (
        error != null &&
        (Array.isArray(error) || // Nos propres exceptions.
          (errorType &&
            (errorType.match(/ECONNABORTED/i) ||
              errorType.match(/ABORTED/i))) ||
          (error.message != null &&
            (error.message.match(/permission denied/i) ||
              error.message.match(/XDR encoding failure/i) ||
              error.message.match(/network error/i) ||
              error.message.match(/timeout of 0ms exceeded/i)))) // some network errors
      ) {
        return null;
      } else {
        errorsToReport--;
        return event;
      }
    } else {
      return null;
    }
  },
} as any);

if (document.body) {
  init();
} else {
  document.addEventListener('DOMContentLoaded', init, false);
}

function launchApp() {
  const app = new Vue({
    el: '#demo-app',
    i18n,
    components: {
      ColibrioReader,
    },
  });
}

async function init() {
  if (BrowserDetector.isOS('iOS')) {
    const iosPinchZoomPreventer = new IosNativePinchZoomPreventer();
    iosPinchZoomPreventer.observe();
  }
  // Keep the old method working while we transition
  if (readerQueryInput.token) {
    const decodedToken = jwt.decode(readerQueryInput.token) as any;
    if (getSessionToken(decodedToken.sub)) {
      launchApp();
    } else {
      createSession(readerQueryInput.token)
        .then((sessionToken) => {
          setSessionToken(decodedToken.sub, sessionToken);
          launchApp();
        })
        .catch((e) => {
          if (
            readerQueryInput.signinUrl != null &&
            readerQueryInput.signinUrl.length > 0
          ) {
            window.location.href = safe_atob(readerQueryInput.signinUrl!);
          }
        });
    }
  } else {
    launchApp();
  }
}
