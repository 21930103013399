/*
 * The URL of the assets that comes in the manifest can be rewritten
 * for testing and debugging purposes. Change this file if you need
 * to add rewriting
 */

export function rewriteAssetUrl(url: string): string {
  // Development:
  let newUrl = url.replace(
    'https://colibrio-local-proxy.s3.amazonaws.com/',
    'https://orp.cantook.net/webreader-s3/'
  );

  newUrl = url.replace(
    'https://storage.googleapis.com/',
    'https://orp.cantook.net/webreader/'
  );

  return newUrl;
}
