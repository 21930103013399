import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class ReaderMessagebox extends Vue {
  @Prop(Boolean) activateObj?: boolean;
  @Prop(String) headMessage?: string;
  @Prop(String) bodyMessage?: string;
  @Prop({ default: false }) hideButtons?: boolean;
  @Prop(Boolean) fullscreen?: boolean;
  @Prop({ default: true }) hideCancel?: boolean;
  @Prop({ default: 'Ok' }) okMessage?: string;
  @Prop({ default: 'Cancel' }) cancelMessage?: string;

  public constructor() {
    super();
  }

  public mounted(): void {}

  public ok(): void {
    this.$emit('ok');
  }

  public cancel(): void {
    this.$emit('cancel');
  }
}
