import * as jwt from 'jsonwebtoken';
import { QueryParameters } from '../lib/params';
import md5 from 'md5';
import * as Cookie from 'js-cookie';

const ANON_SID_COOKIE_PREFIX = '_crAnonSid';

/**
 * The initial input for the webreader.
 * They will come from a JWT token, as described in the README, but we
 * will support query parameters during transition (and perhaps allow some overwriting)
 * Notice that sessions and token expiration are not managed here
 */

interface ReaderInput {
  // New interface! use only this field in the future:
  token?: string;

  // Old interface! to be deprecated!
  transactionId?: string; // the manifest url
  authenticatedManifestUrl?: string; // an authenticated url that returns the manifestUrl
  signinUrl?: string; // URL where to redirect the user if authentication fails
  lang?: string; // the language to use
  sessionId: string;
  providerId?: string;
  bundleId?: string;
  issuer?: string;
}

function getLinkFromJwtObject(object: any, rel: string): any | null {
  if (!object || !object.links) {
    return null;
  } else {
    return (object.links as any[]).find((v) => v.rel === rel);
  }
}

const rawToken = QueryParameters.getParameter('token');

const decodedToken =
  rawToken && (jwt.decode(rawToken as string) as { [claim: string]: string });

const transactionId = decodedToken
  ? decodedToken.sub
  : QueryParameters.getParameter('m');

export const readerQueryInput: ReaderInput = {
  token: rawToken,
  transactionId,
  authenticatedManifestUrl: QueryParameters.getParameter('am'),
  signinUrl: decodedToken
    ? getLinkFromJwtObject(decodedToken, 'authenticate')
    : QueryParameters.getParameter('si'),
  sessionId: decodedToken
    ? decodedToken.sid
    : QueryParameters.getParameter('sid') ||
      getAnonSid(transactionId as string),
  providerId: decodedToken
    ? decodedToken.pid
    : QueryParameters.getParameter('pid'),
  bundleId: decodedToken
    ? decodedToken.bid
    : QueryParameters.getParameter('bid'),
  issuer: decodedToken && decodedToken.iss,
  lang: languageCodeFromParameter(
    QueryParameters.getParameter('l'),
    decodedToken && decodedToken.language
  ),
};

function languageCodeFromParameter(
  languageCodeFromQuery: string | undefined,
  languageCodeFromToken: string | undefined
): string | undefined {
  let languageCode = languageCodeFromQuery || languageCodeFromToken;
  languageCode = languageCode && languageCode.replace('-', '_');
  return languageCode;
}

// SID creation for an anonymous user
// If no SID is provided, it is created randomly and stored in a cookie for further use in the same browser
export function getAnonSid(transactionId: string) {
  const storedSID = Cookie.get(getCookieNameForAnonSid(transactionId));

  if (storedSID != null) {
    return storedSID;
  } else {
    const sid = generateSIDforAnonymous();
    Cookie.set(getCookieNameForAnonSid(transactionId), sid, {
      expires: 22,
    });
    return sid;
  }
}

function getCookieNameForAnonSid(transactionId: string): string {
  return `${ANON_SID_COOKIE_PREFIX}-${md5(transactionId)}`;
}

function dec2hex(dec: number) {
  return dec < 10 ? '0' + String(dec) : dec.toString(16);
}

function generateSIDforAnonymous(): string {
  const arr = new Uint8Array(10);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
}
