import { captureEvent } from '../../../model/posthog';
import deepEqual from 'fast-deep-equal';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { GlobalEventBus } from '../../../GlobalEventBus';
import { GlobalEventName } from '../../../GlobalEventName';
import { BrowserDetector } from '../../../lib/colibrio-publishing-framework/colibrio-core-base';
import {
  IPublicationStyleFontSet,
  IPublicationStyleOptions,
  IPublicationStylePalette,
  IReadingSystemEngineOptions,
} from '../../../lib/colibrio-publishing-framework/colibrio-readingsystem-base';
import { IPaginatedRendererOptions } from '../../../lib/colibrio-publishing-framework/colibrio-readingsystem-renderer';
import { readerModel } from '../../../model/ReaderModel';

@Component({
  components: {},
})
export default class ColibrioPublicationSettings extends Vue {
  public drawerOpen: boolean = false;
  public fontSize: number = 100;
  public lineHeight: number = 100;
  public nightMode: boolean = false;
  public pageMarginHorizontal: number = 4;
  public pageMarginVertical: number = 6;
  public timeoutId: number | undefined = undefined;

  public renderers: { text: string; value: string }[] = [];
  public currentRenderer: string = '';
  public currentPublicationFontSet: IPublicationStyleFontSet | null = null;
  public currentPublicationPalette: IPublicationStylePalette | null = null;
  public useDarkTheme: boolean = readerModel.useDarkMode;
  public inFullscreenState: boolean = false;
  public ignoreAspectRatio: boolean =
    BrowserDetector.isPlatform('mobile') &&
    !readerModel.readerPublicationData!.isFixedLayout;
  public showRendererBackgroundShadow: boolean = !(
    BrowserDetector.isPlatform('mobile') &&
    !readerModel.readerPublicationData!.isFixedLayout
  );
  public showPageTurnShadow: boolean = true;

  public publicationTheme: any = {
    palettes: [],
    fontSets: [],
  };

  public mounted() {
    GlobalEventBus.$on(
      GlobalEventName.APP_SETTINGS_DRAWER_OPENED,
      this.onAppSettingsDrawerOpened
    );
    GlobalEventBus.$on(
      GlobalEventName.APP_SETTINGS_DRAWER_CLOSED,
      this.onAppSettingsDrawerClosed
    );
    GlobalEventBus.$on(GlobalEventName.APP_EVENT_KEYUP_ESC, this.onKeyUpEscape);

    this.renderers = [
      {
        text: this.$i18n.t('settings.renderer.responsive') as string,
        value: '__responsive',
      },
      {
        text: this.$i18n.t('settings.renderer.flip') as string,
        value: 'flipbookRenderer',
      },
      {
        text: this.$i18n.t('settings.renderer.stack') as string,
        value: 'stackRenderer',
      },
      {
        text: this.$i18n.t('settings.renderer.scroll') as string,
        value: 'verticalScrollRenderer',
      },
    ];
    this.currentRenderer = '__responsive';
    this.currentPublicationFontSet = null;
    this.currentPublicationPalette = null;

    this.publicationTheme = {
      palettes: [
        {
          title: this.$i18n.t('settings.palette_themes.night'),
          palette: {
            backgroundLight: '#303030',
            backgroundDark: '#424242',
            foregroundLight: '#ffffffb3',
            foregroundDark: '#ffffff',
            accent: '#d48872',
          },
        },
        {
          title: this.$i18n.t('settings.palette_themes.sepia'),
          palette: {
            backgroundLight: '#f9e6d9',
            backgroundDark: '#e4d1c4',
            foregroundLight: '#776965',
            foregroundDark: '#4c413e',
            accent: '#7a5b48',
          },
        },
        {
          title: this.$i18n.t('settings.publication_default'),
          palette: null,
        },
      ],
      fontSets: [
        {
          title: this.$i18n.t('settings.font_sets.all_sans'),
          fontSet: {
            fontFaces: [
              {
                family: 'sans-serif',
              },
            ],
            defaults: {
              fallback: 'sans-serif',
            },
          },
        },
        {
          title: this.$i18n.t('settings.publication_default'),
          fontSet: null,
        },
      ],
    };

    document.addEventListener('fullscreenchange', this.updateFullscreenState);
    document.addEventListener('fullscreenerror', this.updateFullscreenState);
    window.addEventListener('resize', this.updateFullscreenState);
    this.updateFullscreenState();
  }

  public destroyed() {
    GlobalEventBus.$off(
      GlobalEventName.APP_SETTINGS_DRAWER_OPENED,
      this.onAppSettingsDrawerOpened
    );
    GlobalEventBus.$off(
      GlobalEventName.APP_SETTINGS_DRAWER_CLOSED,
      this.onAppSettingsDrawerClosed
    );
    GlobalEventBus.$off(
      GlobalEventName.APP_EVENT_KEYUP_ESC,
      this.onKeyUpEscape
    );
    document.removeEventListener(
      'fullscreenchange',
      this.updateFullscreenState
    );
    document.removeEventListener('fullscreenerror', this.updateFullscreenState);
    window.removeEventListener('resize', this.updateFullscreenState);
  }

  public onKeyUpEscape() {
    if (this.drawerOpen) {
      GlobalEventBus.$emit(GlobalEventName.APP_SETTINGS_DRAWER_CLOSE_INTENT);
    }
  }

  public onAppSettingsDrawerClosed() {
    this.drawerOpen = false;
  }

  public onAppSettingsDrawerOpened() {
    this.drawerOpen = true;
  }

  public updateFullscreenState() {
    this.inFullscreenState =
      document.fullscreenElement !== null ||
      Math.abs(window.innerHeight - screen.height) <= 3;
  }

  @Watch('fontSize')
  public onFontSizeChanged() {
    this.updateStyleOptions();
  }

  @Watch('lineHeight')
  public onLineHeightChanged() {
    this.updateStyleOptions();
  }

  @Watch('nightMode')
  public onNightModeChanged() {
    this.updateStyleOptions();
  }

  @Watch('pageMarginVertical')
  public onPageMarginVerticalChanged() {
    this.updateStyleOptions();
  }

  @Watch('pageMarginHorizontal')
  public onPageMarginHorizontalChanged() {
    this.updateStyleOptions();
  }

  @Watch('ignoreAspectRatio')
  public onIgnoreAspectRatioChanged() {
    this.updateStyleOptions();
  }

  @Watch('showRendererBackgroundShadow')
  public onShowRendererBackgroundShadowChanged() {
    this.updateStyleOptions();
  }

  @Watch('showPageTurnShadow')
  public onShowPageTurnShadowChanged() {
    this.updateStyleOptions();
  }

  public toggleFullScreen() {
    if (document.documentElement.requestFullscreen) {
      if (document.fullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      } else {
        document.documentElement.requestFullscreen();
      }
    }
  }

  private updateStyleOptions() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }

    this.timeoutId = window.setTimeout(() => {
      let readingSystem = readerModel.getReadingSystem();
      let updatedPublicationOptions: IPublicationStyleOptions = {
        palette: this.currentPublicationPalette || undefined,
        fontSet: this.currentPublicationFontSet || undefined,
        fontSizeScaleFactor: isNaN(this.fontSize) ? 1 : this.fontSize / 100,
        lineHeightScaleFactor: isNaN(this.lineHeight)
          ? 1
          : this.lineHeight / 100,
        pageMarginHorizontal: isNaN(this.pageMarginHorizontal)
          ? 4
          : this.pageMarginHorizontal,
        pageMarginVertical: isNaN(this.pageMarginVertical)
          ? 6
          : this.pageMarginVertical,
      };
      let pageBackgroundColor = this.nightMode ? '#202020' : '#fff';

      if (readingSystem) {
        let currentOptions: IReadingSystemEngineOptions =
          readingSystem.getOptions();
        let publicationsOptionsAreDirty = !deepEqual(
          currentOptions.publicationStyleOptions,
          updatedPublicationOptions
        );

        if (publicationsOptionsAreDirty) {
          readingSystem.setOptions({
            publicationStyleOptions: updatedPublicationOptions,
          });
        }

        let updatedRendererOptions: Partial<IPaginatedRendererOptions> = {
          ignoreAspectRatio: this.ignoreAspectRatio,
          pageBackgroundColor: pageBackgroundColor,
          showRendererBackgroundShadow: this.showRendererBackgroundShadow,
          showPageTurnShadow: this.showPageTurnShadow,
        };

        readingSystem
          .getViews()
          .forEach((view) => view.setRendererOptions(updatedRendererOptions));

        const loggedOptions = {
          publicationStyle: updatedPublicationOptions,
          appStyle: updatedRendererOptions,
        };

        captureEvent('update-settings', loggedOptions);
      }

      GlobalEventBus.$emit(GlobalEventName.APP_STYLE_OPTIONS_UPDATED, {
        pageBackgroundColor,
      });
    }, 500);
  }

  private switchAppTheme() {
    GlobalEventBus.$emit(GlobalEventName.APP_SETTINGS_THEME_CHANGED, {
      useDarkTheme: this.useDarkTheme,
    });
  }

  private onPageRendererSelected(rendererName: string) {
    GlobalEventBus.$emit(
      GlobalEventName.READER_RENDERER_CHANGE_INTENT,
      rendererName
    );
  }
}
