import * as md5 from 'md5';

export class QueryParameters {
  public static getParameter(
    name: string,
    defaultValue?: string
  ): string | undefined {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) {
      return defaultValue;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  public static getParameterWithDefault(
    name: string,
    defaultValue: string
  ): string {
    return QueryParameters.getParameter(name, defaultValue) as string;
  }

  public static getUrlMd5(): string {
    return md5(window.location.href);
  }
}
