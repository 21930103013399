import axios from '../../node_modules/axios';
import { BACKEND_URL, CHECKPOINT_API_URL } from '../constants';
import { safe_atob } from '../lib/tools';
import { Locator } from './Checkpoint';
import { Manifest } from './Manifest';
import { PublicationState } from './PublicationState';
import { HttpRequest } from '../utils/io/http/HttpRequest';

// Get the manifest
export async function getManifest(manifestUrl: string): Promise<Manifest> {
  return (await axios.get<Manifest>(manifestUrl)).data;
}

// return the publication referenced by the manifest
// Return whether or not the publication was returned.
export async function returnPublication(manifest: Manifest): Promise<boolean> {
  if (manifest.return_url) {
    try {
      await axios.post(manifest.return_url!, undefined, {
        responseType: 'json',
      });
      return true;
    } catch (e) {
      return false;
    }
  } else {
    return false;
  }
}

// returns the publication full contents as an ArrayBuffer
function appendBuffer(buffer1: ArrayBuffer, buffer2: ArrayBuffer): ArrayBuffer {
  const tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
  tmp.set(new Uint8Array(buffer1), 0);
  tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
  return tmp.buffer as ArrayBuffer;
}

// This gets the full publication, breaking it in two calls
// to bypass possible obfuscation. (Assumes full content length > 1)
export async function getFullPublicationData(
  length: number,
  url: string
): Promise<ArrayBuffer> {
  const firstByteArray = await HttpRequest.sendRangeRequest(url, 0, 0);
  const restArray = await HttpRequest.sendRangeRequest(url, 1, length - 1);
  return appendBuffer(firstByteArray, restArray);
}

// get the manifest URL from an authenticated URL
export async function getManifestUrl(manifestEndPoint: string) {
  const amResponse = await axios.get<{ manifest: string }>(manifestEndPoint, {
    withCredentials: true,
  });
  // if everything goes ok, we are here
  return safe_atob(amResponse.data.manifest);
}

export async function setCheckpoint(
  locator: Locator,
  resourceId: string,
  providerId: string,
  sessionId: string,
  bundleId?: string
) {
  const url = `${CHECKPOINT_API_URL}/v2`;
  const params: any = {
    realm: 'ebook',
    provider: providerId,
    session: sessionId,
    resource: resourceId,
  };
  if (bundleId != null) {
    params.bundle = bundleId;
  }
  await axios.post(url, locator, { params });
}

export async function getLatestCheckpoint(
  resourceId: string,
  providerId: string,
  sessionId: string
): Promise<Locator> {
  const url = `${CHECKPOINT_API_URL}/v2/latest`;
  const params: any = {
    realm: 'ebook',
    provider: providerId,
    session: sessionId,
    resource: resourceId,
  };

  const response = await axios.get<Locator>(url, { params });
  return response.data;
}

export async function createSession(integratorToken: any): Promise<any> {
  const response = await axios.post<any>(`${BACKEND_URL}/createsession`, {
    integratorToken,
  });
  return response.data;
}
