import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class DemarqueLoader extends Vue {
  // ! is a non-null assertion operator. It is a way to tell
  // the compiler "this expression cannot be null or undefined".
  @Prop({ type: String, default: '18px' }) size!: string;
  spinnerSize: { width: string; height: string };

  constructor() {
    super();
    this.spinnerSize = {
      width: this.size,
      height: this.size,
    };
  }
}
