import Vue from 'vue';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import FeatureSwitchsMixin from '../../../featureSwitchsMixin';
import { GlobalEventBus } from '../../../GlobalEventBus';
import { GlobalEventName } from '../../../GlobalEventName';
import {
  AnnotationSelectorType,
  AnnotationTargetSideBias,
  IAnnotationTarget,
  IFragmentSelector,
} from '../../../lib/colibrio-publishing-framework/colibrio-core-annotation';
import {
  BrowserDetector,
  ColibrioError,
  KeyCode,
  Logger,
} from '../../../lib/colibrio-publishing-framework/colibrio-core-base';
import {
  MediaType,
  MediaTypeCategory,
} from '../../../lib/colibrio-publishing-framework/colibrio-core-io-base';
import {
  ContentDocumentLayout,
  PageProgressionDirection,
} from '../../../lib/colibrio-publishing-framework/colibrio-core-publication-base';
import {
  IContentBlock,
  ICustomViewContent,
  IMouseEngineEvent,
  INavigationIntentEngineEvent,
  IPointerEngineEvent,
  IReaderDocumentLocationTarget,
  IReaderPublication,
  IRenderableDocumentPageNavigationContext,
  ISelectionChangedEngineEvent,
  ISyncMediaEngineEventTargetXmlElementNodeData,
  ISyncMediaTimeline,
  ISyncMediaTimelinePosition,
  ISyncMediaTimelinePositionData,
  IView,
  IViewAnnotationLayer,
  IViewAnnotationTarget,
  IViewAnnotationTargetMouseEngineEvent,
  IViewEngineEvent,
  IVisiblePage,
  NavigationCollectionType,
  PageProgressionTimelineType,
  ReaderDocumentEventState,
  SyncMediaPlayerSeekType,
  SyncMediaTimelinePositionAnchorPoint,
  ViewState,
} from '../../../lib/colibrio-publishing-framework/colibrio-readingsystem-base';
import {
  ResponsiveViewRule,
  SyncMediaTimelinePosition,
} from '../../../lib/colibrio-publishing-framework/colibrio-readingsystem-engine';
import { IEpubReaderPublication } from '../../../lib/colibrio-publishing-framework/colibrio-readingsystem-formatadapter-epub';
import {
  FlipBookRenderer,
  StackRenderer,
  VerticalScrollRenderer,
} from '../../../lib/colibrio-publishing-framework/colibrio-readingsystem-renderer';
import { AnnotationUtils } from '../../../model/AnnotationUtils';
import { Locator } from '../../../model/Checkpoint';
import {
  AnnotationMotivation,
  IUserAnnotationHighlight,
  IUserAnnotationTarget,
} from '../../../model/IUserAnnotation';
import { readerModel } from '../../../model/ReaderModel';
import { TtsUtteranceProvider } from '../../../utils/tts/TtsUtteranceProvider';
import { WebSpeechTtsSynthesizer } from '../../../utils/tts/WebSpeechTtsSynthesizer';
import ColibrioImageZoom from '../image-zoom/colibrio-image-zoom.vue';
import { captureEvent } from '../../../model/posthog';

declare var nw: any;
declare var chrome: any;

@Component({
  components: {
    ColibrioImageZoom,
  },
})
export default class ColibrioPublicationView extends Mixins(
  FeatureSwitchsMixin
) {
  currentPageNumbers = '';
  hasExactPageNumbers = false;
  hasPageList = false;

  /**
   * This is our main view for reading the linear content of the Publication
   */
  mainView: IView | undefined;

  mainViewTimelineValue: number | null = null;
  mainViewTimelineLength: number | null = null;

  /**
   * Circumvents a vue/vuetify bug that causes @change event to be emitted twice per click.
   */
  mainViewTimelineLastChangeValue: number = -1;

  /**
   * This reader view is used for reading non-linear content activated by clicking on links in the Publication
   */
  popupReaderView: IView | undefined;

  externalHref: string | null = null;
  isPopupReaderDialogActive: boolean = false;
  isExternalHrefDialogActive: boolean = false;
  isImageZoomDialogActive: boolean = false;

  @Prop()
  isResizingDisabled!: boolean;

  @Prop()
  initialLocator!: Locator;

  zoomedImageUrl: string | null = null;
  revokeZoomedImageUrl: (() => void) | undefined = undefined;

  isClosePublicationDialogActive: boolean = false;

  syncMediaViewAnnotationLayer: IViewAnnotationLayer | undefined = undefined;
  isSyncMediaSupported: boolean = false;
  isSyncMediaPlayerControlsVisible: boolean = false;
  syncMediaPlayerControlsToggleCounter = 0;
  syncMediaPlayerPlaying: boolean = false;
  syncMediaPlayerMuted: boolean = false;
  syncMediaPlayerPlaybackVolume: number = 70;
  isSyncMediaVolumeUiVisible: boolean = false;
  syncMediaPlayerPlaybackRate: number = 100;
  syncMediaRateUiVisible: boolean = false;
  syncMediaTempPauseTimeoutId: number | null = null;
  syncMediaPlayerWaiting: boolean = false;
  syncMediaPageTurnTimeoutId: number | null = null;
  timelinePromise: Promise<ISyncMediaTimeline | null> | undefined = undefined;
  isSyncMediaInVisiblePages: boolean = false;

  clientDeviceIsMobile: boolean = BrowserDetector.isPlatform('mobile');

  isAnnotationOptionsDialogActive: boolean = false;
  activeTextSelection: string | undefined = undefined;

  @Watch('isAnnotationOptionsDialogActive')
  onAnnotationOptionsDialogVisibleChange(value: boolean, _oldValue: boolean) {
    if (!value) {
      this.annotationOptionsDialogFormClear();
    }
  }

  annotationOptionsDialogForm: HTMLElement | undefined = undefined;
  annotationEditButtonIsVisible: boolean = false;
  annotationLayer: IViewAnnotationLayer | undefined = undefined;
  annotationLayerCurrentSelectionEvent:
    | ISelectionChangedEngineEvent
    | undefined = undefined;
  annotationLayerCurrentSelectedAnnotation: IViewAnnotationTarget | undefined =
    undefined;
  annotationLayerSelectorToTargetMap: Map<string, IViewAnnotationTarget> =
    new Map();
  annotationLayerHighlightColorMap: Map<string, string> = new Map();
  annotationEditButton: Vue | undefined = undefined;
  annotationEditIntentTimeoutHandle: number | undefined = undefined;

  isZoomModeActive: boolean = false;
  lastPointerDownEvent: IPointerEngineEvent | undefined = undefined;
  lastPointerUpEvent: IPointerEngineEvent | undefined = undefined;
  timelineEscapePosition: ISyncMediaTimelinePositionData | undefined =
    undefined;
  hasTimelineEscapePosition: boolean = false;

  isNavDrawerOpen: boolean = false;
  isSettingsDrawerOpen: boolean = false;

  appNotificationMessage: string = '';
  annotationStyling = readerModel.annotationStyling;

  public get isModalDialogActive(): boolean {
    return (
      this.isAnnotationOptionsDialogActive ||
      this.isClosePublicationDialogActive ||
      this.isExternalHrefDialogActive ||
      this.isImageZoomDialogActive ||
      this.isPopupReaderDialogActive ||
      this.isNavDrawerOpen ||
      this.isSettingsDrawerOpen ||
      this.isClosePublicationDialogActive
    );
  }

  /**
   * Setup our mainView and configure the different renderers we will support.
   */
  createMainView() {
    let readingSystem = readerModel.getReadingSystem();
    if (!readingSystem) {
      console.error('ReadingSystem not set!');
      return;
    }
    let readerViewElement = this.$refs.readerViewElement as HTMLElement;

    /*
     * You can load several publications simultaneously with a single reading system.
     * In this demo implementation there is always one publication.
     */
    let readerPublication = readingSystem.getReaderPublications()[0];

    /*
     * We use a View instance to render our main content.
     * The View is a "manager" for Renderers and makes it easy for us to
     * select a Renderer implementation depending on the device screen size and orientation.
     *
     * By default, the view will be in "responsive" mode and select renderer based on which renderer that can use as much as possible of the available
     * target element area.
     *
     * For example, the view may use the FlipBookRenderer when orientation is landscape,
     * and StackRenderer when orientation is portrait.
     */
    this.mainView = readingSystem.createView({
      /**
       * This setting will enable the the built-in swipe left/right gestures for navigating the book.
       */
      enablePointerEventListener: true,

      name: 'mainView',

      /**
       * We enable the navigation gestures for 'touch' only.
       */
      viewInteractionPointerTypes: {
        touch: true,
      },

      pageProgressionTimelineOptions: {
        allowProgressiveRefinement: false,
        forceCompleteRendition: false,
      },
      viewStateOptions: {
        transformStateOptions: {
          maxPanOffsetHorizontal: '50%',
          maxPanOffsetVertical: '50%',
          viewInteractionPointerTypes: {
            mouse: true,
            touch: true,
          },
        },
      },
    });

    /*
     * The FlipBookRenderer is the "bookish" renderer where 2 pages are displayed side-by-side
     */
    let flipbookRenderer = new FlipBookRenderer({
      name: 'flipbookRenderer',
      disableAnimations: false,
      ignoreAspectRatio:
        this.clientDeviceIsMobile &&
        !readerModel.readerPublicationData!.isFixedLayout,
      showRendererBackgroundShadow: !(
        this.clientDeviceIsMobile &&
        !readerModel.readerPublicationData!.isFixedLayout
      ),
    });

    /*
     * When we add the renderer to mainView, we can also pass a ResponsiveViewRule that acts as a filter when the view selects which renderer to use.
     * The ReaderViewport rule takes a media query string, or a function callback.
     *
     * In this case we tell the view to use this renderer only if the min-width is 600px.
     */
    this.mainView.addRenderer(
      flipbookRenderer,
      new ResponsiveViewRule('(min-width: 600px)')
    );

    let stackRenderer = new StackRenderer({
      name: 'stackRenderer',
      disableAnimations: false,
      ignoreAspectRatio:
        this.clientDeviceIsMobile &&
        !readerModel.readerPublicationData!.isFixedLayout,
      showRendererBackgroundShadow: !(
        this.clientDeviceIsMobile &&
        !readerModel.readerPublicationData!.isFixedLayout
      ),
    });

    this.mainView.addRenderer(
      stackRenderer,
      new ResponsiveViewRule('(min-width: 300px)')
    );

    /*
     * Sometimes it is useful to create several renderer instances of the same type.
     * On this StackRenderer instance, we set the option { ignoreAspectRatio: true }
     * and use a ResponsiveViewRule to specifically target small screens.
     * (We also have a @media query in the vue CSS in this file to hide the previous/next arrows in the app)
     *
     * The result is that for small screens, we will use as much as possible of the device screen for displaying the publication.
     *
     * let stackViewIgnoreAspectRatio = new StackRenderer({
     *      ignoreAspectRatio: true,
     *      name: 'stackRendererIgnoreAspectRatio'
     * });
     * this.mainView.addRenderer(stackViewIgnoreAspectRatio, new ResponsiveViewRule('(min-width: 300px) and (max-width: 450px)'));
     */

    let scrollRenderer = new VerticalScrollRenderer({
      overflowY: 'auto',
      name: 'verticalScrollRenderer',
    });
    // We add this renderer to the view so we can get it by name, but it will not be used when in responsive mode.
    this.mainView.addRenderer(
      scrollRenderer,
      new ResponsiveViewRule(() => false)
    );

    /*
     * Here we tell the view which content documents it should render.
     * You have full control of which documents to render into a view, and which order to render them.
     *
     * We can re-order the documents in the array to present them in another order:
     * - readerDocuments = readerDocuments.filter(myFilter).reverse()
     *
     * You can even mix content documents from multiple publications:
     * - readerDocuments = readerPublication1.getSpine().concat(readerPublication2.getSpine())
     *
     * In the code below we filter out any document which is set as non-linear.
     * Non-linear content documents are shown in a popup view instead. See goToDocumentLocationTarget() method
     * for this implementation.
     */
    let readerDocuments = readerPublication
      .getSpine()
      .filter((readerDocument) => {
        return readerDocument.getSourceContentDocument().isInLinearContent();
      });
    this.mainView.setReaderDocuments(readerDocuments);

    /**
     * Now lets setup custom page content for the various IView.setViewContent*() methods.
     */

    /**
     * If the viewport is to small, lets notify the user
     */
    this.mainView.setViewContentOnActiveRendererMissing(
      '<p>Viewport too small</p>'
    );

    this.mainView.setViewContentOnLoading(
      `<demarque-loader>${this.$i18n.t('viewer.loading')}</demarque-loader>`
    );

    /**
     * When the reading system injects empty pages to obey left/right spread slot. Show this text.
     */
    this.mainView.setViewContentOnEmptyPage(
      '<p>This page is intentionally left blank</p>'
    );

    /**
     * If there was a problem loading publication content, notify the user and let her retry.
     */
    let refreshCallback = () => {
      if (this.mainView) {
        this.mainView.refresh(true);
      }
    };
    let errorPage: ICustomViewContent = {
      renderTo(element: HTMLElement) {
        element.innerHTML = `
                        <p>An error occurred while loading this page :(</p>
                        <button>Retry</button>
                    `;

        element
          .querySelector('button')!
          .addEventListener('click', refreshCallback);
      },

      onRemoved(element: HTMLElement) {
        element
          .querySelector('button')!
          .removeEventListener('click', refreshCallback);
      },
    };
    this.mainView.setViewContentOnLoadError(errorPage);

    /*
     * Set which DOM Element the mainView should render to.
     * It is up to you to set the width and height of this element. mainView will not expand/style this element.
     *
     * In this demo, we have styled the readerViewElement using flex-box so that its size is responsive..
     */
    this.mainView.renderTo(readerViewElement);

    /*
     * We haven't told mainView where we want to start reading yet, so at the moment the view will not render anything.
     *
     * Calling goToStart() will tell the view to go to the first page of the first document in the list of documents we passed in the call above.
     *
     * The colibrio publishing framework uses annotation targets extensively for targeting content in publications.
     *
     * If we wanted to continue reading from the last session, we would call readerView.goTo(lastReadingPosition) where lastReadingPosition is an IAnnotationTarget object;
     * The current reading position can be retrieved anytime by calling this.readerView.getCurrentAnnotationTarget()
     */
    if (this.initialLocator) {
      let startAnnotationTarget: IAnnotationTarget = {
        selector: {
          type: AnnotationSelectorType.FRAGMENT_SELECTOR,
          value: this.initialLocator.locations!.fragments![0],
        } as IFragmentSelector,
      };
      this.mainView.goTo(startAnnotationTarget).catch((err) => {
        if (!ColibrioError.isColibrioAbortedError(err)) {
          console.error(err);
        } else if (this.mainView) {
          this.mainView.goToStart();
        }
      });
    } else {
      this.mainView.goToStart().catch((err) => {
        if (!ColibrioError.isColibrioAbortedError(err)) {
          console.error(err);
        }
      });
    }

    // Use publication page-list if available to show the page numbers from a printed edition.
    readerPublication
      .fetchPublicationNavigation()
      .then((publicationNavigation) => {
        let pageListCollection = publicationNavigation
          .getNavigationCollections()
          .find(
            (collection) =>
              collection.type === NavigationCollectionType.PAGE_LIST
          );
        if (pageListCollection && pageListCollection.children) {
          this.hasPageList = true;
        }
      });

    // Use publication page-list if available to show the page numbers from a printed edition.
    readerPublication
      .fetchPublicationNavigation()
      .then((publicationNavigation) => {
        let pageListCollection = publicationNavigation
          .getNavigationCollections()
          .find(
            (collection) =>
              collection.type === NavigationCollectionType.PAGE_LIST
          );
        if (pageListCollection && pageListCollection.children) {
          this.hasPageList = true;
        }
      });

    this.isSyncMediaSupported =
      readerPublication.getSourcePublication().getMediaType() ===
        MediaType.APPLICATION_EPUB_ZIP ||
      readerPublication.getSourcePublication().getMediaType() ===
        MediaType.APPLICATION_PDF;

    this.annotationLayerSetup();
    this.annotationOptionsDialogSetup();
    this.annotationButtonSetup();
  }

  /**
   * Setup all event handling with our view.
   */
  setupEventListeners() {
    let readingSystem = readerModel.getReadingSystem();

    if (!this.mainView || !readingSystem) {
      return;
    }

    /*
     * Normally, when a link is clicked in the Publication, the view will go to the content targeted by that link,
     * but only if that link points to a content documents we've told the view to render.
     *
     * In our case, non-linear content documents are not rendered into the readerView, so those links won't work.
     * Also clicks on links which points to external resources will not work either.
     *
     * Fortunately, each time a link is clicked in the Publication, Colibrio Reader SDK will generate a ReaderEvent with type 'navigationIntent'
     * to let us specify what should happen.
     *
     * We can attach an event listener directly on the readerView to handle events generated specifically by that view,
     * or we can attach a listener on the readingSystem to receive events from all reader views.
     *
     * In this demo we have both a main view (readerView) and a view for reading non-linear content (popupReaderView)
     * so lets attach the listener to the readingSystem to handle them in one single place.
     */
    readingSystem.addEngineEventListener<'navigationIntent'>(
      'navigationIntent',
      (navigationIntentEngineEvent: INavigationIntentEngineEvent) => {
        // Calling preventDefault() will prevent Colibrio Reader SDKs default behaviour for the navigationIntent event.
        navigationIntentEngineEvent.preventDefault();

        let pointerDeltaY = 0;
        let pointerDeltaX = 0;

        if (this.lastPointerDownEvent && this.lastPointerUpEvent) {
          pointerDeltaX = Math.abs(
            this.lastPointerUpEvent.screenX - this.lastPointerDownEvent.screenX
          );
          pointerDeltaY = Math.abs(
            this.lastPointerUpEvent.screenY - this.lastPointerDownEvent.screenY
          );
        }
        if (pointerDeltaY > 20 || pointerDeltaX > 20) {
          // Pointer has been dragged during pointer down, this is probably a pan gesture so we exit.
          return;
        }

        if (
          this.isZoomModeActive &&
          navigationIntentEngineEvent.view.getTransformManager().getScale() ===
            1
        ) {
          // If the user has pressed the "Zoom" button, the next click should always perform scaling.
          return;
        }
        /*
         * The navigationIntentEvent contains the property readerDocumentLocationTarget
         * which contains information about which content document the user wishes to navigate to.
         * It also contains lots of internal publication-specific data about where in the document to go to.
         *
         * It will be null if this is an externalNavigation, for example to another website.
         */
        let documentLocationTarget =
          navigationIntentEngineEvent.readerDocumentLocationTarget;
        let internalNavigation = navigationIntentEngineEvent.internalNavigation;

        if (internalNavigation && documentLocationTarget) {
          this.goToDocumentLocationTarget(documentLocationTarget);
        } else if (!internalNavigation && navigationIntentEngineEvent.href) {
          /*
           * Most engine events generated due to user input, such as mouse clicks, still fires asynchronously as the original target resides in an iframe with separate origin.
           * Due to this, most browsers will prevent open new tabs/windows if we try it inside this callback.
           *
           * We get around this limitation by asking the user if he/she wants to navigate to the specified URL using a dialog in the app.
           * When the user clicks anything in the app dialog, a "trusted" event will be fired and we can open a new tab/window.
           */
          this.showOpenUrlDialog(navigationIntentEngineEvent.href);
        }
      }
    );

    this.mainView.addEngineEventListener<'viewStateChanged'>(
      'viewStateChanged',
      (viewStateEvent) => {
        this.isZoomModeActive =
          viewStateEvent.newViewState === ViewState.TRANSFORM;

        // Hide the edit annotation button if it is visible;
        this.annotationEditButtonHide();
      }
    );

    this.mainView.addEngineEventListener<'pointerdown'>(
      'pointerdown',
      (event) => {
        this.lastPointerDownEvent = event;
        GlobalEventBus.$emit(
          GlobalEventName.READER_PUBLICATION_POINTER_DOWN,
          event
        );
      }
    );

    this.mainView.addEngineEventListener<'pointerup'>('pointerup', (event) => {
      this.lastPointerUpEvent = event;
      GlobalEventBus.$emit(
        GlobalEventName.READER_PUBLICATION_POINTER_UP,
        event
      );
    });

    this.mainView.addEngineEventListener('click', (evt) => {
      if (this.lastPointerDownEvent) {
        // Some browsers, send click events even though the pointer moves before the up event.
        // So we track how many pixels the pointer has moved since last pointer down to see if this is a real click event.
        let eventDeltaX = Math.abs(
          evt.screenX - this.lastPointerDownEvent.screenX
        );
        let eventDeltaY = Math.abs(
          evt.screenY - this.lastPointerDownEvent.screenY
        );

        if (eventDeltaX > 20 || eventDeltaY > 20) {
          // The pointer had moved to far during the pointer down/move event. Let's
          // treat this as a pan event and return.
          return;
        }
      }

      GlobalEventBus.$emit(GlobalEventName.READER_PUBLICATION_CLICK, evt);

      let isZoomed = evt.view.getTransformManager().getScale() > 1;
      if (this.isZoomModeActive) {
        if (!isZoomed) {
          this.zoomToPointerPosition(evt, 3);
        }
      } else if (
        !this.isSyncMediaPlayerControlsVisible &&
        evt.mediaResource &&
        evt.mediaResource.getMediaTypeCategory() === MediaTypeCategory.IMAGE &&
        evt.readerDocumentEventState ===
          ReaderDocumentEventState.NOT_PROCESSED &&
        evt.target &&
        evt.target.readerDocument &&
        evt.target.readerDocument.getSourceContentDocument().getLayout() ===
          ContentDocumentLayout.REFLOWABLE &&
        (!this.lastPointerUpEvent ||
          this.lastPointerUpEvent.readerDocumentEventState ===
            ReaderDocumentEventState.NOT_PROCESSED) &&
        this.zoomedImageUrl === null
      ) {
        let mediaResource = evt.mediaResource;
        mediaResource.createUrl().then((url) => {
          if (this.zoomedImageUrl) {
            // We could get here if we got two fast clicks on the same image before createUrl() returns.
            // Make sure we only keep one.
            mediaResource.revokeUrl(url);
            return;
          }
          this.zoomedImageUrl = url;
          this.isImageZoomDialogActive = true;
          this.revokeZoomedImageUrl = () => {
            mediaResource.revokeUrl(url);
          };
        });
      }
    });

    document.documentElement.addEventListener('keyup', this.onKeyUp);
    document.documentElement.addEventListener('keydown', this.onKeyDown);

    /*
     * Listen on "resize" events so we can scale the viewport and re-layout publication contents.
     *
     * Since there are several "resize" scenarios where re-layout is unwanted,
     * Colibrio Reader SDK doesn't automatically re-layout publications on resize.
     *
     * For example, clicking on a "Search" input field on a mobile device will bring up soft-keyboard which will trigger a resize event.
     * In this case we probably do not want to re-layout the contents..
     */
    window.addEventListener('resize', this.onResize);

    this.syncMediaEventListenerSetup();
    this.annotationLayerEventListenerSetup();
    this.pageProgressionTimelineSetupEventListeners();
    this.appUiSetUpEventHandlers();

    /*
     * We use a global Vue event-bus for various events in the app.
     */
    GlobalEventBus.$on(
      GlobalEventName.APP_NAV_DRAWER_NAV_ITEM_CLICKED,
      this.goTo
    );
    GlobalEventBus.$on(
      GlobalEventName.READER_RENDERER_CHANGE_INTENT,
      this.onRendererChangeIntent
    );
    GlobalEventBus.$on(
      GlobalEventName.READER_PUBLICATION_CLOSE_INTENT,
      this.showClosePublicationDialog
    );
    GlobalEventBus.$on(
      GlobalEventName.READER_VIEW_GOTO_FRAGMENT_SELECTOR,
      this.goToFragmentSelector
    );
  }

  destroyed() {
    document.documentElement.removeEventListener('keyup', this.onKeyUp);
    document.documentElement.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('resize', this.onResize);

    GlobalEventBus.$off(
      GlobalEventName.APP_NAV_DRAWER_NAV_ITEM_CLICKED,
      this.goTo
    );
    GlobalEventBus.$off(
      GlobalEventName.READER_RENDERER_CHANGE_INTENT,
      this.onRendererChangeIntent
    );
    GlobalEventBus.$off(
      GlobalEventName.READER_PUBLICATION_CLOSE_INTENT,
      this.showClosePublicationDialog
    );
    GlobalEventBus.$off(
      GlobalEventName.READER_VIEW_GOTO_FRAGMENT_SELECTOR,
      this.goToFragmentSelector
    );

    this.destroyPopupReaderView();
    this.destroyImageZoomDialog();
    if (this.mainView) {
      // Destroying the view will also remove all added engine event listeners.
      this.mainView.destroy();
      this.mainView = undefined;
    }
    this.timelinePromise = undefined;

    this.lastPointerDownEvent = undefined;
    this.lastPointerUpEvent = undefined;
    this.timelineEscapePosition = undefined;

    this.annotationLayerCurrentSelectionEvent = undefined;
  }

  onKeyDown(ev: KeyboardEvent) {
    let elementName: string = (ev.target as HTMLElement).localName as string;
    // Make it less likely that navigation key events interfere with voice over technologies by checking for active modifier keys.
    let modifierActive: boolean = this.isModifierKeyActive(ev);
    if (
      this.mainView &&
      !ev.defaultPrevented &&
      elementName !== 'textarea' &&
      elementName !== 'input'
    ) {
      if (
        ev.keyCode === KeyCode.KEY_RIGHT &&
        !modifierActive &&
        !this.isNavDrawerOpen &&
        !this.isSettingsDrawerOpen
      ) {
        this.navigateToRight();
      } else if (
        ev.keyCode === KeyCode.KEY_LEFT &&
        !modifierActive &&
        !this.isNavDrawerOpen &&
        !this.isSettingsDrawerOpen
      ) {
        this.navigateToLeft();
      }
    }
  }

  onKeyUp(ev: KeyboardEvent) {
    // Make it less likely that navigation key events interfere with voice over technologies by checking for active modifier keys.
    let modifierActive: boolean = this.isModifierKeyActive(ev);
    if (ev.keyCode === KeyCode.KEY_ESC && !modifierActive) {
      if (this.isSyncMediaPlayerControlsVisible) {
        this.toggleSyncMediaPlayer();
      }
      GlobalEventBus.$emit(GlobalEventName.APP_EVENT_KEYUP_ESC, ev.target);
    }
  }

  onResize(_ev: UIEvent) {
    if (!this.isModalDialogActive) {
      if (this.mainView) {
        this.mainView.refresh();
      }
      if (this.popupReaderView) {
        this.popupReaderView.refresh();
      }
    }
  }

  onRendererChangeIntent(rendererName: string) {
    if (this.mainView) {
      if (rendererName === '__responsive') {
        this.mainView.setResponsive(true);
      } else {
        let view = this.mainView.getRendererByName(rendererName);
        if (view) {
          this.mainView.setActiveRenderer(view);
        }
      }
    }
  }

  appUiSetUpEventHandlers() {
    if (this.mainView) {
      this.mainView.addEngineEventListener(
        'visiblePagesChanged',
        (evt: IViewEngineEvent) => {
          this.mainViewTimelineLastChangeValue = -1;
          this.appUiStateCurrentPageNumbersUpdate(evt);
          GlobalEventBus.$emit(
            GlobalEventName.READER_PUBLICATION_VISIBLE_PAGES_CHANGED
          );
        }
      );
    }
  }

  appUiStateCurrentPageNumbersUpdate(evt: IViewEngineEvent) {
    if (this.hasPageList) {
      let contextPromises = evt.view
        .getVisiblePages()
        .map((visiblePage: IVisiblePage) =>
          visiblePage.fetchNavigationContext()
        );
      Promise.all(contextPromises).then(
        (navigationContexts: IRenderableDocumentPageNavigationContext[]) => {
          let firstNavigationContext = navigationContexts[0];
          let lastNavigationContext =
            navigationContexts[navigationContexts.length - 1];

          let firstPageListItemReferences = firstNavigationContext
            .getNavigationItemReferences()
            .filter(
              (reference) =>
                reference.getNavigationCollection().type ===
                NavigationCollectionType.PAGE_LIST
            );
          let lastPageListItemReferences = lastNavigationContext
            .getNavigationItemReferences()
            .filter(
              (reference) =>
                reference.getNavigationCollection().type ===
                NavigationCollectionType.PAGE_LIST
            );

          let firstPageListItemReference =
            firstPageListItemReferences[0] ||
            firstNavigationContext.getNearestPreviousNavigationItem(
              NavigationCollectionType.PAGE_LIST
            );
          let lastPageListItemReference =
            lastPageListItemReferences[lastPageListItemReferences.length - 1] ||
            lastNavigationContext.getNearestPreviousNavigationItem(
              NavigationCollectionType.PAGE_LIST
            );

          let firstPageNumber = '';
          let lastPageNumber = '';
          if (firstPageListItemReference) {
            firstPageNumber = firstPageListItemReference
              .getNavigationItem()
              .textContent.trim();
          }
          if (lastPageListItemReference) {
            lastPageNumber = lastPageListItemReference
              .getNavigationItem()
              .textContent.trim();
          }

          if (
            firstPageNumber &&
            lastPageNumber &&
            firstPageNumber !== lastPageNumber
          ) {
            this.currentPageNumbers = `${firstPageNumber} - ${lastPageNumber}`;
          } else if (firstPageNumber) {
            this.currentPageNumbers = firstPageNumber;
          } else if (lastPageNumber) {
            this.currentPageNumbers = lastPageNumber;
          } else {
            this.currentPageNumbers = '';
          }
        }
      );
    }
  }

  pageProgressionTimelineSetupEventListeners() {
    if (this.mainView) {
      this.mainView.addEngineEventListener<'timelineChanged'>(
        'timelineChanged',
        (evt) => {
          let pageProgressionTimeline = evt.view.getPageProgressionTimeline();
          if (pageProgressionTimeline) {
            let currentSegment = pageProgressionTimeline.getCurrentSegment();
            this.mainViewTimelineLength = pageProgressionTimeline.getLength();

            if (
              this.mainViewTimelineValue === null ||
              this.mainViewTimelineValue < currentSegment.start + 1 ||
              this.mainViewTimelineValue > currentSegment.end + 1
            ) {
              if (currentSegment.end === this.mainViewTimelineLength - 1) {
                // If we are on the last page, make sure the progressbar is filled.
                this.mainViewTimelineValue = currentSegment.end + 1;
              } else {
                this.mainViewTimelineValue = currentSegment.start + 1;
              }
            }

            if (
              !this.hasPageList &&
              pageProgressionTimeline.getTimelineType() !==
                PageProgressionTimelineType.ESTIMATED_PAGES
            ) {
              this.hasExactPageNumbers = true;
              if (currentSegment.start !== currentSegment.end) {
                this.currentPageNumbers = `${currentSegment.start + 1} - ${
                  currentSegment.end + 1
                }`;
              } else {
                this.currentPageNumbers = '' + (currentSegment.start + 1);
              }
            }
          } else {
            this.mainViewTimelineLength = null;
            this.mainViewTimelineValue = null;
          }
        }
      );
    }
  }

  annotationLayerTargetClicked(event: IViewAnnotationTargetMouseEngineEvent) {
    this.annotationLayerCurrentSelectedAnnotation = event.viewAnnotationTarget;

    if (this.annotationEditIntentTimeoutHandle) {
      window.clearTimeout(this.annotationEditIntentTimeoutHandle);
    }

    this.annotationEditIntentTimeoutHandle = window.setTimeout(() => {
      this.annotationEditIntentTimeoutHandle = undefined;
      this.annotationEditButtonHide();
      this.annotationLayerCurrentSelectedAnnotation = undefined;
    }, 4000);

    this.annotationEditButtonShow();
  }

  annotationButtonSetup() {
    this.annotationEditButton = this.$refs.annotationEditButton as Vue;
  }

  annotationEditButtonShow() {
    this.annotationEditButtonIsVisible = true && this.fsAnnotations;
  }

  annotationEditButtonHide() {
    this.annotationEditButtonIsVisible = false;
  }

  annotationEditButtonClicked = () => {
    this.annotationOptionsDialogShow();
  };

  annotationDialogDeleteButtonClicked = () => {
    this.annotationLayerViewAnnotationTargetDelete();
  };

  annotationOptionsDialogShow(
    viewAnnotationTarget?: IViewAnnotationTarget<IUserAnnotationHighlight>
  ) {
    if (this.annotationOptionsDialogForm) {
      this.isAnnotationOptionsDialogActive = true;
      if (
        viewAnnotationTarget ||
        this.annotationLayerCurrentSelectedAnnotation
      ) {
        if (!viewAnnotationTarget) {
          viewAnnotationTarget = this.annotationLayerCurrentSelectedAnnotation!;
        }
        let annotationData = viewAnnotationTarget.getCustomData();
        if (annotationData) {
          let colorElementChecked =
            this.annotationOptionsDialogForm.querySelector(
              '[name="highlight-color"]:checked'
            )! as HTMLInputElement;
          let colorElement = this.annotationOptionsDialogForm.querySelector(
            '[value="' + annotationData.target.styleClass + '"]'
          )! as HTMLInputElement;
          let commentElement = this.annotationOptionsDialogForm.querySelector(
            '#colibrio-publication-view__annotation-options-dialog__form__comment'
          )! as HTMLInputElement;
          let selectorElement = this.annotationOptionsDialogForm.querySelector(
            '#colibrio-publication-view__annotation-options-dialog__form__selector'
          )! as HTMLInputElement;
          colorElementChecked.checked = false;
          colorElement.checked = true;
          commentElement.value = annotationData.body[0].value;
          selectorElement.value = annotationData.target.selector.value
            ? annotationData.target.selector.value
            : '';
        }
      } else {
        // We need to grab the selected text at this stage
        if (this.annotationLayerCurrentSelectionEvent) {
          let selectedTextElement =
            this.annotationOptionsDialogForm.querySelector(
              '#colibrio-publication-view__annotation-options-dialog__form__selected-text'
            )! as HTMLInputElement;
          if (selectedTextElement) {
            selectedTextElement.value =
              this.annotationLayerCurrentSelectionEvent.selectionText || '';
          }
        }
      }
      this.annotationEditButtonHide();
    }
  }

  annotationOptionsDialogHide(clear: boolean = true) {
    this.isAnnotationOptionsDialogActive = false;
    if (clear) {
      this.annotationOptionsDialogFormClear();
    }
  }

  annotationLayerSetup() {
    this.annotationLayer = this.mainView!.createAnnotationLayer('annotations');
    this.annotationLayer.setOptions({
      annotationLayerStyle: { 'mix-blend-mode': 'multiply' },
      viewAnnotationTargetInputEngineEventsEnabled: true,
    });

    this.annotationLayerHighlightColorMap.set(
      'red',
      this.annotationStyling.annotationHighlightColorRed
    );
    this.annotationLayerHighlightColorMap.set(
      'green',
      this.annotationStyling.annotationHighlightColorGreen
    );
    this.annotationLayerHighlightColorMap.set(
      'blue',
      this.annotationStyling.annotationHighlightColorBlue
    );
    this.annotationLayerHighlightColorMap.set(
      'yellow',
      this.annotationStyling.annotationHighlightColorYellow
    );
  }

  annotationLayerEventListenerSetup() {
    if (this.mainView) {
      this.mainView.addEngineEventListener(
        'selectionChanged',
        (ev: ISelectionChangedEngineEvent) => {
          if (this.isAnnotationOptionsDialogActive) {
            return;
          }
          if (ev.isRange) {
            this.annotationLayerCurrentSelectionEvent = ev;
            this.annotationLayerCurrentSelectedAnnotation = undefined;
            this.activeTextSelection = ev.selectionText || undefined;
            this.annotationEditButtonShow();
          } else {
            this.annotationLayerCurrentSelectionEvent = undefined;
            this.activeTextSelection = undefined;
            this.annotationEditButtonHide();
          }
        }
      );

      if (this.annotationLayer) {
        this.annotationLayer.addEngineEventListener(
          'viewAnnotationTargetContextMenu',
          (event: IViewAnnotationTargetMouseEngineEvent) => {
            this.annotationOptionsDialogShow(event.viewAnnotationTarget);
          }
        );
        this.annotationLayer.addEngineEventListener(
          'viewAnnotationTargetClick',
          this.annotationLayerTargetClicked
        );
      }

      this.mainView.addEngineEventListener<'viewStateChanged'>(
        'viewStateChanged',
        (viewStateEvent) => {
          this.isZoomModeActive =
            viewStateEvent.newViewState === ViewState.TRANSFORM;

          // Hide the edit annotation button if it is visible;
          this.annotationEditButtonHide();
        }
      );
    }
  }

  annotationLayerViewAnnotationTargetDelete() {
    if (this.annotationOptionsDialogForm && this.annotationLayer) {
      let selector = this.annotationOptionsDialogForm.querySelector(
        '#colibrio-publication-view__annotation-options-dialog__form__selector'
      )! as HTMLInputElement;
      if (selector.value !== '') {
        let annotationTarget = this.annotationLayerSelectorToTargetMap.get(
          selector.value
        );
        if (annotationTarget) {
          this.annotationLayer.removeViewAnnotationTarget(annotationTarget);
          this.annotationLayerSelectorToTargetMap.delete(selector.value);
        }
      }
    }
    GlobalEventBus.$emit(GlobalEventName.READER_ANNOTATION_LAYER_CHANGED);
    this.annotationOptionsDialogHide();
  }

  annotationOptionsDialogSetup() {
    this.annotationOptionsDialogForm = this.$refs
      .annotationOptionsDialogForm as HTMLElement;
  }

  annotationOptionsDialogFormSave() {
    if (this.annotationOptionsDialogForm) {
      let selectorElement = this.annotationOptionsDialogForm.querySelector(
        '#colibrio-publication-view__annotation-options-dialog__form__selector'
      )! as HTMLInputElement;
      let commentElement = this.annotationOptionsDialogForm.querySelector(
        '#colibrio-publication-view__annotation-options-dialog__form__comment'
      )! as HTMLInputElement;
      let colorElement = this.annotationOptionsDialogForm.querySelector(
        '[name="highlight-color"]:checked'
      )! as HTMLInputElement;
      let selectedTextElement = this.annotationOptionsDialogForm.querySelector(
        '#colibrio-publication-view__annotation-options-dialog__form__selected-text'
      )! as HTMLInputElement;

      switch (colorElement.value) {
        case 'annotation-highlight-red':
        case 'annotation-highlight-green':
        case 'annotation-highlight-blue':
        case 'annotation-highlight-yellow':
          break;
        default:
          console.log(
            'annotationOptionsDialogFormSave(): Illegal colorElement value ',
            colorElement.value
          );
          this.annotationOptionsDialogHide();
          return;
      }

      let annotationData: IUserAnnotationHighlight = {
        '@context': 'http://www.w3.org/ns/anno.jsonld',
        type: 'Annotation',
        motivation: AnnotationMotivation.COMMENTING,
        stylesheet: {
          type: 'CssStylesheet',
          value: `.annotation-highlight-red {
                        background-color: ${this.annotationStyling.annotationHighlightColorRed}
                      }
                      .annotation-highlight-green {
                        background-color: ${this.annotationStyling.annotationHighlightColorGreen}
                      }
                      .annotation-highlight-blue {
                        background-color: ${this.annotationStyling.annotationHighlightColorBlue}
                      }
                      .annotation-highlight-yellow {
                        background-color: ${this.annotationStyling.annotationHighlightColorYellow}
                      }`,
        },
        body: [
          {
            type: 'TextualBody',
            format: 'text/plain',
            value: commentElement.value,
            purpose: AnnotationMotivation.COMMENTING,
          },
          {
            type: 'TextualBody',
            format: 'text/plain',
            value: selectedTextElement.value,
            purpose: AnnotationMotivation.HIGHLIGHTING,
          },
        ],
        target: {
          selector: {
            conformsTo: 'http://www.idpf.org/epub/linking/cfi/epub-cfi.html',
            type: 'FragmentSelector',
            value: '',
          },
          styleClass: colorElement.value,
        } as IUserAnnotationTarget,
      };

      if (selectorElement.value !== '') {
        let viewAnnotationTarget = this.annotationLayerSelectorToTargetMap.get(
          selectorElement.value
        );
        if (viewAnnotationTarget) {
          let annotation: IUserAnnotationHighlight =
            viewAnnotationTarget.getCustomData();
          annotation.body[0].value = commentElement.value;
          annotation.target.styleClass = colorElement.value;
          viewAnnotationTarget.setCustomData(annotation);
          viewAnnotationTarget.setOptions({
            annotationTargetRangeClassName: colorElement.value,
            annotationTargetRangeStyle: {
              'background-color':
                AnnotationUtils.getTargetHighlightColor(annotation),
            },
          });
        }
      } else {
        if (this.annotationLayer && this.annotationLayerCurrentSelectionEvent) {
          let annotationTarget =
            this.annotationLayerCurrentSelectionEvent.annotationTarget;
          if (annotationTarget) {
            let viewAnnotationTarget =
              this.annotationLayer.addAnnotationTarget<IUserAnnotationHighlight>(
                annotationTarget
              );
            viewAnnotationTarget.setOptions({
              annotationTargetRangeClassName: colorElement.value,
              annotationTargetRangeStyle: {
                'background-color':
                  AnnotationUtils.getTargetHighlightColor(annotationData),
              },
            });
            let selector = (annotationTarget.selector! as IAnnotationTarget)
              .value as string;
            annotationData.target.selector.value = selector;
            viewAnnotationTarget.setCustomData(annotationData);
            this.annotationLayerSelectorToTargetMap.set(
              selector,
              viewAnnotationTarget
            );
          }
        }
      }
    }
    GlobalEventBus.$emit(GlobalEventName.READER_ANNOTATION_LAYER_CHANGED);
    this.annotationOptionsDialogHide();
  }

  annotationOptionsDialogFormClear() {
    if (this.annotationOptionsDialogForm) {
      (this.annotationOptionsDialogForm as HTMLFormElement).reset();
      let selectorElement = this.annotationOptionsDialogForm.querySelector(
        '#colibrio-publication-view__annotation-options-dialog__form__selector'
      )! as HTMLInputElement;
      let selectedTextElement = this.annotationOptionsDialogForm.querySelector(
        '#colibrio-publication-view__annotation-options-dialog__form__selected-text'
      )! as HTMLInputElement;
      selectorElement.value = '';
      selectedTextElement.value = '';
    }
  }

  /**
   * What we receive is an plain annotationTarget object (which has the same schema as "target" in the Web annotations spec.).
   *
   * We could just sent this object to readerView.goTo(), but we resolve it against the readerPublication to get a documentLocationTarget object instead.
   * We need this object to know if the target content document is a non-linear content document that should be opened in the popup reader.
   */
  goTo(annotationTarget: IAnnotationTarget) {
    let readerPublication = readerModel
      .getReadingSystem()
      .getReaderPublications()[0];
    if (readerPublication) {
      readerPublication
        .resolveToDocumentLocationTarget(annotationTarget)
        .then((documentLocationTarget) => {
          this.goToDocumentLocationTarget(documentLocationTarget);
        })
        .catch((err) => {
          if (!ColibrioError.isColibrioAbortedError(err)) {
            Logger.logError(err);
          }
        });
    }
  }

  toggleSyncMediaPlayer(): void {
    if (!this.mainView) {
      return;
    }
    let mainView = this.mainView;
    this.syncMediaPlayerControlsToggleCounter++;

    if (this.isSyncMediaPlayerControlsVisible) {
      this.toggleSyncMediaControlsUi(false);
      if (this.syncMediaPageTurnTimeoutId) {
        window.clearTimeout(this.syncMediaPageTurnTimeoutId);
        this.syncMediaPageTurnTimeoutId = null;
      }
      let mediaPlayer = mainView.getSyncMediaPlayer();
      if (mediaPlayer) {
        mediaPlayer.destroy();
      }
      if (this.syncMediaViewAnnotationLayer) {
        this.syncMediaViewAnnotationLayer.setVisible(false);
        this.syncMediaViewAnnotationLayer
          .getViewAnnotationTargets()
          .forEach((target) => target.destroy());
      }
    } else {
      this.toggleSyncMediaControlsUi(true);
      this.syncMediaPlayerWaiting = true;

      let toggleCounterValue = this.syncMediaPlayerControlsToggleCounter;

      this.fetchSyncMediaTimeline().then(async (timeline) => {
        if (
          timeline &&
          toggleCounterValue === this.syncMediaPlayerControlsToggleCounter
        ) {
          let readingPosition = mainView.getReadingPosition();
          let timelinePosition: ISyncMediaTimelinePosition | null = null;
          if (readingPosition) {
            try {
              timelinePosition =
                await timeline.fetchTimelinePositionFromAnnotationTarget(
                  readingPosition.getAnnotationTarget(),
                  AnnotationTargetSideBias.AFTER
                );
            } catch (e) {
              console.error(e);
            }
            if (
              toggleCounterValue !== this.syncMediaPlayerControlsToggleCounter
            ) {
              return;
            }
          }

          let syncMediaPlayer = mainView
            .getReadingSystemEngine()
            .createSyncMediaPlayer(timeline);

          if (this.syncMediaViewAnnotationLayer) {
            let currentViewAnnotationTarget: IViewAnnotationTarget | null =
              null;
            let lastTimelinePosition: ISyncMediaTimelinePosition | null = null;
            syncMediaPlayer.addEngineEventListener(
              'syncMediaPlayerTimelinePositionUpdated',
              (evt) => {
                if (
                  !lastTimelinePosition ||
                  lastTimelinePosition.getSegmentIndex() !==
                    evt.timelinePosition.getSegmentIndex()
                ) {
                  lastTimelinePosition = evt.timelinePosition;
                  evt.syncMediaPlayer
                    .getTimeline()
                    .fetchAnnotationTargetFromTimelinePosition(
                      evt.timelinePosition
                    )
                    .then((annotationTarget) => {
                      if (currentViewAnnotationTarget) {
                        currentViewAnnotationTarget.destroy();
                        currentViewAnnotationTarget = null;
                      }
                      if (this.syncMediaViewAnnotationLayer) {
                        currentViewAnnotationTarget =
                          this.syncMediaViewAnnotationLayer.addAnnotationTarget(
                            annotationTarget
                          );
                      }
                    });
                }
              }
            );
          }

          if (timelinePosition) {
            syncMediaPlayer.seekToTimelinePosition(timelinePosition);
          }
          mainView.setSyncMediaPlayer(syncMediaPlayer);
          this.syncMediaPlayerWaiting = !syncMediaPlayer.isReady();

          if (this.syncMediaViewAnnotationLayer) {
            this.syncMediaViewAnnotationLayer.setVisible(true);
          }
        }
      });
    }
  }

  toggleSyncMediaControlsUi(forceVisible: boolean | undefined = undefined) {
    if (!this.isSyncMediaPlayerControlsVisible || forceVisible === true) {
      this.isSyncMediaPlayerControlsVisible = true;
      window.setTimeout(() => {
        (
          (this.$refs.syncMediaPlayerPlayButton as Vue).$el as HTMLElement
        ).focus();
      }, 200);
      GlobalEventBus.$emit(GlobalEventName.APP_SYNC_MEDIA_UI_VISIBLE);
    } else {
      this.isSyncMediaPlayerControlsVisible = false;
      (
        (this.$refs.syncMediaPlayerToggleButton as Vue).$el as HTMLElement
      ).focus();
      GlobalEventBus.$emit(GlobalEventName.APP_SYNC_MEDIA_UI_HIDDEN);
    }
  }

  fetchSyncMediaTimeline(): Promise<ISyncMediaTimeline | null> {
    if (!this.timelinePromise) {
      if (!this.mainView) {
        return Promise.reject('this.mainView not set :(');
      }

      let mainView = this.mainView;

      // Try to fetch the publication native syncMedia timeline first.
      let readerPublication = mainView.getReaderPublications()[0];

      if (
        readerPublication.hasSyncMedia() &&
        isEpubReaderPublication(readerPublication)
      ) {
        this.timelinePromise = readerPublication
          .createSyncMediaTimeline(mainView.getReaderDocuments(), {
            ttsSynthesizer: new WebSpeechTtsSynthesizer(),
            ttsUtteranceProvider: new TtsUtteranceProvider(readerPublication),
          })
          .then((timeline) => {
            return timeline || this.createTtsTimeline();
          });
      } else {
        this.timelinePromise = this.createTtsTimeline();
      }

      this.timelinePromise.catch((err) => {
        console.error(err);
        this.timelinePromise = undefined; // Unset so we retry next time.
      });
    }
    return this.timelinePromise;
  }

  createTtsTimeline(): Promise<ISyncMediaTimeline | null> {
    if (!this.mainView) {
      return Promise.reject('this.mainView not set :(');
    }
    let mainView = this.mainView;
    let ttsTimelinePromise = mainView
      .getReadingSystemEngine()
      .createSyncMediaTtsTimeline(
        mainView.getReaderDocuments(),
        new TtsUtteranceProvider(this.mainView.getReaderPublications()[0]),
        new WebSpeechTtsSynthesizer()
      );
    return ttsTimelinePromise.then((timeline) => {
      this.syncMediaViewAnnotationLayer = mainView.createAnnotationLayer('tts');
      this.syncMediaViewAnnotationLayer.setOptions({
        annotationTargetRangeStyle: {
          'background-color': 'yellow',
        },
        annotationLayerStyle: {
          'mix-blend-mode': 'multiply',
          opacity: '0.4',
        },
      });

      return timeline;
    });
  }

  setupAutomaticPageTurn() {
    if (this.syncMediaPageTurnTimeoutId) {
      window.clearTimeout(this.syncMediaPageTurnTimeoutId);
    }
    this.syncMediaPageTurnTimeoutId = window.setTimeout(() => {
      if (this.mainView && this.mainView.canPerformNext()) {
        this.mainView.next();
      }
    }, 4000);
  }

  goToDocumentLocationTarget(
    documentLocationTarget: IReaderDocumentLocationTarget
  ) {
    if (
      documentLocationTarget
        .getReaderDocument()
        .getSourceContentDocument()
        .isInLinearContent()
    ) {
      if (this.popupReaderView || this.isPopupReaderDialogActive) {
        this.destroyPopupReaderView();
      }

      if (this.mainView) {
        this.mainView.goTo(documentLocationTarget).catch((err) => {
          if (!ColibrioError.isColibrioAbortedError(err)) {
            Logger.logError(err);
          }
        });
      }

      GlobalEventBus.$emit(
        GlobalEventName.READER_VIEW_GOTO,
        documentLocationTarget
      );
    } else {
      this.showDocumentInPopupReaderView(documentLocationTarget);
    }
  }

  goToFragmentSelector(fragmentSelector: string) {
    let annotationTarget: IAnnotationTarget = {
      selector: {
        type: AnnotationSelectorType.FRAGMENT_SELECTOR,
        value: fragmentSelector,
      } as IFragmentSelector,
    };

    if (this.mainView && this.mainView.canPerformGoTo()) {
      this.mainView.goTo(annotationTarget);
    }
  }

  /**
   * Vue.js life-cycle hook.
   * This method is called by Vue after this component has been attached to the DOM.
   */
  mounted() {
    this.createMainView();
    this.setupEventListeners();
  }

  navigateToLeft() {
    if (
      this.mainView &&
      this.mainView.getPageProgressionDirection() ===
        PageProgressionDirection.LTR
    ) {
      this.previous();
    } else {
      this.next();
    }
  }

  navigateToRight() {
    if (
      this.mainView &&
      this.mainView.getPageProgressionDirection() ===
        PageProgressionDirection.LTR
    ) {
      this.next();
    } else {
      this.previous();
    }
  }

  onProgressBarChanged(value: number) {
    // For some reason, this method is fired twice by vue. First with the old value, then with the new value.
    if (
      this.mainView &&
      value !== this.mainViewTimelineLastChangeValue &&
      this.mainViewTimelineValue !== null
    ) {
      // Wierdness in Edge cause a bug in Vuetify when pressing left/right arrow while the slider element has focus.
      if (document.activeElement) {
        (document.activeElement as HTMLElement).blur();
      }
      this.mainViewTimelineLastChangeValue = value;
      let timeline = this.mainView.getPageProgressionTimeline();
      if (timeline) {
        let pageValue = value - 1;
        let currentSegment = timeline.getCurrentSegment();
        if (
          pageValue < currentSegment.start ||
          pageValue > currentSegment.end
        ) {
          let snapToInitialReadingPosition = true;
          timeline
            .fetchAnnotationTargetFromPosition(
              pageValue,
              snapToInitialReadingPosition
            )
            .then((annotationTarget) => {
              if (this.mainView && this.mainView.canPerformGoTo()) {
                return this.mainView.goTo(annotationTarget);
              }
            })
            .catch((err) => {
              if (!ColibrioError.isColibrioAbortedError(err)) {
                Logger.logError(err);
              }
            });
        }
      }
    }
  }

  previous() {
    if (this.mainView && this.mainView.canPerformPrevious()) {
      GlobalEventBus.$emit(GlobalEventName.READER_VIEW_GOTO_PREVIOUS);
      this.mainView.previous().catch((err) => {
        if (!ColibrioError.isColibrioAbortedError(err)) {
          Logger.logError(err);
        }
      });
    }
  }

  refreshView() {
    if (this.mainView) {
      this.mainView.refresh(true);
    }
  }

  next() {
    if (this.mainView && this.mainView.canPerformNext()) {
      GlobalEventBus.$emit(GlobalEventName.READER_VIEW_GOTO_NEXT);
      this.mainView.next().catch((err) => {
        if (!ColibrioError.isColibrioAbortedError(err)) {
          Logger.logError(err);
        }
      });
    }
  }

  openExternalHref() {
    if (this.externalHref) {
      if (typeof nw !== 'undefined') {
        nw.Shell.openExternal(this.externalHref);
      } else {
        window.open(this.externalHref, '_blank');
      }
    }
    this.isExternalHrefDialogActive = false;
  }

  showOpenUrlDialog(href: string) {
    this.externalHref = href;
    this.isExternalHrefDialogActive = true;
  }

  showClosePublicationDialog() {
    this.isClosePublicationDialogActive = true;
  }

  showDocumentInPopupReaderView(
    documentLocationTarget: IReaderDocumentLocationTarget
  ) {
    let popupReaderViewElement = this.$refs
      .popupReaderViewElement as HTMLElement;
    let readingSystem = readerModel.getReadingSystem();
    if (this.popupReaderView) {
      // The popupReaderView is already open
      this.popupReaderView.setReaderDocuments([
        documentLocationTarget.getReaderDocument(),
      ]);
      this.popupReaderView.renderTo(popupReaderViewElement);
    } else if (readingSystem) {
      // Trigger Vue to show the dialog.
      this.isPopupReaderDialogActive = true;

      /*
       * We use a scroll view for the popup reader view.
       * We also set "allowNativePanY" which is important to allow the browser to use native-scroll.
       */
      let popupReaderView = readingSystem.createView();
      popupReaderView.setActiveRenderer(new VerticalScrollRenderer());
      popupReaderView.setReaderDocuments([
        documentLocationTarget.getReaderDocument(),
      ]);

      // Unfortunately, Vuetify does not emit any events when the dialog is actually rendered to the DOM. For now we just go with setTimeout.
      setTimeout(() => {
        // Could get here in a racing condition i.e. two non-linear content documents opening at the same time.
        if (!this.popupReaderView && this.isPopupReaderDialogActive) {
          this.popupReaderView = popupReaderView;
          this.popupReaderView.renderTo(popupReaderViewElement);
          this.popupReaderView.goTo(documentLocationTarget).catch((err) => {
            if (!ColibrioError.isColibrioAbortedError(err)) {
              Logger.logError(err);
            }
          });
        }
      }, 200);
    }
  }

  destroyImageZoomDialog() {
    if (this.revokeZoomedImageUrl) {
      this.revokeZoomedImageUrl();
      this.revokeZoomedImageUrl = undefined;
    }
    this.zoomedImageUrl = null;
    this.isImageZoomDialogActive = false;
  }

  destroyPopupReaderView() {
    if (this.popupReaderView) {
      this.popupReaderView.destroy();
      this.popupReaderView = undefined;
    }
    this.isPopupReaderDialogActive = false;
  }

  closePublication() {
    this.isClosePublicationDialogActive = false;
    GlobalEventBus.$emit(GlobalEventName.READER_PUBLICATION_CLOSE);
  }

  closePublicationCancelled() {
    this.isClosePublicationDialogActive = false;
  }

  switchPublicationView(rendererName: string) {
    if (this.mainView) {
      if (rendererName === '__responsive') {
        this.mainView.setResponsive(true);
      } else {
        let view = this.mainView.getRendererByName(rendererName);
        if (view) {
          this.mainView.setActiveRenderer(view);
        }
      }
    }
  }

  toggleZoomMode() {
    if (this.mainView) {
      if (this.isZoomModeActive) {
        this.mainView.setViewState(ViewState.DEFAULT);
      } else {
        this.mainView.setViewState(ViewState.TRANSFORM);
      }
    }
  }

  zoomToPointerPosition(evt: IMouseEngineEvent, scale = 2) {
    if (this.mainView) {
      this.mainView.setViewState(ViewState.TRANSFORM);
      let transformManager = this.mainView.getTransformManager();
      if (transformManager && transformManager.canTransform()) {
        transformManager.zoomToEventPosition(evt, scale);
      }
    }
  }

  /**
   * Toggles the playback between playing and paused
   * */
  syncMediaPlayerPlayPause() {
    if (this.mainView) {
      let mediaPlayer = this.mainView.getSyncMediaPlayer();
      if (mediaPlayer) {
        if (!this.syncMediaPlayerPlaying) {
          mediaPlayer.play();
          captureEvent('toggle-tts-play');
        } else {
          mediaPlayer.pause();
          captureEvent('toggle-tts-pause');
        }
      }
    }
  }

  /**
   * seekToNextSegment jumps to a new segment in the timeline. Depending on the playing/paused state of the media player,
   * the new segment may start playing directly.
   */
  syncMediaPlayerSeekNext() {
    if (this.mainView) {
      let mediaPlayer = this.mainView.getSyncMediaPlayer();
      if (mediaPlayer) {
        let snapToVisiblePagesBoundary = true;
        mediaPlayer.seekToNextSegment(snapToVisiblePagesBoundary);
      }
    }
  }

  syncMediaPlayerEscape() {
    if (this.mainView) {
      let mediaPlayer = this.mainView.getSyncMediaPlayer();
      if (mediaPlayer && this.timelineEscapePosition) {
        mediaPlayer.seekToTimelinePosition(this.timelineEscapePosition);
      }
    }
  }

  /**
   * seekToPreviousSegment jumps to the previous segment in the timeline. Depending on the playing/paused state of the media player,
   * the new item may start playing directly.
   * This method is a bit more complex than the 'syncMediaPlayerSeekNext' method. The reason for this is
   * that if the media player is 'playing' and the user wants to skip to a previous segment it needs to give the user
   * some margin to click multiple times before it resumes playback.
   * */
  syncMediaPlayerPlayPrevious() {
    if (this.mainView) {
      let mediaPlayer = this.mainView.getSyncMediaPlayer();
      if (!mediaPlayer) {
        return;
      }

      let shouldUseTemporaryPause = false;
      if (this.syncMediaTempPauseTimeoutId !== null) {
        window.clearTimeout(this.syncMediaTempPauseTimeoutId);
        this.syncMediaTempPauseTimeoutId = null;
        shouldUseTemporaryPause = true;
      } else if (!mediaPlayer.isPaused()) {
        shouldUseTemporaryPause = true;
      }
      if (shouldUseTemporaryPause) {
        mediaPlayer.pause();
        let snapToVisiblePagesBoundary = true;
        mediaPlayer.seekToPreviousSegment(snapToVisiblePagesBoundary);
        this.syncMediaTempPauseTimeoutId = window.setTimeout(() => {
          if (this.mainView) {
            let mediaPlayer = this.mainView.getSyncMediaPlayer();
            if (mediaPlayer) {
              mediaPlayer.play();
            }
          }
          this.syncMediaTempPauseTimeoutId = null;
        }, 1000);
      } else {
        let snapToVisiblePagesBoundary = true;
        mediaPlayer.seekToPreviousSegment(snapToVisiblePagesBoundary);
      }
    }
  }

  syncMediaPlayerVolumeChange(volume: number) {
    if (this.mainView) {
      let mediaPlayer = this.mainView.getSyncMediaPlayer();
      if (mediaPlayer) {
        mediaPlayer.setVolume(volume / 100);
      }
    }
  }

  syncMediaPlayerToggleMute() {
    if (this.mainView) {
      this.syncMediaPlayerMuted = !this.syncMediaPlayerMuted;
      let mediaPlayer = this.mainView.getSyncMediaPlayer();
      if (mediaPlayer) {
        mediaPlayer.setMuted(this.syncMediaPlayerMuted);
      }
    }
  }

  syncMediaPlayerPlaybackRateChange(rate: number) {
    if (this.mainView) {
      let mediaPlayer = this.mainView.getSyncMediaPlayer();
      if (mediaPlayer) {
        mediaPlayer.setPlaybackRate(rate / 100);
      }
    }
  }

  syncMediaVolumeUiToggle() {
    if (this.syncMediaPlayerMuted) {
      this.syncMediaPlayerToggleMute();
    }
  }

  syncMediaOnPublicationSelectionChanged(ev: ISelectionChangedEngineEvent) {
    if (this.mainView) {
      const mediaPlayer = this.mainView.getSyncMediaPlayer();
      if (mediaPlayer && ev.annotationTarget && !ev.isRange) {
        let timeline = mediaPlayer.getTimeline();
        timeline
          .fetchTimelinePositionFromAnnotationTarget(
            ev.annotationTarget,
            AnnotationTargetSideBias.AFTER
          )
          .then((pos: ISyncMediaTimelinePosition) => {
            if (
              pos.getAnchorPoint() ===
              SyncMediaTimelinePositionAnchorPoint.INSIDE
            ) {
              // Always seek to beginning of segment by setting offsetMs to 0
              let newPosition = new SyncMediaTimelinePosition(
                pos.getTimeline(),
                pos.getSegmentIndex(),
                0,
                SyncMediaTimelinePositionAnchorPoint.INSIDE
              );
              mediaPlayer.seekToTimelinePosition(newPosition);
            }
          });
      }
    }
  }

  syncMediaEventListenerSetup() {
    if (this.mainView) {
      this.mainView.addEngineEventListener(
        'selectionChanged',
        this.syncMediaOnPublicationSelectionChanged
      );

      this.mainView.addEngineEventListener(
        'syncMediaPlayerVisiblePagesChanged',
        (evt) => {
          this.isSyncMediaInVisiblePages = evt.containsSyncMedia;
          if (this.isSyncMediaInVisiblePages) {
            if (this.syncMediaPageTurnTimeoutId) {
              window.clearTimeout(this.syncMediaPageTurnTimeoutId);
              this.syncMediaPageTurnTimeoutId = null;
            }
          } else if (this.syncMediaPlayerPlaying) {
            this.setupAutomaticPageTurn();
          }
        }
      );

      /**
       * syncMediaPlayerViewNavigationIntent is fired any time playback has reached the end of the visible pages.
       **/
      this.mainView.addEngineEventListener(
        'syncMediaPlayerViewNavigationIntent',
        (_evt) => {
          /**
           * Here we could call evt.preventDefault() to not perform navigation.
           */
        }
      );

      this.mainView.addEngineEventListener<'syncMediaPlayerPlay'>(
        'syncMediaPlayerPlay',
        (_evt) => {
          this.syncMediaPlayerPlaying = true;
          if (!this.isSyncMediaInVisiblePages) {
            this.setupAutomaticPageTurn();
          }
        }
      );
      this.mainView.addEngineEventListener<'syncMediaPlayerPaused'>(
        'syncMediaPlayerPaused',
        (_evt) => {
          this.syncMediaPlayerPlaying = false;
          if (this.syncMediaPageTurnTimeoutId) {
            window.clearTimeout(this.syncMediaPageTurnTimeoutId);
            this.syncMediaPageTurnTimeoutId = null;
          }
        }
      );

      this.mainView.addEngineEventListener<'syncMediaPlayerReady'>(
        'syncMediaPlayerReady',
        (_evt) => {
          this.syncMediaPlayerWaiting = false;
        }
      );

      this.mainView.addEngineEventListener<'syncMediaPlayerWaiting'>(
        'syncMediaPlayerWaiting',
        (_evt) => {
          this.syncMediaPlayerWaiting = true;
        }
      );

      this.mainView.addEngineEventListener(
        'syncMediaPlayerActiveSegmentReady',
        (evt) => {
          // This event allows us to handle skippability and escapability.
          for (let target of evt.targets) {
            // This is an example of escapability implementation.
            // Allow escape out of <seq> elements in the SMIL document, and <par> elements if it has a "epub:type" attribute
            if (
              target.mediaType === MediaType.APPLICATION_SMIL &&
              target.nodeData
            ) {
              let parNode = target.nodeData;
              if (
                parNode.attributes.some(
                  (attribute) => attribute.name === 'epub:type'
                )
              ) {
                this.timelineEscapePosition = parNode.endPosition;
              } else if (parNode.parent && parNode.parent.nodeName === 'seq') {
                this.timelineEscapePosition = parNode.parent.endPosition;
              } else {
                this.timelineEscapePosition = undefined;
              }
            }
            this.hasTimelineEscapePosition = !!this.timelineEscapePosition;

            // This is an example of a skippability implementation, that will skip segments that target elements in the publication that has the attribute epub:type="pagebreak"
            if (target.mediaType === MediaType.APPLICATION_XHTML) {
              let foundSkipAttribute = false;
              if (target.nodeData) {
                let nodeData: ISyncMediaEngineEventTargetXmlElementNodeData | null =
                  target.nodeData;
                while (nodeData && !foundSkipAttribute) {
                  foundSkipAttribute = nodeData.attributes.some(
                    (attr) =>
                      attr.name === 'epub:type' && attr.value === 'pagebreak'
                  );
                  nodeData = nodeData.parent;
                }
              } else if (target.contentBlock) {
                let contentBlock: IContentBlock | null = target.contentBlock;
                while (contentBlock && !foundSkipAttribute) {
                  foundSkipAttribute = contentBlock
                    .getAttributes()
                    .some(
                      (attr) =>
                        attr.name === 'epub:type' && attr.value === 'pagebreak'
                    );
                  contentBlock = contentBlock.getParent();
                }
              }

              if (foundSkipAttribute) {
                if (
                  evt.lastSeekType === SyncMediaPlayerSeekType.NEXT ||
                  evt.lastSeekType === SyncMediaPlayerSeekType.CONTINUOUS
                ) {
                  evt.syncMediaPlayer.seekToNextSegment(true);
                  break;
                } else if (
                  evt.lastSeekType === SyncMediaPlayerSeekType.PREVIOUS
                ) {
                  evt.syncMediaPlayer.seekToPreviousSegment(true);
                  break;
                } else {
                  // If lastSeekType is GOTO, allow to play the segment.
                }
              }
            }
          }
        }
      );
    }
  }

  private isModifierKeyActive(ev: KeyboardEvent) {
    return (
      ev.getModifierState('Control') ||
      ev.getModifierState('Alt') ||
      ev.getModifierState('Meta') ||
      ev.getModifierState('CapsLock') ||
      ev.getModifierState('Fn') ||
      ev.getModifierState('OS')
    );
  }
}

function isEpubReaderPublication(
  readerPublication: IReaderPublication
): readerPublication is IEpubReaderPublication {
  return (
    readerPublication.getSourcePublication().getMediaType() ===
    MediaType.APPLICATION_EPUB_ZIP
  );
}
