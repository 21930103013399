/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */

export interface IUserAnnotation {
  _uid?: number;
  '@context': 'http://www.w3.org/ns/anno.jsonld';
  id?: string;
  type: 'Annotation';
  creator?: string;
  created?: string;
  modified?: string;
  generator?: string;
  generated?: string;
  motivation?: AnnotationMotivation;
  stylesheet?: IUserAnnotationStylesheet;
  body?: IUserAnnotationBody[];
  target: IUserAnnotationTarget;
}

export interface IUserAnnotationBody {
  type: 'TextualBody' | AnnotationExternalWebResourceClass;
  format: string;
  value: string;
  purpose: AnnotationMotivation;
}

export interface IUserAnnotationStylesheet {
  type: 'CssStylesheet';
  value: string;
}

export interface IUserAnnotationTarget {
  id?: string;
  selector:
    | IUserAnnotationFragmentSelectorEpubcfi
    | IUserAnnotationFragmentSelectorPdf
    | IUserAnnotationFragmentSelectorMediaTemporal;
  conformsTo: string;
  styleClass?: string;
}

export interface IUserAnnotationFragmentSelector {
  type: 'FragmentSelector';
  conformsTo: string;
  value: string;
}

interface IUserAnnotationFragmentSelectorEpubcfi
  extends IUserAnnotationFragmentSelector {
  conformsTo: 'http://www.idpf.org/epub/linking/cfi/epub-cfi.html';
}

interface IUserAnnotationFragmentSelectorPdf
  extends IUserAnnotationFragmentSelector {
  conformsTo: 'http://tools.ietf.org/rfc/rfc3778';
}

interface IUserAnnotationFragmentSelectorMediaTemporal
  extends IUserAnnotationFragmentSelector {
  conformsTo: 'http://www.w3.org/TR/media-frags/';
}

export enum AnnotationExternalWebResourceClass {
  TEXT = 'Text',
  DATASET = 'Dataset',
  IMAGE = 'Image',
  VIDEO = 'Video',
  SOUND = 'Sound',
}

export enum AnnotationMotivation {
  COMMENTING = 'commenting',
  BOOKMARKING = 'bookmarking',
  LINKING = 'linking',
  CLASSIFYING = 'classifying',
  ASSESSING = 'assessing',
  DESCRIBING = 'describing',
  EDITING = 'editing',
  HIGHLIGHTING = 'highlighting',
  IDENTIFYING = 'identifying',
  MODERATING = 'moderating',
  QUESTIONING = 'questioning',
  REPLYING = 'replying',
  TAGGING = 'tagging',
}

export interface IUserAnnotationHighlight extends IUserAnnotation {
  motivation: AnnotationMotivation.COMMENTING;
  stylesheet: {
    type: 'CssStylesheet';
    value: string;
  };
  body: [
    {
      type: 'TextualBody';
      format: 'text/plain';
      value: string;
      purpose: AnnotationMotivation.COMMENTING;
    },
    {
      type: 'TextualBody';
      format: 'text/plain';
      value: string;
      purpose: AnnotationMotivation.HIGHLIGHTING;
    }
  ];
}
