import * as Cookie from 'js-cookie';
import { GlobalEventBus } from '../GlobalEventBus';
import { GlobalEventName } from '../GlobalEventName';
import {
  AnnotationSelectorType,
  IFragmentSelector,
} from '../lib/colibrio-publishing-framework/colibrio-core-annotation';
import { QueryParameters } from '../lib/params';
import * as api from './api';
import { PublicationState } from './PublicationState';
import { readerQueryInput } from './ReaderInput';
import { readerModel } from './ReaderModel';
import { publicationManager } from './PublicationManager';
import * as Sentry from '@sentry/browser';

// replaced by:
const LAST_VISITED_LOCATOR_COOKIE_PREFIX = 'last_visited_locator';

const MIN_TIME_BETWEEN_UPDATE_IN_MILLIS = 10000;

// Interfaces for locators
// https://github.com/readium/architecture/tree/master/locators

export interface Locator {
  href: string;
  type: string;
  title?: string;
  locations?: Location;
  text?: LocatorText;
}

export interface Location {
  fragments?: string[];
  progression?: number;
  position?: number;
  totalProgression?: number;
}

export interface LocatorText {
  after?: string;
  before?: string;
  highlight?: string;
}

export class CheckpointManager {
  // Current reading location, obtained by reading events
  private currentLocator?: Locator;

  // Whether or not an update to the server has been scheduled
  // It will be set when the page changes, and it will be cleaned
  // when the update is actually sent
  private scheduledUpdate = false;

  // Wheter or not the initial latest location on server has already been solved
  // (true even if the response is 'not found' or if no connection
  // is possible)
  //
  // This is used to prevent overwriting an old locator remotely
  // by turning the page before an attempt to response from the server
  // has been received
  private initalRemoteLocationResolved = false;

  constructor() {
    this.subscribeToReadingPositionChanges();
  }

  // Get the last visited locator locally. This is called by the
  // Reader, to set the publication initial location
  // NOTEL: We keep old fragments working for a while
  public getLastVisitedLocatorLocally(): Locator | undefined {
    // TODO: use the url hash
    const cookieLocatorRawValue = Cookie.get(this.getVisitedLocatorCookieId());
    if (cookieLocatorRawValue) {
      return JSON.parse(cookieLocatorRawValue) as Locator;
    } else {
      return undefined;
    }
  }

  /*
   * This function will be called each time a progression event occurs,
   * including during the opening of a publication
   * it will:
   * 1. save the current locator locally (in a cookie) and in memory
   * 2. if an update on server has not been scheduled, schedule it.
   */
  private async handleProgression(
    fragment: string,
    totalProgression: number
  ): Promise<void> {
    this.currentLocator = this.makeLocator(
      publicationManager.PublicationState.identifier || '',
      publicationManager.PublicationState.nature || '',
      publicationManager.PublicationState.title || '',
      [fragment],
      totalProgression
    );

    Cookie.set(
      this.getVisitedLocatorCookieId(),
      JSON.stringify(this.currentLocator),
      { expires: 30, sameSite: 'Strict' }
    );

    // Schedule sending an update to server
    if (
      !this.scheduledUpdate &&
      this.canReadOrWriteProgressOnServer() &&
      this.initalRemoteLocationResolved
    ) {
      setTimeout(
        () => this.updateProgressionInServer(),
        MIN_TIME_BETWEEN_UPDATE_IN_MILLIS
      );
      this.scheduledUpdate = true;
    }
    if (!this.initalRemoteLocationResolved) {
      this.resolveInitialRemoteCheckpoint();
    }
  }

  /*
   * send the current location to the server, and reset the schedule guard
   */
  private updateProgressionInServer() {
    try {
      api.setCheckpoint(
        this.currentLocator!,
        publicationManager.PublicationState.identifier!,
        readerQueryInput.providerId!,
        readerQueryInput.sessionId!,
        readerQueryInput.bundleId
      );
      this.scheduledUpdate = false;
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  private canReadOrWriteProgressOnServer(): boolean {
    return !!(
      publicationManager.PublicationState &&
      publicationManager.PublicationState.identifier &&
      readerQueryInput.sessionId &&
      readerQueryInput.providerId
    );
  }

  private getVisitedLocatorCookieId() {
    return `${LAST_VISITED_LOCATOR_COOKIE_PREFIX}-${QueryParameters.getUrlMd5()}`;
  }

  private makeLocator(
    href: string,
    type: string,
    title: string,
    fragments: string[],
    totalProgression: number
  ): Locator {
    return {
      href,
      type,
      title,
      locations: { fragments, totalProgression },
    };
  }

  /*
   * Attempt to get the latest remote checkpoint, and advance the reader to that location
   * if found and more advanced than the current progression.
   */
  private async resolveInitialRemoteCheckpoint() {
    if (this.canReadOrWriteProgressOnServer()) {
      try {
        const remoteLocator = await api.getLatestCheckpoint(
          publicationManager.PublicationState.identifier!,
          readerQueryInput.providerId!,
          readerQueryInput.sessionId!
        );
        if (
          !this.currentLocator ||
          this.currentLocator!.locations!.totalProgression! <
            remoteLocator!.locations!.totalProgression!
        ) {
          GlobalEventBus.$emit(
            GlobalEventName.READER_VIEW_GOTO_FRAGMENT_SELECTOR,
            remoteLocator!.locations!.fragments![0]
          );
        }
      } catch (e) {
        //surely a 404
      } finally {
        this.initalRemoteLocationResolved = true;
      }
    }
  }

  // Get the interesting data from "change position event" and send it to handleProgression
  private subscribeToReadingPositionChanges() {
    // Page changing
    readerModel
      .getReadingSystem()
      .addEngineEventListener<'readingPositionChanged'>(
        'readingPositionChanged',
        event => {
          const readingPosition = event.view.getReadingPosition();
          const progressionTimeline = event.view.getPageProgressionTimeline();
          if (readingPosition && progressionTimeline) {
            const annotationTarget = readingPosition.getAnnotationTarget();
            const progression =
              progressionTimeline.getCurrentSegment().end /
              progressionTimeline.getLength();
            const selector = annotationTarget.selector;
            if (typeof selector === 'object') {
              if (selector.type === AnnotationSelectorType.FRAGMENT_SELECTOR) {
                // note: not awaiting
                this.handleProgression(
                  (selector as IFragmentSelector).value,
                  progression
                );
              }
            }
          }
        }
      );
  }
}
