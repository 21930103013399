/**
 * Session manager (Session in the sense of a user who has been authenticated,
 * not a "reading session", i.e., not a reading session in the checkpoint store)
 */

import { readerQueryInput } from './ReaderInput';
import md5 from 'md5';
import * as Cookie from 'js-cookie';

const CR_COOKIE_PREFIX = '_crSession';

function getSessionCookieForTransactionId(transactionId: string): string {
  return `${CR_COOKIE_PREFIX}-${md5(transactionId)}`;
}

export function setSessionToken(
  transactionId: string,
  sessionToken: string
): void {
  Cookie.set(getSessionCookieForTransactionId(transactionId), sessionToken, {
    expires: 22,
  });
}

export function getSessionToken(transactionId: string) {
  return Cookie.get(getSessionCookieForTransactionId(transactionId));
}
