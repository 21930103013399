/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Share My World AB - All Rights Reserved
 */
import Hammer from 'hammerjs';

export class ImageInteractionHandler {
  private hammer: HammerManager;

  private transform: ITransform;
  private startX = 0;
  private startY = 0;
  private startScale = 1;

  constructor(
    private containerElement: HTMLElement,
    private imageElement: HTMLImageElement
  ) {
    // Set initial width and height by scaling up while keeping aspect ratio.
    let imageWidth = imageElement.width;
    let imageHeight = imageElement.height;

    let imageRatio = imageWidth / imageHeight;
    let containerRatio =
      containerElement.offsetWidth / containerElement.offsetHeight;

    if (imageRatio > containerRatio) {
      // Image has wider aspect ratio than container
      imageElement.style.width = containerElement.offsetWidth + 'px';
      imageElement.style.height =
        imageHeight * (containerElement.offsetWidth / imageWidth) + 'px';
    } else {
      imageElement.style.width =
        imageWidth * (containerElement.offsetHeight / imageHeight) + 'px';
      imageElement.style.height = containerElement.offsetHeight + 'px';
    }

    this.transform = {
      translate: {
        x: Math.round(
          (containerElement.offsetWidth - imageElement.offsetWidth) / 2
        ),
        y: Math.round(
          (containerElement.offsetHeight - imageElement.offsetHeight) / 2
        ),
      },
      scale: 1,
    };
    this.updateElementTransform();

    imageElement.addEventListener('dragstart', this, false);
    containerElement.addEventListener('wheel', this, false);

    this.hammer = new Hammer.Manager(containerElement);
    this.hammer.add(new Hammer.Pan({ threshold: 0, pointers: 0 }));
    this.hammer
      .add(new Hammer.Pinch({ threshold: 0 }))
      .recognizeWith(this.hammer.get('pan'));
    this.hammer.add(new Hammer.Tap({ event: 'doubletap', taps: 2 }));
    this.hammer.add(new Hammer.Tap());
    this.hammer.on('panstart panmove', ev => this.onPan(ev));
    this.hammer.on('pinchstart pinchmove', ev => this.onPinch(ev));
  }

  destroy(): void {
    this.imageElement.removeEventListener('dragstart', this, false);
    this.containerElement.removeEventListener('wheel', this, false);
    this.hammer.destroy();
  }

  handleEvent(event: Event) {
    switch (event.type) {
      case 'dragstart':
        event.preventDefault();
        break;

      case 'wheel':
        let wheelEvent = event as WheelEvent;
        if (wheelEvent.deltaY < 0) {
          this.transform.scale *= 1.11;
        } else if (wheelEvent.deltaY > 0) {
          this.transform.scale *= 0.9;
        }
        this.requestElementUpdate();
        break;
    }
  }

  updateElementTransform() {
    this.imageElement.style.transform =
      'translate3d(' +
      this.transform.translate.x +
      'px, ' +
      this.transform.translate.y +
      'px, 0) ' +
      'scale(' +
      this.transform.scale +
      ', ' +
      this.transform.scale +
      ')';
    // this.animationFrameRequested = false;
  }

  onPan(ev: HammerInput) {
    if (!this.transform) {
      return;
    }
    if (ev.type === 'panstart') {
      this.startX = this.transform.translate.x;
      this.startY = this.transform.translate.y;
    }
    this.transform.translate.x = ev.deltaX + this.startX;
    this.transform.translate.y = ev.deltaY + this.startY;

    this.requestElementUpdate();
  }

  onPinch(ev: HammerInput) {
    if (!this.transform) {
      return;
    }

    if (ev.type == 'pinchstart') {
      this.startScale = this.transform.scale;
    }

    this.transform.scale = this.startScale * ev.scale;

    this.requestElementUpdate();
  }

  requestElementUpdate() {
    // if (!this.animationFrameRequested) {
    //     this.animationFrameRequested = true;
    //     window.requestAnimationFrame(() => this.updateElementTransform);
    // }
    this.updateElementTransform();
  }
}

interface ITransform {
  translate: {
    x: number;
    y: number;
  };
  scale: number;
}
