import { Component, Vue } from 'vue-property-decorator';
import * as fs from './featureSwitch';

@Component
export default class FeatureSwitchsMixin extends Vue {
  public readonly fsAnnotations = fs.FS_ANNOTATIONS;

  // @ts-ignore
  public readonly fsTts = fs.FS_TTS && window.SpeechSynthesisUtterance != null;
}
