import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { readerQueryInput } from '../model/ReaderInput';
import {
  getFallbackVariantForLanguageCode,
  GLOBAL_FALLBACK,
} from './fallbacks';
import en_CA from './translations/en_CA.json';
import es_ES from './translations/es_ES.json';
import fr_CA from './translations/fr_CA.json';

Vue.use(VueI18n);

const locale = getFallbackVariantForLanguageCode(readerQueryInput.lang);

const messages = {
  en_CA,
  fr_CA,
  es_ES,
};

const i18n = new VueI18n({
  locale,
  messages,
  fallbackLocale: GLOBAL_FALLBACK,
  silentTranslationWarn: true,
});
export default i18n;
