/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 0.33.4
 */
'use strict';
(function (r, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-base', './colibrio-core-io-base'], function (a0, a1) { return f(r, a0, a1); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(r, require('./colibrio-core-base'), require('./colibrio-core-io-base')); } else { r.colibrio = r.colibrio || {}; r.colibrio.core = r.colibrio.core || {}; r.colibrio.core.io = r.colibrio.core.io || {}; r.colibrio.core.io.resourceprovider = r.colibrio.core.io.resourceprovider || {}; r.colibrio.core.io.resourceprovider.cache = f(r, colibrio.core.base, colibrio.core.io.base); } }(typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function(__self, __dep__colibrio_core_base, __dep__colibrio_core_io_base) { var api = {}; (function() { 'use strict';var d,f="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},h;if("function"==typeof Object.setPrototypeOf)h=Object.setPrototypeOf;else{var k;a:{var l={j:!0},m={};try{m.__proto__=l;k=m.j;break a}catch(a){}k=!1}h=k?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var n=h;var p=__dep__colibrio_core_base;var q=new Uint16Array(1);q[0]=4660;new Uint8Array(q.buffer);function r(a){return new Promise(function(b,c){var e=new FileReader;e.onload=function(){e.onabort=null;e.onload=null;e.onerror=null;b(e.result)};e.onabort=e.onerror=function(){e.onabort=null;e.onload=null;e.onerror=null;c(e.error)};e.readAsArrayBuffer(a)})};function t(a){this.a=a;this.b=null;this.c=new Map;this.i=this.g=null}d=t.prototype;d.asArrayBuffer=function(){var a=this;if(!this.b){if(0<this.c.size){var b=this.c.get(void 0);b||(b=this.c.values().next().value);b=b.then(function(c){return r(c)})}else b=this.a.asArrayBuffer();this.b=b.catch(function(c){a.b=null;return Promise.reject(c)})}return this.b};
d.asBlob=function(a){var b=this,c=this.c.get(a);c||(this.b?c=this.b.then(function(e){return a?new Blob([e],{type:a}):new Blob([e])}):c=this.a.asBlob(a),c=c.catch(function(e){b.c.delete(a);return Promise.reject(e)}),this.c.set(a,c));return c};d.asDataStream=function(){return this.a.asDataStream()};d.asString=function(){var a=this;this.g||(this.g=this.a.asString().catch(function(b){a.g=null;return Promise.reject(b)}));return this.g};
d.asUint8Array=function(){var a=this;this.i||(this.i=this.asArrayBuffer().then(function(b){return new Uint8Array(b)}).catch(function(b){a.i=null;return Promise.reject(b)}));return this.i};d.exists=function(){return this.a.exists()};d.getMediaType=function(){return this.a.getMediaType()};d.getPath=function(){return this.a.getPath()};d.getSize=function(){return this.a.getSize()};d.getUrl=function(){return this.a.getUrl()};d.isFakeUrl=function(){return this.a.isFakeUrl()};function u(a,b){return p.ColibrioError.call(this,"colibrio.io.resourceprovider.cache",a,b)||this}var v=p.ColibrioError;u.prototype=f(v.prototype);u.prototype.constructor=u;if(n)n(u,v);else for(var w in v)if("prototype"!=w)if(Object.defineProperties){var x=Object.getOwnPropertyDescriptor(v,w);x&&Object.defineProperty(u,w,x)}else u[w]=v[w];u.a=v.prototype;function y(a){this.i=a;this.c=!1;this.a=new Map;this.b=new Map;this.g=new Map}d=y.prototype;d.destroy=function(){this.c||(this.c=!0,this.g.clear(),this.a.clear(),p.RuntimePlatform.isBlobUrlSupported()&&this.b.forEach(function(a){return URL.revokeObjectURL(a)}),this.b.clear())};d.getBackingResourceProvider=function(){return this.i};
d.loadRenderableUrl=function(a,b){var c=this;this.c&&z();var e=this.a.get(a);e?e.f++:(e={f:1,h:null},this.a.set(a,e),p.RuntimePlatform.isBlobUrlSupported()?e.h=a.asBlob(b).then(function(g){if(c.c||!c.a.has(a))throw new u(p.ColibrioErrorType.ABORTED);g=URL.createObjectURL(g);c.b.set(a,g);return g}):e.h=a.asUint8Array().then(function(g){if(c.c||!c.a.has(a))throw new u(p.ColibrioErrorType.ABORTED);g="data:"+(b||a.getMediaType())+";base64,"+p.Base64.encode(g);c.b.set(a,g);return g}));return e.h};
d.loadResource=function(a){this.c&&z();var b=a.hash?a.href.slice(0,-a.hash.length):a.href,c=this.g.get(b);c?c.f++:(c={f:1,h:new t(this.i.getResource(a))},this.g.set(b,c));return c.h};d.unloadRenderableUrl=function(a,b){var c=this.a.get(a);c&&(c.f-=b,0>=c.f&&(this.a.delete(a),b=this.b.get(a)))&&(p.RuntimePlatform.isBlobUrlSupported()&&URL.revokeObjectURL(b),this.b.delete(a))};d.unloadResource=function(a,b){a=a.hash?a.href.slice(0,-a.hash.length):a.href;var c=this.g.get(a);c&&(c.f-=b,0>=c.f&&this.g.delete(a))};
function z(){throw new u("CACHE_HOST_CLOSED");};function A(a){this.a=a;this.b=!1;this.c=new Map;this.g=new Map}d=A.prototype;d.acceptsUrl=function(a){this.b&&B();return this.a.getBackingResourceProvider().acceptsUrl(a)};d.destroy=function(){var a=this;this.b||(this.b=!0,this.g.forEach(function(b){return a.a.unloadResource(b.h,b.f)}),this.g.clear(),this.c.forEach(function(b,c){a.a.unloadRenderableUrl(c,b.f)}),this.c.clear())};d.getBaseUrl=function(){return this.a.getBackingResourceProvider().getBaseUrl()};
d.getRenderableUrl=function(a,b){this.b&&B();if(a.isFakeUrl()){b=this.a.loadRenderableUrl(a,b);var c=this.c.get(a);c?c.f++:(c={f:1,h:void 0},this.c.set(a,c))}else b=Promise.resolve(a.getUrl().toString());return b};d.getResource=function(a){this.b&&B();var b=a.toString(),c=this.g.get(b);c?c.f++:(c={f:1,h:a},this.g.set(b,c));return this.a.loadResource(a)};d.isDestroyed=function(){return this.b};function B(){throw new u("CACHE_RESOURCE_PROVIDER_CLOSED");};api={CacheResource:t,CacheResourceProvider:A,CacheResourceProviderError:u,CacheResourceProviderErrorType:{CACHE_HOST_CLOSED:"CACHE_HOST_CLOSED",CACHE_RESOURCE_PROVIDER_CLOSED:"CACHE_RESOURCE_PROVIDER_CLOSED"},CacheHostResourceProvider:y}; }).call(__self); return api; }));
