import * as Sentry from '@sentry/browser';
import { includes } from 'lodash';
import { GlobalEventBus } from '../GlobalEventBus';
import { GlobalEventName } from '../GlobalEventName';
import { IRandomAccessDataSource } from '../lib/colibrio-publishing-framework/colibrio-core-io-base';
import { IPublication } from '../lib/colibrio-publishing-framework/colibrio-core-publication-base';
import { safe_atob } from '../lib/tools';
import { HttpRequest } from '../utils/io/http/HttpRequest';
import * as api from './api';
import { rewriteAssetUrl } from './AssetUrlRewrite';
import { Manifest } from './Manifest';
import { getObfuscationType, unobfuscate } from './Obfuscation';
import { DefaultPublicationState, PublicationState } from './PublicationState';
import { readerQueryInput } from './ReaderInput';
import { readerModel } from './ReaderModel';

export class PublicationManager implements IRandomAccessDataSource {
  readonly EXPIRATION_CHECK_TIME = 10000; // check every 10 secondes if the loan is still valid.

  public manifest: Manifest | null = null;
  public PublicationState: DefaultPublicationState;
  private manifestLoadedAt = 0; // when the manifest was loaded, in milliseconds from the epoch
  private publicationData = new ArrayBuffer(0);

  constructor() {
    this.PublicationState = new DefaultPublicationState();
  }

  // PUBLIC API

  // Load a publication
  public async loadPublication(): Promise<void> {
    try {
      await this.fetchValidatedManifest();
      if (this.manifest) {
        await this.setSentryContext();
        await this.renderPublication();
        // Launch a loop to check periodically the expiration date
        this.checkExpiration();
      }
    } catch (errors: any) {
      // Note: errors must not be an Error but a list of GlobalEventNames
      this.updatePublicationState({
        state: 'failed',
        error_code: errors[0],
      });
    }
  }

  // Download publication for offline reading.
  // rewriting the URL if necessary (for test and debugging). Note that data
  // might be obfuscated. Unobfuscation will take place in fetchChunk.
  public async downloadFullPublication(): Promise<void> {
    if (this.manifest) {
      await this.refreshManifestIfNeeded();
      this.updatePublicationState({ download_state: 'downloading' });
      this.publicationData = await api.getFullPublicationData(
        this.manifest.size,
        rewriteAssetUrl(this.manifest.url)
      );
      this.updatePublicationState({ download_state: 'downloaded' });
    }
  }

  public async returnPublication(): Promise<void> {
    const result = await api.returnPublication(this.manifest!);
    if (result) {
      console.log('loan returned');
      this.updatePublicationState({ state: 'returned' });
    } else {
      console.log('loan return fail');
    }
  }

  // IRandomAccessDataSource methods -> Methods

  // We overwrite this method in order to:
  // 1) renew the manifest on URL expiration
  // 2) return data from memory if downloaded for offline reading
  // 3) rewrite the target URL (for test or debugging)
  // 4) unobfuscate the data, if necessary
  public async fetchChunk(
    startOffset: number,
    endOffset: number
  ): Promise<ArrayBuffer> {
    // If we are here, there is a manifest
    // If the publication has been downloaded, simply return it
    let chunk: ArrayBuffer;
    if (this.publicationData.byteLength > 0) {
      chunk = this.publicationData.slice(startOffset, endOffset);
    } else {
      // Else, fetch from the url
      // reload the manifest, if it has expired
      await this.refreshManifestIfNeeded();
      try {
        chunk = await this.publicationRangeRequest(
          rewriteAssetUrl(this.manifest!.url),
          startOffset,
          endOffset
        );
      } catch (err) {
        // Make a second attempt to download the manifest if something fails
        // in case the manifest has changed
        await this.fetchValidatedManifest();
        try {
          chunk = await this.publicationRangeRequest(
            rewriteAssetUrl(this.manifest!.url),
            startOffset,
            endOffset
          );
        } catch (e) {
          Sentry.captureException(e);
          return new ArrayBuffer(0);
        }
      }
    }
    // Apply unobfuscation, if necessary
    const obfuscationType = getObfuscationType(
      rewriteAssetUrl(this.manifest!.url)
    );
    const view = new Uint8Array(chunk);
    unobfuscate(obfuscationType, view);
    return chunk;
  }

  /**
   * Get the size of the data in bytes.
   */
  public getSize(): number {
    if (this.manifest) {
      return this.manifest.size;
    } else {
      // we really should never be here
      throw new Error('no manifest!');
    }
  }

  // PRIVATE METHODS
  private checkExpiration(): void {
    if (this.manifest && this.manifest.expire_at) {
      const expirationDate = new Date(this.manifest.expire_at);
      const now = new Date();
      if (expirationDate < now) {
        this.updatePublicationState({
          state: 'failed',
          error_code: GlobalEventName.BOOK_EXPIRED_ERROR,
        });
      } else {
        setTimeout(() => this.checkExpiration(), this.EXPIRATION_CHECK_TIME);
      }
    }
  }

  private async publicationRangeRequest(
    targetUrl: string,
    startOffset: number,
    endOffset: number
  ): Promise<ArrayBuffer> {
    // Apply rewrites, if any
    const buffer = await HttpRequest.sendRangeRequest(
      targetUrl,
      startOffset,
      endOffset - 1
    );
    return buffer;
  }

  private async refreshManifestIfNeeded(): Promise<void> {
    if (Date.now() - this.manifestLoadedAt > this.manifest!.url_ttl * 1000) {
      await this.fetchValidatedManifest();
    }
  }

  // Updates and returns the current manifest, or fails if the manifest is not
  // valid. Failures are thrown as a list of error codes
  private async fetchValidatedManifest(): Promise<void> {
    const manifestUrl = await this.getManifestUrl();
    if (manifestUrl) {
      try {
        this.manifest = await api.getManifest(manifestUrl);
        this.manifestLoadedAt = Date.now();
        const errors = this.validateManifest(this.manifest);
        if (errors.length > 0) {
          throw errors;
        }
      } catch (error) {
        throw ['network_error'];
      }
    }
  }

  // Get the manifest URL, either directly from the URL parameters
  // or asking an authenticated end point to get the manifest URL.
  // if the call to the authenticated end point fails, it redirects
  // the user to the url in field "si" (base64ed)
  // NOTE: the "authenticated end point" will be discontinued,
  // integrators should use token based.
  private async getManifestUrl(): Promise<string | null> {
    if (readerQueryInput.transactionId) {
      return safe_atob(readerQueryInput.transactionId);
    } else {
      try {
        if (readerQueryInput.authenticatedManifestUrl) {
          const manifestEndPoint = safe_atob(
            readerQueryInput.authenticatedManifestUrl
          );
          return api.getManifestUrl(manifestEndPoint);
        } else {
          throw ['no_manifest'];
        }
      } catch (error: any) {
        // The request was made and the server responded with an error code
        if (error.response && [401, 403].includes(error.response.status)) {
          if (readerQueryInput.signinUrl) {
            window.location.href = safe_atob(readerQueryInput.signinUrl);
            return null;
          } else {
            throw ['no_signin_url'];
          }
        } else {
          // Something happened in setting up the request that triggered an Error
          Sentry.captureException(error);
          throw ['network_error'];
        }
      }
    }
  }

  // Check the manifest and return a list of errors, as GlobalEventNames
  private validateManifest(manifest: Manifest): GlobalEventName[] {
    const errors: GlobalEventName[] = [];

    // check if nature is supported
    if (!includes(['epub', 'pdf'], manifest.nature.toLowerCase())) {
      errors.push(GlobalEventName.NATURE_NOT_SUPPORTED_ERROR);
    }

    // check if the publication is still available
    if (manifest.expire_at) {
      const expirationDate = new Date(manifest.expire_at);
      const now = new Date();
      if (expirationDate < now) {
        errors.push(GlobalEventName.BOOK_EXPIRED_ERROR);
      }
    }
    return errors;
  }

  // Render a publication in Colibrio vue, given a manifest has already been loaded
  private async renderPublication() {
    this.PublicationState.update({ state: 'loading' });
    if (this.manifest) {
      try {
        const mediaType =
          this.manifest.nature === 'epub'
            ? 'application/epub+zip'
            : 'application/pdf';
        const readerPublication =
          await readerModel.loadPublicationFromDataSource(this, mediaType);
        // Set the publication information once it has been loaded:
        const identifiers = readerPublication
          .getSourcePublication()
          .getMetadata()
          .getIdentifiers();

        this.updatePublicationState({
          state: 'presenting',
          nature: this.manifest.nature,
          identifier: this.getPublicationIdentifier(
            readerPublication.getSourcePublication()
          ),
          expiration_date: this.manifest.expire_at,
          return_url: this.manifest.return_url,
          title: this.manifest.title || '',
        });
      } catch (err) {
        this.PublicationState.update({
          state: 'failed',
          error_code: GlobalEventName.READER_PUBLICATION_LOAD_ERROR,
        });
      }
    } else {
      // We should not be here
      throw [GlobalEventName.READER_PUBLICATION_LOAD_ERROR];
    }
  }

  // choose an identifier either from the manifest or the publication metadata
  private getPublicationIdentifier(
    publication: IPublication
  ): string | undefined {
    if (this.manifest!.identifier) {
      return this.manifest!.identifier!;
    } else {
      const publicationIdentifiers = publication.getMetadata().getIdentifiers();
      if (publicationIdentifiers.length > 0) {
        return publicationIdentifiers[0].identifier;
      } else {
        return undefined;
      }
    }
  }

  // Update the publication state and emit an event to notify the UI.
  private updatePublicationState(change: Partial<PublicationState>): void {
    this.PublicationState.update(change);
    GlobalEventBus.emitStateChange(this.PublicationState);
  }

  // To be called only after manifest is recovered.
  private async setSentryContext(): Promise<void> {
    if (this.manifest == null) {
      throw new Error('Trying to set sentry context without manifest.');
    }
    Sentry.setContext('publication', {
      identifier: this.manifest.identifier,
      manifest_url: await this.getManifestUrl(),
      nature: this.manifest.nature,
      pub_title: this.manifest.title, // not 'title', which sentry reserves.
    });
  }
}

export let publicationManager = new PublicationManager();
