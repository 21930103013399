// This constants are injected by webpack (webpack.config.js)
// but typescript dont know about them, so I put them here

declare const VERSION: string;
declare const PRODUCTION: boolean;
declare const EXECUTION_ENV: string;
declare const CHECKPOINT_API_URL: string;
declare const COLIBRIO_LICENSE_API_KEY: string;
declare const BACKEND_URL: string;

const _VERSION = VERSION;
const _PRODUCTION = PRODUCTION;
const _EXECUTION_ENV = EXECUTION_ENV;
const _CHECKPOINT_API_URL = CHECKPOINT_API_URL;
const _COLIBRIO_LICENSE_API_KEY = COLIBRIO_LICENSE_API_KEY;
const _BACKEND_URL = BACKEND_URL;

export { _VERSION as VERSION };
export { _EXECUTION_ENV as EXECUTION_ENV };
export { _PRODUCTION as PRODUCTION };
export { _CHECKPOINT_API_URL as CHECKPOINT_API_URL };
export { _COLIBRIO_LICENSE_API_KEY as COLIBRIO_LICENSE_API_KEY };
export { _BACKEND_URL as BACKEND_URL };
