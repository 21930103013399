/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 0.33.4
 */
'use strict';
(function (r, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-base', './colibrio-core-io-base'], function (a0, a1) { return f(r, a0, a1); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(r, require('./colibrio-core-base'), require('./colibrio-core-io-base')); } else { r.colibrio = r.colibrio || {}; r.colibrio.core = r.colibrio.core || {}; r.colibrio.core.selector = r.colibrio.core.selector || {}; r.colibrio.core.selector.epubcfi = f(r, colibrio.core.base, colibrio.core.io.base); } }(typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function(__self, __dep__colibrio_core_base, __dep__colibrio_core_io_base) { var api = {}; (function() { 'use strict';var h;function aa(a){var b=0;return function(){return b<a.length?{done:!1,value:a[b++]}:{done:!0}}}function ba(a){if(!(a instanceof Array)){var b="undefined"!=typeof Symbol&&Symbol.iterator&&a[Symbol.iterator];a=b?b.call(a):{next:aa(a)};for(var c=[];!(b=a.next()).done;)c.push(b.value);a=c}return a}var da="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},n;
if("function"==typeof Object.setPrototypeOf)n=Object.setPrototypeOf;else{var r;a:{var ea={j:!0},fa={};try{fa.__proto__=ea;r=fa.j;break a}catch(a){}r=!1}n=r?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var ha=n;var t=__dep__colibrio_core_base;var u=__dep__colibrio_core_io_base;function v(a){return a[a.length-1]}function w(a){return a&&9===a.nodeType}function x(a){return a&&1===a.nodeType}function ia(a,b,c){Array.isArray(a)?b=[]:b||(b={});if("object"===typeof a)for(var d=Object.keys(a),e=0;e<d.length;e++){var f=d[e],g=a[f];null!==g&&"object"===typeof g?b[f]=ia(g,b[f],c):"undefined"===typeof g?c&&delete b[f]:b[f]=g}return b}var ja=Array.isArray;function y(a){return"number"===typeof a}function ka(a){return a&&!ja(a)&&"object"===typeof a};function z(a){for(var b=0;a=a.previousSibling;)b++;return b};var la=new Uint16Array(1);la[0]=4660;new Uint8Array(la.buffer);function A(a,b){return t.ColibrioError.call(this,"colibrio.core.selector.epubcfi",a,b)||this}var C=t.ColibrioError;A.prototype=da(C.prototype);A.prototype.constructor=A;if(ha)ha(A,C);else for(var D in C)if("prototype"!=D)if(Object.defineProperties){var ma=Object.getOwnPropertyDescriptor(C,D);ma&&Object.defineProperty(A,D,ma)}else A[D]=C[D];A.a=C.prototype;function na(a){var b=["epubcfi("];a.parentPath&&E(a.parentPath,b);if(a.rangeStartPath||a.rangeEndPath)b.push(","),a.rangeStartPath&&E(a.rangeStartPath,b),a.rangeEndPath&&(b.push(","),E(a.rangeEndPath,b));b.push(")");return b.join("")}
function oa(a,b){b.push("[");"ASSERTION_XML_ID"===a.type?a.xmlId&&b.push(F(a.xmlId)):"ASSERTION_TEXT_LOCATION"===a.type&&(a.textBefore&&b.push(F(a.textBefore)),a.textAfter&&b.push(",",F(a.textAfter)));for(var c=0;c<a.parameters.length;c++){var d=a.parameters[c];b.push(";",F(d.name));for(var e="=",f=0;f<d.values.length;f++)b.push(e,F(d.values[f])),e=","}b.push("]")}
function E(a,b){for(var c=0;c<a.localPaths.length;c++){var d=a.localPaths[c],e=b;d.indirection&&e.push("!");for(var f=0;f<d.steps.length;f++){var g=d.steps[f],k=e;k.push("/",g.stepValue);g.assertion&&oa(g.assertion,k)}}a.offset&&(a=a.offset,"OFFSET_CHARACTER"===a.type?b.push(":",a.characterOffset):"OFFSET_SPATIAL"===a.type?b.push("@",a.x,":",a.y):"OFFSET_TEMPORAL"===a.type&&(b.push("~",a.seconds),y(a.x)&&y(a.y)&&b.push("@",a.x,":",a.y)),a.assertion&&oa(a.assertion,b))}
function F(a){return a.replace(/[\^\[\]\(\),;=]/g,function(b){return"^"+b})};function G(a,b,c,d){var e=null;if(x(a)){var f=a.childNodes[b];x(f)?d=H(f,d):f?(d=H(a,d),d.push(I(f)),e=pa(f)):(d=H(a,d),d.push({type:"STEP_ELEMENT",assertion:null,stepValue:0===b?0:2*a.childElementCount+2}))}else if(x(a.parentNode))a===d?d=null:(d=H(a.parentNode,d),d.push(I(a))),(e=pa(a))&&a&&3===a.nodeType&&(e.characterOffset+=b);else throw new A("CONTAINER_NOT_ATTACHED_TO_DOCUMENT",""+a);c||(c=M());d&&c.localPaths.push({type:"LOCAL_PATH",indirection:0<c.localPaths.length,steps:d});c.offset=e}
function pa(a){if(x(a))return null;a=a.previousSibling;for(var b=0;a&&!x(a);)a&&3===a.nodeType&&(b+=a.data.length),a=a.previousSibling;return{type:"OFFSET_CHARACTER",assertion:null,characterOffset:b}}function qa(a,b){return{type:"LOCAL_PATH",indirection:b,steps:H(a)}}
function H(a,b){for(var c=[],d=[];a.parentNode&&!w(a.parentNode)&&a!==b;)c.push(a),a=a.parentNode;for(a=c.length-1;0<=a;a--){b=c[a];var e=null;b.id&&(e={type:"ASSERTION_XML_ID",xmlId:b.id,parameters:[]});d.push({type:"STEP_ELEMENT",stepValue:2*(ra(b)+1),assertion:e})}return d}function I(a){for(a=a.previousSibling;a&&!x(a);)a=a.previousSibling;return{type:"STEP_TEXT",stepValue:a?2*ra(a)+3:1,assertion:null}}function M(){return{type:"PATH",complete:!1,localPaths:[],offset:null}}
function sa(){return{type:"ROOT",src:"",srcModified:!1,parentPath:null,rangeStartPath:null,rangeEndPath:null,errors:[]}}function ra(a){for(var b=0;a=a.previousElementSibling;)b++;return b};function N(a){this.a=a?a:sa()}h=N.prototype;h.appendLocalPathTo=function(a){if(this.a.rangeStartPath||this.a.rangeEndPath)throw new A("RANGE_PATHS_ALREADY_SET");a=H(a);this.a.parentPath||(this.a.parentPath=M());this.a.parentPath.localPaths.push({type:"LOCAL_PATH",steps:a,indirection:0<this.a.parentPath.localPaths.length})};
h.appendTerminalDomPosition=function(a,b){if(this.a.rangeStartPath||this.a.rangeEndPath)throw new A("RANGE_PATHS_ALREADY_SET");this.a.parentPath||(this.a.parentPath=M());G(a,b,this.a.parentPath)};
h.appendTerminalDomRange=function(a){var b=this.a;b||(b=sa());if(a.collapsed)b.parentPath||(b.parentPath=M()),G(a.startContainer,a.startOffset,b.parentPath);else{b.parentPath||(b.parentPath=M());if(b.rangeStartPath||b.rangeEndPath)throw new A("RANGE_PATHS_ALREADY_SET");b.rangeStartPath=M();b.rangeEndPath=M();G(a.startContainer,a.startOffset,b.rangeStartPath,a.commonAncestorContainer);G(a.endContainer,a.endOffset,b.rangeEndPath,a.commonAncestorContainer);var c=0<b.parentPath.localPaths.length;if(x(a.commonAncestorContainer))b.parentPath.localPaths.push(qa(a.commonAncestorContainer,
c));else if(x(a.commonAncestorContainer.parentNode))c=qa(a.commonAncestorContainer.parentNode,c),c.steps.push(I(a.commonAncestorContainer)),b.parentPath.localPaths.push(c);else throw new A("CONTAINER_NOT_ATTACHED_TO_DOCUMENT",""+a.commonAncestorContainer);}};
h.appendTerminalIndirection=function(){if(this.a.rangeStartPath||this.a.rangeEndPath)throw new A("RANGE_PATHS_ALREADY_SET");this.a.parentPath||(this.a.parentPath=M());this.a.parentPath.localPaths.push({type:"LOCAL_PATH",indirection:!0,steps:[{type:"STEP_ELEMENT",assertion:null,stepValue:0}]})};h.clone=function(){var a=ia(this.a,{},!1);return new N(a)};h.getEpubCfiRootNode=function(){return this.a};
h.prependLocalPathTo=function(a){a=H(a);this.a.parentPath||(this.a.parentPath=M());var b=this.a.parentPath.localPaths[0];b&&(b.indirection=!0);this.a.parentPath.localPaths.unshift({type:"LOCAL_PATH",steps:a,indirection:!1})};h.toString=function(){return na(this.a)};function P(a){this.c=!1;this.b=void 0;this.src="string"===typeof a?a.replace(/[^\x09\x0A\x0D\u0020-\uFFFD]/g,""):"";var b=this.src.indexOf("epubcfi(");0<b&&(this.src=this.src.substring(b));-1===b?(this.a=0,this.c=!0,this.b={type:-1,value:this.src,srcOffset:0}):(this.a=8,this.b={type:0,value:"epubcfi(",srcOffset:0});this.srcModified=this.src!==a}P.prototype.next=function(){if(void 0!==this.b){var a=this.b;this.b=void 0;return a}return ta(this)};
P.prototype.peek=function(){void 0===this.b&&(this.b=ta(this));return this.b};
function ta(a){if(!a.c){var b=a.a++,c=a.src.charCodeAt(b);if(33===c)b=Q(40,"!",b);else if(44===c)b=Q(41,",",b);else if(47===c){for(var d=c=a.a;R(a.src.charCodeAt(c));)c++;a.a=c;b=d===c?Q(-1,"/",b):{type:11,value:Number(a.src.substring(d,c)),srcOffset:d}}else if(58===c)b=Q(42,":",b);else if(64===c)b=Q(45,"@",b);else if(126===c)b=Q(46,"~",b);else if(91===c){c=!0;for(d=[];c;){var e=void 0,f=a.a++,g=a.src.charCodeAt(f);if(44===g)e=Q(41,",",f);else if(59===g)e=Q(43,";",f);else if(61===g)e=Q(44,"=",f);
else if(93===g)c=!1;else if(isNaN(g))e=Q(-2,"",f),c=!1;else if(91===g||40===g||41===g)e=Q(-1,String.fromCharCode(g),f);else{e=a;for(var k=g=f,l=!1,p="",q=!0;q;){var m=e.src.charCodeAt(g);94===m?(m=e.src.charCodeAt(g+1),isNaN(m)?q=!1:(p+=e.src.substring(k,g),55296<=m&&56319>=m?(p+=String.fromCharCode(m,e.src.charCodeAt(g+2)),g+=3):(p+=String.fromCharCode(m),g+=2),k=g)):91===m||93===m||40===m||41===m||44===m||59===m||61===m||isNaN(m)?q=!1:(32===m&&(l=!0),g++)}e.a=g;e={type:30,value:p+e.src.substring(k,
g),srcOffset:f,l:l}}e&&d.push(e)}b={type:20,value:d,srcOffset:b}}else if(41===c)b=Q(1,")",b),a.c=!0;else if(isNaN(c))b=Q(-2,"",b),a.c=!0;else if(R(c)){for(c=a.a;R(d=a.src.charCodeAt(c));)c++;if(46===d&&R(a.src.charCodeAt(c+1)))for(c+=2;R(a.src.charCodeAt(c));)c++;a.a=c;b={type:10,value:Number(a.src.substring(b,c)),srcOffset:b}}else{for(c=a.a;ua(a.src.charCodeAt(c));)c++;a.a=c;b=Q(-1,a.src.substring(b,c),b)}return b}}function Q(a,b,c){return{type:a,value:b,srcOffset:c}}
function ua(a){return 128<a||97<=a&&122>=a||65<=a&&97>=a}function R(a){return 48<=a&&57>=a};function S(a,b){var c;if(ka(b)){var d=b.srcOffset;"string"===typeof b.value?c=b.value:y(b.value)?c=""+b.value:ja(b.value)&&(c="["+b.value.map(function(e){return e.value}).join("")+"]")}else d=b;return{type:a,srcOffset:d,value:c||""}};function va(a){this.errors=[];this.a=new P(a)}function wa(a){a=new va(a);var b=null,c=null,d=null;if(T(a,0,"INVALID_EPUBCFI_START")){b=U(a);var e=a.a.peek();e&&41===e.type&&(a.a.next(),c=U(a),T(a,41,"MISSING_END_RANGE")&&(d=U(a)));T(a,1,"INVALID_EPUBCFI_END")}return{type:"ROOT",srcOffset:0,src:a.a.src,srcModified:a.a.srcModified,parentPath:b,rangeStartPath:c,rangeEndPath:d,errors:a.errors}}
function T(a,b,c,d,e){e=e||a.a;var f=e.peek();if(f&&f.type===b)return e.next();V(a,c,f||{type:-2,value:"",srcOffset:d?d.srcOffset:a.a.a})}function W(a,b){var c=xa(a,new u.ArrayInputStream(b.value));return 0===c.length?(V(a,"INVALID_ASSERTION",b),null):za(c,b.srcOffset)}
function Aa(a,b){var c=b.next();if(!c)return null;if(43!==c.type)return V(a,"INVALID_PARAMETER",c),null;var d=T(a,30,"INVALID_PARAMETER",c,b);if(!d)return null;if(d.l)return V(a,"INVALID_PARAMETER",d),null;if(!T(a,44,"INVALID_PARAMETER",d,b))return null;var e=T(a,30,"INVALID_PARAMETER",c,b);if(!e)return null;for(var f=[e.value],g;(g=b.peek())&&43!==g.type;)b.next(),41!==g.type?V(a,"INVALID_PARAMETER",g):(e=T(a,30,"INVALID_PARAMETER",g,b))&&f.push(e.value);return{type:"PARAMETER",srcOffset:c.srcOffset,
name:d.value,values:f}}function xa(a,b){for(var c=[];b.peek();){var d=Aa(a,b);d&&c.push(d)}return c}
function U(a){var b=[],c=a.a.a,d=null,e=a.a.peek();if(e&&(11===e.type||40===e.type)){b=!1;var f;e=[];for(var g=!1;!b&&(f=a.a.peek());)if(11===f.type){for(var k=void 0,l=a,p=[];(k=l.a.peek())&&11===k.type;){var q=l,m=l.a.next(),ya=0===m.value%2?"STEP_ELEMENT":"STEP_TEXT",Ya=m?m.srcOffset:q.a.a,J=null,K=q.a.peek();if(K&&20===K.type)if("STEP_TEXT"===ya)J=W(q,q.a.next());else{J=void 0;K=q;q=q.a.next();var B=new u.ArrayInputStream(q.value),O=null,ca=B.peek();ca&&30===ca.type&&(O=ca.value,B.next());B=xa(K,
B);null===O&&0===B.length&&V(K,"INVALID_ASSERTION",q);O?J={type:"ASSERTION_XML_ID",xmlId:O,srcOffset:q.srcOffset,parameters:B}:J=za(B,q.srcOffset)}p.push({type:ya,srcOffset:Ya,stepValue:m.value,assertion:J})}e.push({type:"LOCAL_PATH",steps:p,indirection:g,srcOffset:f.srcOffset});g=!1}else 40===f.type?(g&&V(a,"INVALID_INDIRECTION",f),g=!0,a.a.next()):b=!0;g&&f&&e.push({type:"LOCAL_PATH",steps:[],indirection:!0,srcOffset:f.srcOffset});b=e;e=a.a.peek()}if(f=e)f=e.type,f=46===f||45===f||42===f;if(f){d=
null;if(f=a.a.peek())if(46===f.type){var L=a.a.next();if(d=T(a,10,"INVALID_TEMPORAL_OFFSET",L)){f=a.a.peek();g=e=null;if(f&&45===f.type){if(e=Ba(a,a.a.next()))g=e.assertion;f=a.a.peek()}!g&&f&&20===f.type&&(g=W(a,a.a.next()));d={type:"OFFSET_TEMPORAL",srcOffset:L.srcOffset,assertion:g,seconds:d.value,x:e?e.x:null,y:e?e.y:null}}else d=null}else 45===f.type?d=Ba(a,a.a.next()):42===f.type&&(d=a.a.next(),(f=T(a,10,"INVALID_CHARACTER_OFFSET",d))?(e=null,(g=a.a.peek())&&20===g.type&&(e=a.a.next(),l=new u.ArrayInputStream(e.value),
k=g=null,(p=l.peek())&&30===p.type&&(g=p,l.next(),p=l.peek()),p&&41===p.type&&(l.next(),k=T(a,30,"INVALID_ASSERTION",p,l)),l=xa(a,l),null===g&&null===k&&0===l.length?(V(a,"INVALID_ASSERTION",e),e=null):(g||k?L={type:"ASSERTION_TEXT_LOCATION",textBefore:g?g.value:null,textAfter:k?k.value:null,parameters:l,srcOffset:e.srcOffset}:L=za(l,e.srcOffset),e=L)),d={type:"OFFSET_CHARACTER",srcOffset:d.srcOffset,characterOffset:f.value,assertion:e}):d=null);e=a.a.peek()}return{type:"PATH",offset:d,localPaths:b,
srcOffset:c,complete:!!e&&(41===e.type||1===e.type)}}
function Ba(a,b){var c=T(a,10,"INVALID_SPATIAL_OFFSET",b);if(!c)return null;var d=c.value;if(0>d||100<d)V(a,"INVALID_SPATIAL_OFFSET_VALUE",c),d=0>d?0:100<d?100:d;c=T(a,42,"INVALID_SPATIAL_OFFSET",c);if(!c)return null;var e=T(a,10,"INVALID_SPATIAL_OFFSET",c);if(!e)return null;c=e.value;if(0>c||100<c)V(a,"INVALID_SPATIAL_OFFSET_VALUE",e),c=0>c?0:100<c?100:c;e=a.a.peek();var f=null;e&&20===e.type&&(f=W(a,a.a.next()));return{type:"OFFSET_SPATIAL",srcOffset:b.srcOffset,assertion:f,x:d,y:c}}
function V(a,b,c){a.errors.push(S(b,c))}function za(a,b){return{type:"ASSERTION_GENERIC",parameters:a,srcOffset:b}};function X(a,b,c,d,e,f){this.repairedWithXmlIdAssertions=this.missingXmlIdAssertions=!1;this.resolverErrors=[];this.stepsResolved=!0;this.ast=a;this.documentPathname=b;this.container=c;this.offset=d;this.isOpfDocument="package"===this.getDocument().documentElement.nodeName;this.intendedTargetType=e;this.virtualTarget=f}X.prototype.createResolverError=function(a,b,c){this.resolverErrors.push({type:a,documentPath:this.documentPathname,node:b,errorData:c})};
X.prototype.getDocument=function(){return w(this.container)?this.container:this.container.ownerDocument};X.prototype.getTargetElement=function(){if("ELEMENT"===this.intendedTargetType&&null===this.virtualTarget&&(x(this.container)||w(this.container))){var a=this.container.childNodes[this.offset];return x(a)?a:null}return null};X.prototype.getTargetNode=function(){var a=null;null===this.virtualTarget&&(a=x(this.container)||w(this.container)?this.container.childNodes[this.offset]:this.container);return a};function Ca(a,b,c){this.c=a;this.a=b;this.b=c}
Ca.prototype.resolve=function(){for(var a=this.c.steps,b=0;b<a.length;b++){var c=a[b];if("STEP_ELEMENT"===c.type)if(this.a.intendedTargetType="ELEMENT",this.a.virtualTarget)Da(this,c)&&(this.a.stepsResolved=!0,this.a.virtualTarget=null);else{if(x(this.b)){var d=this.b,e=c,f=e.stepValue/2-1,g=d.children,k=g.length;0<=f&&f<k?this.b=g[f]:0>f?this.a.virtualTarget="FIRST_CHILD":(this.a.virtualTarget="LAST_CHILD",f!==k&&(this.a.createResolverError("STEP_VALUE_OUT_OF_BOUNDS",d,e),this.a.stepsResolved=!1));
Da(this,c)}}else if(e=c,this.a.intendedTargetType="TEXT",!this.a.virtualTarget&&x(this.b))if(c=void 0,d=this.b,f=e,e=(f.stepValue>>1)-1,e<d.childElementCount)if(f=-1===e?0:z(d.children[e])+1,f<d.childNodes.length){for(e=d=d.childNodes[f];!c&&e&&!x(e);)e&&3===e.nodeType&&(c=e),e=e.nextSibling;this.b=c||d}else this.a.virtualTarget=-1===e?"FIRST_CHILD":"LAST_CHILD";else this.a.virtualTarget="LAST_CHILD",this.a.createResolverError("STEP_VALUE_OUT_OF_BOUNDS",d,f),this.a.stepsResolved=!1}this.a.virtualTarget?
(this.a.container=this.b,this.a.offset="FIRST_CHILD"===this.a.virtualTarget?0:this.b.childNodes.length):(this.a.container=this.b.parentNode,this.a.offset=z(this.b));return this.a};
function Da(a,b){var c=!1,d=a.b;if((b=b.assertion)&&b.xmlId){if(null!==a.a.virtualTarget||!x(d)||d.id!==b.xmlId){var e=d.ownerDocument.getElementById(b.xmlId);!e||"itemref"===d.nodeName&&"itemref"!==e.nodeName?a.a.createResolverError("XML_ID_ASSERTION_FAILED",d,b):(a.b=e,a.a.repairedWithXmlIdAssertions=!0,a.a.virtualTarget=null,c=!0)}}else null===a.a.virtualTarget&&x(d)&&d.id&&(a.a.missingXmlIdAssertions=!0);return c};function Y(a,b){this.characterOffsetOutOfBounds=!1;this.elementCharacterOffset=null;this.indirectionsResolved=!0;this.spatialOffset=this.sideBias=null;this.stepsResolved=!0;this.temporalOffset=null;this.ast=a;this.localPaths=[];this.addResolvedLocalPath(b)}h=Y.prototype;
h.addResolvedLocalPath=function(a){this.localPaths.push(a);this.stepsResolved=a.stepsResolved;this.container=a.container;this.documentPathname=a.documentPathname;this.intendedTargetType=a.intendedTargetType;this.offset=a.offset;this.virtualTarget=a.virtualTarget};h.getDocument=function(){return w(this.container)?this.container:this.container.ownerDocument};
h.getResolverErrors=function(){for(var a=[],b=0;b<this.localPaths.length;b++){var c=this.localPaths[b];0<c.resolverErrors.length&&a.push.apply(a,ba(c.resolverErrors))}return a};h.getTargetElement=function(){return v(this.localPaths).getTargetElement()};h.getTargetNode=function(){return v(this.localPaths).getTargetNode()};h.getTerminalLocalPath=function(){return v(this.localPaths)};h.isMissingXmlIdAssertions=function(){return this.localPaths.some(function(a){return a.missingXmlIdAssertions})};
h.isRepairedWithXmlIdAssertions=function(){return this.localPaths.some(function(a){return a.repairedWithXmlIdAssertions})};h.isTargetingOpfDocument=function(){return v(this.localPaths).isOpfDocument};function Ea(a){this.indirectionErrors=[];this.rangeStartPath=this.rangeEndPath=this.parentPath=null;this.ast=a}h=Ea.prototype;
h.createDomRange=function(){if(this.isTargetingOpfDocument())return null;var a=null;if(this.rangeStartPath&&this.rangeEndPath){var b=this.rangeStartPath.getDocument();a=b.createRange();a.setStart(this.rangeStartPath.container,this.rangeStartPath.offset);this.rangeEndPath.getDocument()===b&&a.setEnd(this.rangeEndPath.container,this.rangeEndPath.offset)}else this.parentPath&&(a=this.parentPath.getDocument().createRange(),a.setStart(this.parentPath.container,this.parentPath.offset));return a};
h.getElementCharacterOffsets=function(){return Fa(this,"elementCharacterOffset",{characterOffset:0})};h.getParserErrors=function(){return this.ast.errors};h.getResolverErrors=function(){for(var a=[],b=[this.parentPath,this.rangeStartPath,this.rangeEndPath],c=0;c<b.length;c++){var d=b[c];d&&a.push.apply(a,ba(d.getResolverErrors()))}return a};h.getSideBias=function(){return!this.parentPath||this.rangeStartPath||this.rangeEndPath?null:this.parentPath.sideBias};
h.getSpatialOffsets=function(){return Fa(this,"spatialOffset",{x:0,y:0})};h.getTargetElement=function(){if(this.rangeStartPath&&this.rangeEndPath){var a=this.rangeStartPath.getTargetElement();return null!==a&&a===this.rangeEndPath.getTargetElement()?a:null}return this.parentPath?this.parentPath.getTargetElement():null};h.getTemporalOffsets=function(){return Fa(this,"temporalOffset",{seconds:0})};
h.hasElementOffsets=function(){return null!==this.getSpatialOffsets()||null!==this.getTemporalOffsets()||null!==this.getElementCharacterOffsets()};h.hasErrors=function(){return!this.hasParentPathOrRangePaths()||this.isTargetingOpfDocument()||!this.isEveryIndirectionResolved()||0<this.ast.errors.length||!this.isEveryStepAndOffsetParsed()||!this.isEveryStepResolved()||this.isSomeCharacterOffsetOutOfBounds()};
h.hasParentPathOrRangePaths=function(){return null!==this.parentPath&&(null===this.rangeStartPath&&null===this.rangeStartPath||null!==this.rangeStartPath&&null!==this.rangeEndPath)};h.hasRangePaths=function(){return null!==this.rangeStartPath&&null!==this.rangeEndPath};h.hasWarnings=function(){return this.isMissingXmlIdAssertions()||this.isRepairedWithXmlIdAssertions()};
h.isDomRange=function(){var a=this.rangeStartPath,b=this.rangeEndPath;return!!a&&!!b&&!this.isTargetingOpfDocument()&&a.getDocument()===b.getDocument()&&(a.container!==b.container||a.offset!==b.offset)};h.isEveryIndirectionResolved=function(){return 0===this.indirectionErrors.length};h.isEveryStepAndOffsetParsed=function(){return null!==this.ast.parentPath&&this.ast.parentPath.complete&&(!this.ast.rangeStartPath||this.ast.rangeStartPath.complete)&&(!this.ast.rangeEndPath||this.ast.rangeEndPath.complete)};
h.isEveryStepResolved=function(){return null!==this.parentPath&&this.parentPath.stepsResolved&&(!this.rangeStartPath||this.rangeStartPath.stepsResolved)&&(!this.rangeEndPath||this.rangeEndPath.stepsResolved)};h.isMissingXmlIdAssertions=function(){return null!==this.parentPath&&this.parentPath.isMissingXmlIdAssertions()||null!==this.rangeStartPath&&this.rangeStartPath.isMissingXmlIdAssertions()||null!==this.rangeEndPath&&this.rangeEndPath.isMissingXmlIdAssertions()};
h.isOwnedBySingleDocument=function(){return this.rangeStartPath&&this.rangeEndPath?this.rangeStartPath.getDocument()===this.rangeEndPath.getDocument():!0};h.isRepairedWithXmlIdAssertions=function(){return null!==this.parentPath&&this.parentPath.isRepairedWithXmlIdAssertions()||null!==this.rangeStartPath&&this.rangeStartPath.isRepairedWithXmlIdAssertions()||null!==this.rangeEndPath&&this.rangeEndPath.isRepairedWithXmlIdAssertions()};
h.isSomeCharacterOffsetOutOfBounds=function(){return[this.parentPath,this.rangeStartPath,this.rangeEndPath].some(function(a){return null!==a&&a.characterOffsetOutOfBounds})};h.isTargetingElement=function(){return null!==this.getTargetElement()};h.isTargetingOpfDocument=function(){return this.rangeStartPath&&this.rangeEndPath?this.rangeStartPath.isTargetingOpfDocument()||this.rangeEndPath.isTargetingOpfDocument():null!==this.parentPath&&this.parentPath.isTargetingOpfDocument()};
function Fa(a,b,c){var d=null,e=a.rangeStartPath,f=a.rangeEndPath;e&&f?f[b]?d={start:e[b]||c,end:f[b]}:e[b]&&(d={start:e[b],end:null}):(a=a.parentPath)&&a[b]&&(d={start:a[b],end:null});return d};function Ga(a){this.a=a}function Ha(a,b){for(var c=a.a.getTargetNode(),d=[];c&&!x(c);)c&&3===c.nodeType&&d.push(c),c=c.nextSibling;0<d.length?(c=d.find(function(e){if(b<e.length)return!0;b-=e.length;return!1}))?(a.a.container=c,a.a.offset=b):(d=d[d.length-1],a.a.container=d,a.a.offset=d.data.length,a.a.characterOffsetOutOfBounds=0!==b):0<b&&(a.a.characterOffsetOutOfBounds=!0)};function Ia(a){this.b="";this.i=null;this.m=a="string"===typeof a?wa(a):a;this.a=new Ea(a);this.g=a.parentPath?Ja(a.parentPath):null;this.f=a.rangeStartPath?Ja(a.rangeStartPath):null;this.c=a.rangeEndPath?Ja(a.rangeEndPath):null}h=Ia.prototype;h.getResolvedTarget=function(){return this.a};
h.resolve=function(a,b,c){for(a=this.handleIndirection(a,b);a;)(a=Z(this,a))?(b=void 0,"function"===typeof c?b=c(a,this.b):b=c.handleIndirection(a,this.b),a=this.handleIndirection(b.target,b.targetPath)):(a=null,this.b="");return this.a};
h.resolveAsync=function(a,b,c){var d=this;return new Promise(function(e){function f(){var k=Z(d,g),l=null;k?("function"===typeof c?l=c(k,d.b):l=c.handleIndirection(k,d.b),l.then(function(p){g=d.handleIndirection(p.target,p.targetPath);f()}).catch(function(p){t.Logger.logWarning(p);g=d.handleIndirection(null,"");f()})):e(d.a)}var g=d.handleIndirection(a,b);f()})};h.resolveNextLocalPath=function(a,b){return(a=this.handleIndirection(a,b))?Z(this,a):null};
h.addResolvedLocalPath=function(a,b){this.a[a]?this.a[a].addResolvedLocalPath(b):this.a[a]=new Y(this.m[a],b);return this.a[a]};
function Z(a,b){var c=null,d=!1;if(null===b)if(a.g)a.a.parentPath&&(a.a.parentPath.indirectionsResolved=!1,a.a.parentPath.stepsResolved=!1),a.g=null,a.f=null,a.c=null;else if(a.f){if(a.f=null,a.a.rangeStartPath&&(a.a.rangeStartPath.indirectionsResolved=!1,a.a.rangeStartPath.stepsResolved=!1),a.a.parentPath&&a.c){var e=a.a.parentPath;a.b=e.documentPathname;var f=a.c.localPaths[0];f&&f.indirection?d=!0:e&&(c=Z(a,e))}}else a.c&&(a.c=null,a.a.rangeEndPath&&(a.a.rangeEndPath.indirectionsResolved=!1,a.a.rangeEndPath.stepsResolved=
!1));else if(a.g){var g="parentPath";var k=a.g}else a.f?(g="rangeStartPath",k=a.f):a.c&&(g="rangeEndPath",k=a.c);if(b&&g&&k)if((e=k.localPaths.shift())&&b?(b instanceof Y?(b=new X(e,b.documentPathname,b.container,b.offset,b.intendedTargetType,b.virtualTarget),b=new Ca(e,b,b.getTargetNode()||b.container)):(f=new X(e,a.b,b.parentNode,z(b),"ELEMENT",null),b=new Ca(e,f,b)),b=b.resolve()):b=b instanceof Y?new X(e||null,b.documentPathname,b.container,b.offset,b.intendedTargetType,b.virtualTarget):new X(e||
null,a.b,b.parentNode,z(b),"ELEMENT",null),e=a.addResolvedLocalPath(g,b),e.stepsResolved)if(k=k.localPaths[0])k.indirection?d=!0:c=Z(a,e);else{if(Ka(e),La(e),Ma(a,g),g=a.f||a.c)(k=g.localPaths[0])&&k.indirection?d=!0:a.a.parentPath&&(a.b=a.a.parentPath.documentPathname,c=Z(a,a.a.parentPath))}else"parentPath"===g?(a.g=null,a.f=null,a.c=null):Ma(a,g),Ka(e),La(e);d&&e&&((c=e.getTargetElement())||a.a.indirectionErrors.push({from:e.getTargetNode()||e.container,fromPath:e.documentPathname,target:null,targetPath:null}));
return a.i=c}h.handleIndirection=function(a,b){var c=null;w(a)?c=a.documentElement:a&&11===a.nodeType&&x(a.firstChild)?c=a.firstChild:x(a)&&a.parentNode&&(c=a);c||this.a.indirectionErrors.push({from:this.i,fromPath:this.b,target:c,targetPath:b});this.b=b;return c};
function La(a){var b=null;if(a.ast.offset&&a.ast.offset.assertion)b=a.ast.offset.assertion;else{var c=a.getTerminalLocalPath();c.ast&&(c=v(c.ast.steps))&&(b=c.assertion)}b&&(b=b.parameters.find(function(d){return"s"===d.name&&0<d.values.length}))&&(a.sideBias="b"===b.values[0]?"BEFORE":"AFTER")}
function Ka(a){var b=a.ast.offset;if("ELEMENT"===a.intendedTargetType&&b&&a.stepsResolved)if("OFFSET_TEMPORAL"===b.type)a.temporalOffset={seconds:b.seconds},y(b.x)&&y(b.y)&&(a.spatialOffset={x:b.x,y:b.y});else if("OFFSET_SPATIAL"===b.type)a.spatialOffset={x:b.x,y:b.y};else{if("OFFSET_CHARACTER"===b.type){var c=a.getTargetElement();c&&"img"===c.localName&&(a.elementCharacterOffset=b.characterOffset)}}else"TEXT"===a.intendedTargetType&&(c=new Ga(a),b&&"OFFSET_CHARACTER"===b.type&&a.stepsResolved?Ha(c,
b.characterOffset):Ha(c,0))}function Ja(a){return{type:"PATH",offset:a.offset,localPaths:a.localPaths.slice(),srcOffset:a.srcOffset,complete:a.complete}}function Ma(a,b){"parentPath"===b?a.g=null:"rangeStartPath"===b?a.f=null:"rangeEndPath"===b&&(a.c=null)};function Na(a){function b(d){var e=v(d.localPaths);e&&(e=v(e.steps))&&"STEP_TEXT"===e.type&&!d.offset&&(d.offset={type:"OFFSET_CHARACTER",characterOffset:0,assertion:null},c=!0)}var c=!1;a.parentPath&&b(a.parentPath);a.rangeStartPath&&b(a.rangeStartPath);a.rangeEndPath&&b(a.rangeEndPath);return c}
function Oa(a,b){var c=!1;a.parentPath&&0!==a.parentPath.localPaths.length&&0!==a.parentPath.localPaths[0].steps.length?a.rangeStartPath&&!a.rangeEndPath?(b||a.errors.push(S("MISSING_END_RANGE")),c=!0,a.rangeStartPath=null):a.rangeEndPath&&(0===a.rangeEndPath.localPaths.length||0===a.rangeEndPath.localPaths[0].steps.length&&null===a.rangeEndPath.offset)&&(b||a.errors.push(S("PATH_EMPTY",a.rangeEndPath.srcOffset)),c=!0,a.rangeStartPath=null,a.rangeEndPath=null):(b||a.errors.push(S("PATH_EMPTY",a.srcOffset)),
c=!0,a.parentPath=null,a.rangeStartPath=null,a.rangeEndPath=null);return c}
function Pa(a,b){function c(f){f.offset||(f=v(f.localPaths))&&f.indirection&&0===f.steps.length&&(b||a.errors.push(S("PATH_ENDS_WITH_INDIRECTION",f.srcOffset)),d=!0,f.steps.push({type:"STEP_ELEMENT",stepValue:0,assertion:null}))}var d=!1;if(a.parentPath&&!a.parentPath.offset){var e=v(a.parentPath.localPaths);e&&e.indirection&&0===e.steps.length&&(b||a.errors.push(S("PATH_ENDS_WITH_INDIRECTION",e.srcOffset)),d=!0,a.rangeStartPath&&a.rangeEndPath?(Qa(a.rangeStartPath),Qa(a.rangeEndPath),a.parentPath.localPaths.length--):
(e.steps.push({type:"STEP_ELEMENT",stepValue:0,assertion:null}),a.rangeStartPath=null,a.rangeEndPath=null))}a.rangeStartPath&&c(a.rangeStartPath);a.rangeEndPath&&c(a.rangeEndPath);return d}function Ra(a,b){var c=!1;a.parentPath&&(c=Sa(a,a.parentPath,b));a.rangeStartPath&&(c=Sa(a,a.rangeStartPath,b)||c);a.rangeEndPath&&(c=Sa(a,a.rangeEndPath,b)||c);return c}
function Ta(a,b){var c=!1,d=a.rangeStartPath?a.rangeStartPath.offset:null,e=a.rangeEndPath?a.rangeEndPath.offset:null,f=!1;if(d&&e)d.type!==e.type?f=!0:"OFFSET_TEMPORAL"===d.type&&(null!==d.x&&null===e.x||null===d.x&&null!==e.x)&&(d.x=null,d.y=null,e.x=null,e.y=null,c=!0);else if(!d&&e&&"OFFSET_CHARACTER"!==e.type||d&&!e)f=!0;f&&(a.rangeStartPath&&(a.rangeStartPath.offset=null),a.rangeEndPath&&(a.rangeEndPath.offset=null),c=!0);c&&!b&&a.errors.push(S("INCOMPATIBLE_OFFSET_TYPE"));return c}
function Ua(a,b){var c=!1;if(a.parentPath){var d=a.parentPath.localPaths[0];d&&d.indirection&&(d.indirection=!1,c=!0,b||a.errors.push(S("INVALID_INDIRECTION",d.srcOffset)))}return c}function Va(a,b){var c=!1;if(a.parentPath&&a.parentPath.offset){var d=a.parentPath.offset;d="OFFSET_TEMPORAL"===d.type&&null===d.x;a.rangeStartPath&&Wa(a,a.rangeStartPath,d,b)&&(a.rangeStartPath=null,c=!0);a.rangeEndPath&&Wa(a,a.rangeEndPath,d,b)&&(a.rangeEndPath=null,c=!0)}return c}
function Xa(a,b){var c=!1;if(a.parentPath){var d=Za(a,a.parentPath,b);d.h?(c=!0,a.rangeStartPath=null,a.rangeEndPath=null):d.o&&(a.rangeStartPath&&0<a.rangeStartPath.localPaths.length&&(c=!0,a.rangeStartPath=null),a.rangeEndPath&&0<a.rangeEndPath.localPaths.length&&(c=!0,a.rangeEndPath=null))}a.rangeStartPath&&(d=Za(a,a.rangeStartPath,b),c=c||d.h);a.rangeEndPath&&(d=Za(a,a.rangeEndPath,b),c=c||d.h);return c}
function Qa(a){if(a.localPaths[0])a.localPaths[0].indirection=!0;else{var b={type:"STEP_ELEMENT",stepValue:0,assertion:null};a.localPaths.push({type:"LOCAL_PATH",steps:a.offset?[]:[b],indirection:!0})}}
function Za(a,b,c){var d=!1,e=!1,f=b.localPaths.findIndex(function(g){var k=g.steps.findIndex(function(l){return"STEP_TEXT"===l.type});return-1!==k?(e=!0,k<g.steps.length-1&&(c||a.errors.push(S("INVALID_STEP",g.steps[k].srcOffset)),g.steps=g.steps.slice(0,k+1),b.offset=null,d=!0),!0):!1});-1!==f&&f<b.localPaths.length-1&&(b.localPaths=b.localPaths.slice(0,f+1),d=!0);return{h:d,o:e}}
function Sa(a,b,c){var d=!1;if(b.offset){var e=v(b.localPaths);e&&e.indirection&&0===e.steps.length&&(c||a.errors.push(S("PATH_ENDS_WITH_INDIRECTION",e.srcOffset)),b.localPaths.length--,d=!0)}return d}function Wa(a,b,c,d){var e=!1;0<b.localPaths.length?(d||a.errors.push(S("INVALID_RANGE_PATH",b.srcOffset)),e=!0):!b.offset||c&&"OFFSET_SPATIAL"===b.offset.type||(d||a.errors.push(S("INCOMPATIBLE_OFFSET_TYPE",b.offset.srcOffset)),e=!0);return e};api={EpubCfiBuilder:N,EpubCfiError:A,EpubCfiErrorType:{RANGE_PATHS_ALREADY_SET:"RANGE_PATHS_ALREADY_SET",CONTAINER_NOT_ATTACHED_TO_DOCUMENT:"CONTAINER_NOT_ATTACHED_TO_DOCUMENT"},EpubCfiIntendedTargetType:{ELEMENT:"ELEMENT",TEXT:"TEXT"},EpubCfiNodeType:{ROOT:"ROOT",PATH:"PATH",LOCAL_PATH:"LOCAL_PATH",STEP_ELEMENT:"STEP_ELEMENT",STEP_TEXT:"STEP_TEXT",ASSERTION_TEXT_LOCATION:"ASSERTION_TEXT_LOCATION",ASSERTION_XML_ID:"ASSERTION_XML_ID",ASSERTION_GENERIC:"ASSERTION_GENERIC",PARAMETER:"PARAMETER",OFFSET_CHARACTER:"OFFSET_CHARACTER",
OFFSET_TEMPORAL:"OFFSET_TEMPORAL",OFFSET_SPATIAL:"OFFSET_SPATIAL"},EpubCfiParser:{parse:wa,isEpubCfi:function(a){return 0<=a.indexOf("epubcfi(")}},EpubCfiParserErrorType:{INVALID_RANGE_PATH:"INVALID_RANGE_PATH",INCOMPATIBLE_OFFSET_TYPE:"INCOMPATIBLE_OFFSET_TYPE",INVALID_CHARACTER_OFFSET:"INVALID_CHARACTER_OFFSET",INVALID_ASSERTION:"INVALID_ASSERTION",INVALID_EPUBCFI_START:"INVALID_EPUBCFI_START",INVALID_EPUBCFI_END:"INVALID_EPUBCFI_END",INVALID_PARAMETER:"INVALID_PARAMETER",PATH_ENDS_WITH_INDIRECTION:"PATH_ENDS_WITH_INDIRECTION",
INVALID_SPATIAL_OFFSET:"INVALID_SPATIAL_OFFSET",INVALID_SPATIAL_OFFSET_VALUE:"INVALID_SPATIAL_OFFSET_VALUE",INVALID_STEP:"INVALID_STEP",INVALID_INDIRECTION:"INVALID_INDIRECTION",INVALID_TEMPORAL_OFFSET:"INVALID_TEMPORAL_OFFSET",MISSING_END_RANGE:"MISSING_END_RANGE",PATH_EMPTY:"PATH_EMPTY"},EpubCfiResolver:Ia,EpubCfiResolverErrorType:{XML_ID_ASSERTION_FAILED:"XML_ID_ASSERTION_FAILED",STEP_VALUE_OUT_OF_BOUNDS:"STEP_VALUE_OUT_OF_BOUNDS"},EpubCfiSideBias:{BEFORE:"BEFORE",AFTER:"AFTER"},EpubCfiStringifier:{stringifyRootNode:na},
EpubCfiValidator:{addExplicitCharacterOffsets:Na,checkEmptyParentPathAndRangeEnd:Oa,expandEmptyIndirectionLocalPaths:Pa,removeEmptyIndirectionBeforeOffsets:Ra,removeIncompatibleRangeOffsets:Ta,removeInitialIndirectionFromParentPath:Ua,removeInvalidRangeAfterParentPathOffset:Va,removeStepsAfterTextSteps:Xa,runAllValidations:function(a,b){var c=!1;c=Xa(a,b)||c;c=Ua(a,b)||c;c=Va(a,b)||c;c=Ta(a,b)||c;c=Oa(a,b)||c;c=Na(a)||c;c=Pa(a,b)||c;return c=Ra(a,b)||c}},EpubCfiVirtualTarget:{FIRST_CHILD:"FIRST_CHILD",
LAST_CHILD:"LAST_CHILD"}}; }).call(__self); return api; }));
