import { Component, Mixins } from 'vue-property-decorator';
import FeatureSwitchsMixin from '../../../featureSwitchsMixin';
import { GlobalEventBus } from '../../../GlobalEventBus';
import { GlobalEventName } from '../../../GlobalEventName';
import {
  AnnotationSelectorType,
  FragmentSelectorType,
  IAnnotationTarget,
  IFragmentSelector,
} from '../../../lib/colibrio-publishing-framework/colibrio-core-annotation';
import { IPublicationMetadataItem } from '../../../lib/colibrio-publishing-framework/colibrio-core-publication-base';
import {
  EpubCfiParser,
  IEpubCfiRootNode,
} from '../../../lib/colibrio-publishing-framework/colibrio-core-selector-epubcfi';
import {
  IReaderPublicationNavigationCollection,
  IReaderPublicationNavigationItem,
  IReaderPublicationNavigationItemReference,
  IRenderableDocumentPageNavigationContext,
  NavigationCollectionType,
} from '../../../lib/colibrio-publishing-framework/colibrio-readingsystem-base';
import { AnnotationUtils } from '../../../model/AnnotationUtils';
import { IUserAnnotationHighlight } from '../../../model/IUserAnnotation';
import { PublicationState } from '../../../model/PublicationState';
import { readerModel } from '../../../model/ReaderModel';
import ColibrioPublicationSearchPanel from '../search-panel/colibrio-publication-nav-search-panel.vue';
import ColibrioPublicationNavItemTree from './colibrio-publication-nav-item-tree.vue';
import { ColibrioPublicationNavCollection } from './ColibrioPublicationNavCollection';
import { ColibrioPublicationNavItem } from './ColibrioPublicationNavItem';

@Component({
  components: {
    ColibrioPublicationNavItemTree,
    ColibrioPublicationSearchPanel,
  },
})
export default class ColibrioPublicationNav extends Mixins(
  FeatureSwitchsMixin
) {
  navCollections: ColibrioPublicationNavCollection[] = [];
  readerNavItemMap: Map<
    IReaderPublicationNavigationItem,
    ColibrioPublicationNavItem
  > = new Map();
  publicationTitle: string = '';
  activeReaderNavItems: Set<IReaderPublicationNavigationItem> = new Set();
  publicationMetadata: IPublicationMetadataItem[] = [];
  annotationList: IUserAnnotationHighlight[] = [];
  AnnotationUtils = AnnotationUtils;

  // Life-cycle hook
  mounted() {
    let readingSystem = readerModel.getReadingSystem();
    if (!readingSystem) {
      console.error('ReadingSystem not set!');
      return;
    }
    let readerPublication = readingSystem.getReaderPublications()[0];
    let sourcePublication = readerPublication.getSourcePublication();

    this.publicationMetadata = sourcePublication.getMetadata().getAll();

    readerPublication.fetchPublicationNavigation().then(navDocument => {
      // Transform the navigation collection from the reader to a view model object, where we can add more properties required for our view.
      let readerNavCollections = navDocument.getNavigationCollections();
      this.navCollections = readerNavCollections.map(readerNavCollection => {
        let children: ColibrioPublicationNavItem[] | null = null;
        if (readerNavCollection.children) {
          children = this.createColibrioPublicationNavItems(
            readerNavCollection.children
          );
        }

        let navCollection: ColibrioPublicationNavCollection = {
          children: children,
          expanded: readerNavCollection.type === NavigationCollectionType.TOC, // Let TOC be expanded by default
          title: readerNavCollection.title || '',
          type: readerNavCollection.type,
        };
        return navCollection;
      });
    });

    let titleObj = readerPublication
      .getSourcePublication()
      .getMetadata()
      .getTitles()[0];

    if (titleObj) {
      this.publicationTitle = titleObj.title || '';
    }

    GlobalEventBus.$on(
      GlobalEventName.PUBLICATION_STATE_CHANGED,
      (newState: PublicationState) => {
        if (newState.title) {
          this.publicationTitle = newState.title;
        }
      }
    );

    readingSystem.addEngineEventListener<'visiblePagesChanged'>(
      'visiblePagesChanged',
      event => {
        let navigationContextPromises = event.view
          .getVisiblePages()
          .map(visiblePage => visiblePage.fetchNavigationContext());

        Promise.all(navigationContextPromises).then(
          (navigationContexts: IRenderableDocumentPageNavigationContext[]) => {
            // Set active to false for all currently active nav items
            this.activeReaderNavItems.forEach(readerNavItem => {
              let navItem = this.readerNavItemMap.get(readerNavItem);
              if (navItem) {
                navItem.active = false;
              }

              let currentParent = readerNavItem.parent;
              while (currentParent) {
                let parentNavItem = this.readerNavItemMap.get(currentParent);
                if (parentNavItem) {
                  parentNavItem.expanded = false;
                }
                currentParent = currentParent.parent;
              }
            });
            this.activeReaderNavItems.clear();

            let hasTocItem = false;
            let hasPageListItem = false;

            /**
             * Activate all nav items that point to the visible pages.
             */
            navigationContexts.forEach(context => {
              context.getNavigationItemReferences().forEach(reference => {
                let collectionType = reference.getNavigationCollection().type;

                if (collectionType === NavigationCollectionType.TOC) {
                  hasTocItem = true;
                } else if (
                  collectionType === NavigationCollectionType.PAGE_LIST
                ) {
                  hasPageListItem = true;
                }

                this.activeItemByNavItemReference(reference);
              });
            });

            /**
             * If there are no reference to a page list item or toc item on the visible pages. Search backwards to find the nearest.
             */
            if (!hasTocItem && navigationContexts[0]) {
              let tocItemReference = navigationContexts[0].getNearestPreviousNavigationItem(
                NavigationCollectionType.TOC
              );
              if (tocItemReference) {
                this.activeItemByNavItemReference(tocItemReference);
              }
            }

            if (!hasPageListItem && navigationContexts[0]) {
              let pageListItemReference = navigationContexts[0].getNearestPreviousNavigationItem(
                NavigationCollectionType.PAGE_LIST
              );
              if (pageListItemReference) {
                this.activeItemByNavItemReference(pageListItemReference);
              }
            }
          }
        );
      }
    );

    GlobalEventBus.$on(
      GlobalEventName.READER_ANNOTATION_LAYER_CHANGED,
      this.annotationRebuildList
    );
  }

  destroy() {
    this.publicationMetadata = [];
    this.navCollections = [];
    this.readerNavItemMap.clear();
    this.activeReaderNavItems.clear();
  }

  close() {
    GlobalEventBus.$emit(GlobalEventName.APP_NAV_DRAWER_CLOSE_INTENT);
  }

  annotationRebuildList() {
    let readingSystem = readerModel.getReadingSystem();
    let annotationLayer = readingSystem
      .getViewByName('mainView')!
      .getAnnotationLayerByName('annotations');
    let list: IUserAnnotationHighlight[] = [];
    if (annotationLayer) {
      annotationLayer.getViewAnnotationTargets().forEach(target => {
        let data = target.getCustomData();
        if (data) {
          AnnotationUtils.fetchRootNavigationItemTitle(data).then(title => {
            data._navItemReferenceTitle = title;
            data._highlightColor = AnnotationUtils.getTargetHighlightColor(
              data
            );
            data._bodyText = AnnotationUtils.getBodyCommentText(data);
            data._highlightedText = AnnotationUtils.getBodyHighlightText(data);
            list.push(data);
          });
        }
      });
    }

    this.annotationList = list;
  }

  getNavCollectionTitle(
    navCollection: IReaderPublicationNavigationCollection
  ): string {
    if (navCollection.title) {
      return navCollection.title;
    }

    switch (navCollection.type) {
      case NavigationCollectionType.TOC:
        return this.$i18n.t('navigation.toc').toString();

      case NavigationCollectionType.LANDMARKS:
        return this.$i18n.t('navigation.landmarks').toString();

      case NavigationCollectionType.PAGE_LIST:
        return this.$i18n.t('navigation.pagelist').toString();

      case NavigationCollectionType.LIST_OF_VIDEO_CLIPS:
        return this.$i18n.t('navigation.videoclips').toString();

      case NavigationCollectionType.LIST_OF_AUDIO_CLIPS:
        return this.$i18n.t('navigation.audioclips').toString();

      case NavigationCollectionType.LIST_OF_ILLUSTRATIONS:
        return this.$i18n.t('navigation.audioclips').toString();

      case NavigationCollectionType.LIST_OF_TABLES:
        return this.$i18n.t('navigation.tables').toString();
    }
    if (navCollection.title) {
      return navCollection.title;
    }

    return this.$i18n.t('navigation.other_links').toString();
  }

  goToSelector(selector: IUserAnnotationHighlight['target']['selector']) {
    let annotationTarget = {
      selector: selector,
    };
    GlobalEventBus.$emit(
      GlobalEventName.APP_NAV_DRAWER_NAV_ITEM_CLICKED,
      annotationTarget
    );
  }

  goToChildNavItem(childNavItem: IReaderPublicationNavigationItem) {
    if (childNavItem.annotationTarget) {
      GlobalEventBus.$emit(
        GlobalEventName.APP_NAV_DRAWER_NAV_ITEM_CLICKED,
        childNavItem.annotationTarget
      );
    }
  }

  onClosePublicationClicked() {
    GlobalEventBus.$emit(GlobalEventName.READER_PUBLICATION_CLOSE_INTENT);
  }

  private activeItemByNavItemReference(
    reference: IReaderPublicationNavigationItemReference
  ) {
    let readerNavItem = reference.getNavigationItem();

    let navItem = this.readerNavItemMap.get(readerNavItem);
    if (navItem) {
      navItem.active = true;
      this.activeReaderNavItems.add(readerNavItem);

      let currentParent = readerNavItem.parent;
      while (currentParent) {
        let parentNavItem = this.readerNavItemMap.get(currentParent);
        if (parentNavItem) {
          parentNavItem.expanded = true;
        }
        currentParent = currentParent.parent;
      }
    }
  }

  private createColibrioPublicationNavItems(
    readerItems: IReaderPublicationNavigationItem[]
  ): ColibrioPublicationNavItem[] {
    return readerItems.map(readerItem => {
      let children: ColibrioPublicationNavItem[] | null = null;
      if (readerItem.children) {
        children = this.createColibrioPublicationNavItems(readerItem.children);
      }

      let navItem: ColibrioPublicationNavItem = {
        active: false,
        annotationTarget: readerItem.annotationTarget,
        children: children,
        expanded: false,
        textContent: readerItem.textContent,
      };
      this.readerNavItemMap.set(readerItem, navItem);

      return navItem;
    });
  }

  async sortTOC() {
    let tocAnnotationTargets: IAnnotationTarget[] = [];

    let publicationNavigation = await readerModel
      .getReadingSystem()
      .getReaderPublications()[0]
      .fetchPublicationNavigation();

    publicationNavigation.getNavigationCollections().forEach(navCollection => {
      if (
        navCollection.children &&
        navCollection.type === NavigationCollectionType.TOC
      ) {
        checkItem(navCollection.children);
      }

      function checkItem(items: IReaderPublicationNavigationItem[]) {
        items.forEach((childItem: IReaderPublicationNavigationItem) => {
          if (childItem.annotationTarget) {
            tocAnnotationTargets.push(childItem.annotationTarget);
          }
          if (childItem.children) {
            checkItem(childItem.children);
          }
        });
      }
    });

    let sortableAnnotationTargets = this.getSortableAnnotationTargets(
      tocAnnotationTargets
    );
    return sortableAnnotationTargets.sort((a: any, b: any) => {
      return this.compareStepWithIndex(a, b, 0);
    });
  }

  compareStepWithIndex(
    stepsA: number[],
    stepsB: number[],
    index: number
  ): number {
    if (index < stepsA.length && index < stepsB.length) {
      let diff = stepsA[index] - stepsB[index];
      if (diff === 0) {
        return this.compareStepWithIndex(stepsA, stepsB, index + 1);
      } else {
        return diff;
      }
    } else {
      return stepsA.length - stepsB.length;
    }
  }

  getSortablePathFromEpubCfiRootNode(rootNode: IEpubCfiRootNode): number[] {
    //let sortableString = '';
    let steps: number[] = [];

    if (rootNode.parentPath) {
      rootNode.parentPath.localPaths.forEach(localPath => {
        localPath.steps.forEach(step => {
          //sortableString += '/' + step.stepValue;
          steps.push(step.stepValue);
        });
      });
    }

    if (rootNode.rangeStartPath) {
      rootNode.rangeStartPath.localPaths.forEach(localPath => {
        localPath.steps.forEach(step => {
          //sortableString += '/' + step.stepValue;
          steps.push(step.stepValue);
        });
      });
    }
    return steps;
  }

  getSortableAnnotationTargets(
    annotationTargets: IAnnotationTarget[]
  ): ISortableAnnotationTarget[] {
    let result: ISortableAnnotationTarget[] = [];
    annotationTargets.forEach(annotationTarget => {
      if (
        typeof annotationTarget.selector === 'object' &&
        annotationTarget.selector.type ===
          AnnotationSelectorType.FRAGMENT_SELECTOR
      ) {
        let selector = annotationTarget.selector as IFragmentSelector;

        if (selector.conformsTo === FragmentSelectorType.EPUB3) {
          let rootNode = EpubCfiParser.parse(selector.value);

          let sortableSteps = this.getSortablePathFromEpubCfiRootNode(rootNode);
          result.push({
            sortableSteps: sortableSteps,
            annotationTarget: annotationTarget,
          });
        }
      }
    });

    return result;
  }
}

export interface ISortableAnnotationTarget {
  sortableSteps: number[];
  annotationTarget: IAnnotationTarget;
}
