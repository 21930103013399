import { GlobalEventName } from '../GlobalEventName';

type publication_state_code =
  | 'empty'
  | 'presenting'
  | 'loading'
  | 'failed'
  | 'returned';
type download_state_code = 'null' | 'downloading' | 'downloaded' | 'failed';

export interface PublicationState {
  state: publication_state_code;
  title?: string;
  nature: string;
  expiration_date?: string;
  return_url?: string;
  identifier?: string;
  download_state: download_state_code;
  error_code?: string;
}

export class DefaultPublicationState implements PublicationState {
  public state: publication_state_code = 'empty';
  public download_state: download_state_code = 'null';
  public identifier?: string = undefined;
  public error_code?: string = undefined;
  public title?: string = undefined;
  public nature: string = '';
  public expiration_date?: string = undefined;
  public return_url?: string = undefined;

  public constructor(init?: Partial<PublicationState>) {
    Object.assign(this, init);
  }

  public update(change: Partial<PublicationState>) {
    Object.assign(this, change);
  }
}
