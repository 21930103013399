/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import { IRandomAccessDataSource } from '../lib/colibrio-publishing-framework/colibrio-core-io-base';
import { COLIBRIO_LICENSE_API_KEY } from '../constants';
import { ReadingSystemEngine } from '../lib/colibrio-publishing-framework/colibrio-readingsystem-engine';
import { ReadingSystemIndexEngine } from '../lib/colibrio-publishing-framework/colibrio-readingsystem-indexengine';
import { IReaderPublication } from '../lib/colibrio-publishing-framework/colibrio-readingsystem-base';
import { PublicationLoader } from './PublicationLoader';
import { MediaType } from '../lib/colibrio-publishing-framework/colibrio-core-io-base';
import { IEpubReaderPublication } from '../lib/colibrio-publishing-framework/colibrio-readingsystem-formatadapter-epub';
import { PageProgressionDirection } from '../lib/colibrio-publishing-framework/colibrio-core-publication-base';

export class ReaderModel {
  public indexEngine: ReadingSystemIndexEngine = new ReadingSystemIndexEngine();
  private readingSystem: ReadingSystemEngine;
  private publicationLoader: PublicationLoader;
  public isEmbedded = window.parent !== window;
  public useDarkMode: boolean =
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches;
  public annotationStyling = {
    annotationHighlightColorRed: '#ffc5cb',
    annotationHighlightColorGreen: '#d2f7b4',
    annotationHighlightColorBlue: '#9CDEFF',
    annotationHighlightColorYellow: '#f7d80c',
    get annotationHighlightColorDefault(): string {
      return this.annotationHighlightColorYellow;
    },
  };

  public readerPublicationData:
    | IReaderModelPublicationData
    | undefined = undefined;

  constructor() {
    this.readingSystem = ReaderModel.createReadingSystemEngine();
    this.publicationLoader = new PublicationLoader(this.readingSystem);
  }

  async loadPublicationFromBlob(blob: Blob): Promise<IReaderPublication> {
    let readerPublication = await this.publicationLoader.loadPublicationFromBlob(
      blob
    );
    this.indexEngine.mountReaderPublication(readerPublication);
    this.populateReaderModelPublicationData(readerPublication);
    return readerPublication;
  }

  async loadPublicationFromUrl(
    publicationUrl: string,
    contentLength?: number
  ): Promise<IReaderPublication> {
    let readerPublication = await this.publicationLoader.loadPublicationFromUrl(
      publicationUrl,
      contentLength
    );
    this.indexEngine.mountReaderPublication(readerPublication);
    this.populateReaderModelPublicationData(readerPublication);
    return readerPublication;
  }

  public async loadPublicationFromDataSource(
    dataSource: IRandomAccessDataSource,
    mediaType: string
  ): Promise<IReaderPublication> {
    let readerPublication = await this.publicationLoader.loadFromDataSource(
      dataSource,
      mediaType
    );
    this.indexEngine.mountReaderPublication(readerPublication);
    this.populateReaderModelPublicationData(readerPublication);
    return readerPublication;
  }

  async loadPublicationFromBase64(base64: string): Promise<IReaderPublication> {
    let readerPublication = await this.publicationLoader.loadPublicationFromBase64(
      base64
    );
    this.indexEngine.mountReaderPublication(readerPublication);
    this.populateReaderModelPublicationData(readerPublication);
    return readerPublication;
  }

  unloadAll(): void {
    this.readingSystem
      .getReaderPublications()
      .forEach(readerPublication =>
        this.indexEngine.dismountReaderPublication(readerPublication)
      );
    this.readingSystem.destroy();
    this.publicationLoader.destroy();
    this.readingSystem = ReaderModel.createReadingSystemEngine();
    this.publicationLoader = new PublicationLoader(this.readingSystem);
  }

  getReadingSystem(): ReadingSystemEngine {
    return this.readingSystem;
  }

  getReaderWindowContext() {
    return window.parent === window ? window : window.parent;
  }

  private static createReadingSystemEngine(): ReadingSystemEngine {
    return new ReadingSystemEngine({
      platformWorkaroundOptions: {
        firefoxCspConnectDomains: ['*'],
      },
      licenseApiKey: COLIBRIO_LICENSE_API_KEY,
    });
  }

  private populateReaderModelPublicationData(
    readerPublication: IReaderPublication
  ) {
    let languages = readerPublication
      .getSourcePublication()
      .getMetadata()
      .getLanguages();
    let language = languages.length > 0 ? languages[0].lang : '';
    let titles = readerPublication
      .getSourcePublication()
      .getMetadata()
      .getTitles();
    let title = titles.length > 0 ? titles[0].title : '';

    this.readerPublicationData = {
      isFixedLayout:
        readerPublication.getSourcePublication().getDefaultLayout() ===
        'PRE_PAGINATED',
      hasMediaOverlays: readerPublication.hasSyncMedia(),
      language: language,
      title: title,
      pageProgressionDirection: readerPublication
        .getSourcePublication()
        .getPageProgressionDirection(),
      isScripted: false,
    };

    if (
      readerPublication.getSourcePublication().getMediaType() ===
      MediaType.APPLICATION_EPUB_ZIP
    ) {
      this.readerPublicationData.isScripted =
        (readerPublication as IEpubReaderPublication)
          .getSourcePublication()
          .getSpine()
          .filter(item => {
            return item.isScripted();
          }).length > 0;
    }
  }
}

export let readerModel = new ReaderModel();

export interface IReaderModelPublicationData {
  title: string;
  language: string;
  isFixedLayout: boolean;
  isScripted: boolean;
  hasMediaOverlays: boolean;
  pageProgressionDirection: PageProgressionDirection;
}
