/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import { ILicenseOptions } from '../lib/colibrio-publishing-framework/colibrio-readingsystem-base';
import { IPublication } from '../lib/colibrio-publishing-framework/colibrio-core-publication-base';
import { Random } from '../lib/colibrio-publishing-framework/colibrio-core-encryption';

export class License {
  /**
   * Get extra license options required when using the Colibrio Reader Cloud License SDK.
   *
   * @param publication
   */
  static getLicenseOptions(publication: IPublication): ILicenseOptions {
    let publicationIdentifier = publication.getMetadata().getIdentifiers()[0];
    let publicationToken: string;
    if (publicationIdentifier) {
      publicationToken = publicationIdentifier.identifier;
    } else {
      publicationToken = 'unknown';
    }

    let userToken = Random.getRandomHexString(16);

    if (window.localStorage) {
      let saveduserToken = localStorage.getItem('colibrioUserToken');
      if (saveduserToken) {
        userToken = saveduserToken;
      } else {
        localStorage.setItem('colibrioUserToken', userToken);
      }
    }

    return {
      publicationToken: publicationToken,
      userToken: userToken,
    };
  }
}
