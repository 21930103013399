/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import { BrowserDetector } from '../../lib/colibrio-publishing-framework/colibrio-core-base';
import { ICustomUtteranceData } from './ICustomUtteranceData';
import {
  ITtsSynthesizer,
  ITtsSynthesizerContext,
} from '../../lib/colibrio-publishing-framework/colibrio-readingsystem-base';

const MUTE_VOLUME = 0.000001; // Cant actually set it to 0 because then it won't play/fire any end event

/**
 * An example TTS Speech Synthesizer, using the Web Speech API. This API has many quirks and is not very reliable, but
 * works as a basic implementation.
 */
export class WebSpeechTtsSynthesizer
  implements ITtsSynthesizer<ICustomUtteranceData> {
  private boundaryListener: ((evt: Event) => void) | null = null;
  private cancelTimeoutId: number | null = null;
  private delayedPlayTimeoutId: number | null = null;
  private endListener: ((evt: Event) => void) | null = null;
  private isPlaying: boolean = false;
  private muted: boolean = false;
  private paused: boolean = false;
  private playbackRate: number = 1;
  private recentlyCanceled: boolean = false;
  private speakCharOffset: number = 0;
  private webSpeechUtterance: SpeechSynthesisUtterance | null = null;
  private volume: number = 1;

  constructor() {
    // If we don't do this, it keeps reading the current utterance even after you refresh the page.
    window.addEventListener('unload', () => {
      speechSynthesis.cancel();
    });
  }

  stop() {
    if (this.delayedPlayTimeoutId) {
      window.clearTimeout(this.delayedPlayTimeoutId);
      this.delayedPlayTimeoutId = null;
    }
    this.removeListeners();
    if (!this.paused) {
      this.isPlaying = false;
      this.paused = true;
      this.cancel();
    }
  }

  setPlaybackRate(playbackRate: number) {
    if (this.webSpeechUtterance) {
      // Most browsers, will not apply the rate change on an already playing utterance.
      // A fix could be to save current position, and restart the playback.
      this.webSpeechUtterance.rate = playbackRate;
      this.playbackRate = playbackRate;
    }
  }

  setVolume(volume: number) {
    this.volume = volume;
    if (this.webSpeechUtterance) {
      // Most browsers, will not apply the volume change on an already playing utterance.
      // A fix could be to save current position, and restart the playback.
      this.webSpeechUtterance.volume = volume;
    }
  }

  setVoice(language: string) {
    let allVoices = window.speechSynthesis.getVoices();
    let langVoice = allVoices.find(voice => voice.lang === language);
    if (!langVoice) {
      langVoice = allVoices.find(voice => voice.lang.startsWith(language));
    }
    if (langVoice && this.webSpeechUtterance) {
      this.webSpeechUtterance.voice = langVoice;
    }
  }

  speak(
    utterance: ICustomUtteranceData,
    charOffset: number,
    context: ITtsSynthesizerContext
  ): void {
    this.speakCharOffset = charOffset;
    if (this.delayedPlayTimeoutId) {
      window.clearTimeout(this.delayedPlayTimeoutId);
      this.delayedPlayTimeoutId = null;
    }
    let text = utterance.text.slice(charOffset);
    this.setUtterance(
      new SpeechSynthesisUtterance(text),
      context.onUtteranceEnd,
      context.onBoundary
    );
    if (utterance.language) {
      this.setVoice(utterance.language);
    }
    this.play();
  }

  private setUtterance(
    utterance: SpeechSynthesisUtterance,
    endListener: (() => void) | null,
    boundaryListener: ((startOffset: number) => void) | null
  ) {
    this.removeListeners();
    this.paused = false;
    this.cancel();
    this.isPlaying = false;
    this.webSpeechUtterance = utterance;
    if (endListener) {
      this.setEndListener(endListener);
    }
    if (boundaryListener) {
      this.setBoundaryListener(boundaryListener);
    }
  }

  private play() {
    if (this.webSpeechUtterance) {
      this.webSpeechUtterance.rate = this.playbackRate;
      if (this.muted) {
        this.webSpeechUtterance.volume = MUTE_VOLUME;
      } else {
        this.webSpeechUtterance.volume = this.volume;
      }
      if (this.paused && window.speechSynthesis.paused) {
        window.speechSynthesis.resume();
      } else {
        if (this.recentlyCanceled) {
          /* if you do speak(); cancel(); speak(); too fast, it doesn't always speak, so we put a delay before
           * speaking if we recently canceled.
           */
          this.delayedPlayTimeoutId = window.setTimeout(() => {
            this.delayedPlayTimeoutId = null;
            this.delayedPlay();
          }, 200);
        } else {
          if (BrowserDetector.isBrowser('Firefox')) {
            this.replaceUtteranceWithClone();
          }
          window.speechSynthesis.speak(this.webSpeechUtterance);
          window.speechSynthesis.resume();
          this.isPlaying = true;
        }
        this.paused = false;
      }
    }
  }

  private delayedPlay() {
    // Make sure it should still speak after the delay.
    if (this.webSpeechUtterance && !this.isPlaying && !this.paused) {
      if (BrowserDetector.isBrowser('Firefox')) {
        this.replaceUtteranceWithClone();
      }

      window.speechSynthesis.speak(this.webSpeechUtterance);
      window.speechSynthesis.resume();

      this.isPlaying = true;
    }
  }

  private cancel() {
    this.recentlyCanceled = true;
    if (this.cancelTimeoutId) {
      window.clearTimeout(this.cancelTimeoutId);
    }
    window.speechSynthesis.cancel();
    this.paused = false;
    this.isPlaying = false;
    this.cancelTimeoutId = window.setTimeout(() => {
      this.recentlyCanceled = false;
    }, 200);
  }

  private setEndListener(listener: () => void) {
    // Remove the old listener
    if (this.webSpeechUtterance && this.endListener) {
      this.webSpeechUtterance.removeEventListener('end', this.endListener);
    }
    // Add the new one.
    this.endListener = (_evt: Event) => {
      listener();
    };
    if (this.webSpeechUtterance) {
      this.webSpeechUtterance.addEventListener('end', this.endListener);
    }
  }

  private setBoundaryListener(listener: (startOffset: number) => void) {
    // Remove the old listener
    if (this.webSpeechUtterance && this.boundaryListener) {
      this.webSpeechUtterance.removeEventListener(
        'boundary',
        this.boundaryListener
      );
    }
    // Add the new one.
    this.boundaryListener = (evt: Event) => {
      listener((evt as SpeechSynthesisEvent).charIndex + this.speakCharOffset);
    };
    if (this.webSpeechUtterance) {
      this.webSpeechUtterance.addEventListener(
        'boundary',
        this.boundaryListener
      );
    }
  }

  private removeListeners() {
    if (this.webSpeechUtterance) {
      if (this.endListener) {
        this.webSpeechUtterance.removeEventListener('end', this.endListener);
      }
      if (this.boundaryListener) {
        this.webSpeechUtterance.removeEventListener(
          'boundary',
          this.boundaryListener
        );
      }
    }
  }

  private replaceUtteranceWithClone() {
    // Firefox had some problems reusing the same utterance, so we create a new one just in case.
    if (this.webSpeechUtterance) {
      let utterance = new SpeechSynthesisUtterance();
      utterance.volume = this.webSpeechUtterance.volume;
      utterance.rate = this.webSpeechUtterance.rate;
      utterance.pitch = this.webSpeechUtterance.pitch;
      utterance.text = this.webSpeechUtterance.text;
      utterance.voice = this.webSpeechUtterance.voice;
      utterance.lang = this.webSpeechUtterance.lang;
      this.removeListeners();
      if (this.endListener) {
        utterance.addEventListener('end', this.endListener);
      }
      if (this.boundaryListener) {
        utterance.addEventListener('boundary', this.boundaryListener);
      }
      this.webSpeechUtterance = utterance;
    }
  }
}
