/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {
    ContentBlockClass,
    IContentBlock,
    IContentBlockSpeechFragment,
    IContentBlockTree,
    IReaderPublication,
    ITtsContentBlockContext,
    ITtsUtteranceProvider,
    MediaContentBlockType
} from "../../lib/colibrio-publishing-framework/colibrio-readingsystem-base";
import {ICustomUtteranceData} from "./ICustomUtteranceData";

export class TtsUtteranceProvider implements ITtsUtteranceProvider<ICustomUtteranceData, ICustomSpeechFragment> {

    private excludedBlocks: IContentBlock[] = [];
    private flattenedContentBlockTree: IContentBlock[] = [];
    private publicationLanguage: string | undefined;

    constructor(readerPublication: IReaderPublication | null) {
        if (readerPublication) {
            let allLanguages = readerPublication.getSourcePublication().getMetadata().getLanguages();
            this.publicationLanguage = allLanguages.map(lang => lang.lang)[0];
        }

    }

    createTtsUtteranceFromSpeechFragment(speechFragment: ICustomSpeechFragment, fragmentText: string): Promise<ICustomUtteranceData> {
        let utter: ICustomUtteranceData = {
            text: fragmentText.replace(/\s{2,}/g, ' '),
            language: speechFragment.lang || this.publicationLanguage
        };
        if (speechFragment.altText) {
            utter.text = speechFragment.altText.replace(/\s{2,}/g, ' ');
        } else if (speechFragment.descriptionText) {
            utter.text = speechFragment.descriptionText.replace(/\s{2,}/g, ' ');
        }

        return Promise.resolve(utter);
    }

    getSpeechFragmentsFromContentBlock(contentBlock: IContentBlock, _context: ITtsContentBlockContext): ICustomSpeechFragment[] {
        let excluded = this.excludedBlocks.indexOf(contentBlock) > 0;

        if (excluded) {
            return [];
        }

        let fragments: ICustomSpeechFragment[] = [];
        let blockAttributes = contentBlock.getAttributes();
        let roleAttribute = blockAttributes.find(attr => attr.name === 'role');
        let hiddenAttribute = blockAttributes.find(attr => attr.name === 'aria-hidden');

        let exclude = !!(roleAttribute && (roleAttribute.value === 'presentation' || roleAttribute.value === 'none') || (hiddenAttribute && hiddenAttribute.value === 'true'));

        if (!exclude) {
            let altAttribute = blockAttributes.find(attr => attr.name === 'alt');
            let describedByAttribute = blockAttributes.find(attr => attr.name === 'aria-describedby');
            let descriptionBlock: IContentBlock | undefined;
            let descriptionText: string = '';
            let lang: string | null = contentBlock.getLanguage();

            if (describedByAttribute) {
                descriptionBlock = this._getBlockByElementId(contentBlock.getContentBlockTree(), describedByAttribute.value);
                if (descriptionBlock) {
                    this._excludeBlock(descriptionBlock);
                    descriptionText = this._getContentBlocksText(this._flattenContentBlocks(descriptionBlock.getChildren())).join(' ');
                }
            }

            if (contentBlock.getBlockClass() === ContentBlockClass.MEDIA) {
                let fragment: ICustomSpeechFragment = {
                    endOffset: 0,
                    startOffset: 0,
                }

                if(lang) {
                    fragment.lang = lang;
                }

                if (altAttribute && altAttribute.value && contentBlock.getBlockType() === MediaContentBlockType.IMAGE) {
                    fragment.altText = 'Image alt text: ' + altAttribute.value;
                    fragments.push(fragment);
                } else if (!exclude) {
                    fragment.altText = 'Media Element ' + contentBlock.getBlockType();
                    fragments.push(fragment);
                }

                if (descriptionText) {
                    fragment.descriptionText = descriptionText;
                    fragments.push(fragment);
                }

            }

            if (fragments.length > 0) {
                return fragments;
            }

            let textFragments: IContentBlockSpeechFragment[] = [];
            let textContent = contentBlock.getTextContent();
            if (textContent.trim()) {
                let regexp = /[\s\S]+?(?:[.:?!]+\s|$)/g; // Naive regexp to split text content into sentences.
                let splitContent = regexp.exec(textContent);

                while (splitContent) {
                    let fragment: ICustomSpeechFragment = {
                        startOffset: splitContent.index,
                        endOffset: splitContent.index + splitContent[0].length
                    }

                    if(lang) {
                        fragment.lang = lang;
                    }

                    textFragments.push(fragment);
                    splitContent = regexp.exec(textContent);
                }
            }
            return textFragments;
        } else {
            return [];
        }
    }

    _getBlockByElementId(tree: IContentBlockTree, lookupId: string): IContentBlock | undefined {
        if (this.flattenedContentBlockTree.length == 0) {
            this.flattenedContentBlockTree = this._flattenContentBlocks(tree.getContentBlocks());
        }
        return this.flattenedContentBlockTree.find((block) => {
            let attributes = block.getAttributes();
            if (attributes.length > 0) {
                let id = attributes.find(attr => attr.name === 'id');
                if (id) {
                    return id.value === lookupId;
                } else {
                    return false;
                }

            } else {
                return false;
            }
        });

    }

    _flattenContentBlocks(blocks: IContentBlock[]): IContentBlock[] {
        let collection: IContentBlock[] = [];
        blocks.forEach((block: IContentBlock) => {
            this._addContentBlockToCollection(block, collection);
        });
        return collection;
    }

    _addContentBlockToCollection(block: IContentBlock, collection: IContentBlock[]): void {
        collection.push(block);
        block.getChildren().forEach((childBlock: IContentBlock) => {
            this._addContentBlockToCollection(childBlock, collection);
        });
    }

    _getContentBlocksText(blocks: IContentBlock[]): string[] {
        let textContent: string[] = [];
        blocks.forEach((block: IContentBlock) => {
            let text = block.getTextContent();
            if (text) {
                textContent.push(text);
            }
        });
        return textContent;
    }

    _excludeBlock(block: IContentBlock, recursive: boolean = true) {
        this.excludedBlocks.push(block);
        if (recursive) {
            let blocks: IContentBlock[] = this._flattenContentBlocks([block]);
            blocks.forEach((block: IContentBlock) => {
                this.excludedBlocks.push(block);
            })
        }
    }
}

export interface IUtteranceModifier {
    filter: IUtteranceModifierFilter;
    actions: IUtteranceModifierAction[];
}

export interface IUtteranceModifierExcludeAction extends IUtteranceModifierAction {

}

export interface IUtteranceModifierSsmlDecoratorAction extends IUtteranceModifierAction {

}

export interface IUtteranceModifierAction {

}

export interface IUtteranceModifierFilter {
    rules: IUtteranceModifierFilterRule[];
}

export interface IUtteranceModifierFilterRule {

}

export interface IUtteranceModifierFilterAttributeRule extends IUtteranceModifierFilterRule {

}

export interface IUtteranceModifierFilterStructureRule extends IUtteranceModifierFilterRule {

}

export interface IUtteranceModifierFilterTextContentRule extends IUtteranceModifierFilterRule {

}


export interface ICustomSpeechFragment extends IContentBlockSpeechFragment {
    lang?: string;
    altText?: string;
    descriptionText?: string;
}

