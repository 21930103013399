/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 0.33.4
 */
'use strict';
(function (r, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-base'], function (a0) { return f(r, a0); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(r, require('./colibrio-core-base')); } else { r.colibrio = r.colibrio || {}; r.colibrio.core = r.colibrio.core || {}; r.colibrio.core.io = r.colibrio.core.io || {}; r.colibrio.core.io.base = f(r, colibrio.core.base); } }(typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function(__self, __dep__colibrio_core_base) { var api = {}; (function() { 'use strict';var c,d="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},e;if("function"==typeof Object.setPrototypeOf)e=Object.setPrototypeOf;else{var f;a:{var g={c:!0},h={};try{h.__proto__=g;f=h.c;break a}catch(a){}f=!1}e=f?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var k=e;var l=__dep__colibrio_core_base;var m=new Uint16Array(1);m[0]=4660;new Uint8Array(m.buffer);function n(a,b){return l.ColibrioError.call(this,"colibrio.core.io",a,b)||this}var p=l.ColibrioError;n.prototype=d(p.prototype);n.prototype.constructor=n;if(k)k(n,p);else for(var q in p)if("prototype"!=q)if(Object.defineProperties){var r=Object.getOwnPropertyDescriptor(p,q);r&&Object.defineProperty(n,q,r)}else n[q]=p[q];n.a=p.prototype;function t(a,b,y,z){this.errorType=a;this.errorMessage=b;this.url=y;this.a=z}c=t.prototype;c.asArrayBuffer=function(){return u(this)};c.asBlob=function(){return u(this)};c.asDataStream=function(){return u(this)};c.asString=function(){return u(this)};c.asUint8Array=function(){return u(this)};c.exists=function(){return!1};c.getMediaType=function(){};c.getPath=function(){return this.url.pathname};c.getSize=function(){};c.getUrl=function(){return this.url};c.isFakeUrl=function(){return this.a};
function u(a){return Promise.reject(new n(a.errorType,(a.a?a.url.pathname:a.url.toString())+" : "+a.errorMessage))};function v(a){this.a=a;this.b=0;this.length=a.length}v.prototype.next=function(){return this.a[this.b++]};v.prototype.peek=function(){return this.a[this.b]};function w(a,b){this.f=a;this.b=b}w.prototype.next=function(){if(void 0!==this.a){var a=this.a;this.a=void 0;return a}return x(this)};w.prototype.peek=function(){void 0===this.a&&(this.a=x(this));return this.a};function x(a){for(var b;void 0!==(b=a.f.next())&&!a.b(b););return b};api={ArrayInputStream:v,FilterInputStream:w,IoError:n,IoErrorType:{RESOURCE_NOT_FOUND:"RESOURCE_NOT_FOUND",RESOURCE_ENCRYPTED:"RESOURCE_ENCRYPTED"},InvalidResource:t,MediaType:{APPLICATION_EPUB_ZIP:"application/epub+zip",APPLICATION_SMIL:"application/smil+xml",APPLICATION_NCX:"application/x-dtbncx+xml",APPLICATION_OPF_PACKAGE:"application/oebps-package+xml",APPLICATION_PDF:"application/pdf",APPLICATION_XHTML:"application/xhtml+xml",APPLICATION_VND_MS_OPENTYPE:"application/vnd.ms-opentype",APPLICATION_XML:"application/xml",
APPLICATION_ZIP:"application/zip",AUDIO_MPEG:"audio/mpeg",AUDIO_MP4:"audio/mp4",AUDIO_OGG:"audio/ogg",AUDIO_WEBM:"audio/webm",AUDIO_WAV:"audio/wav",AUDIO_WAVE:"audio/wave",FONT_OTF:"font/opentype",FONT_TTF:"font/truetype",FONT_WOFF:"font/woff",FONT_WOFF2:"font/woff2",IMAGE_BMP:"image/bmp",IMAGE_PNG:"image/png",IMAGE_JPEG:"image/jpeg",IMAGE_SVG:"image/svg+xml",IMAGE_GIF:"image/gif",TEXT_CSS:"text/css",TEXT_JAVASCRIPT:"text/javascript",TEXT_HTML:"text/html",VIDEO_MP4:"video/mp4",VIDEO_WEBM:"video/webm"},
MediaTypeCategory:{FONT:"FONT",IMAGE:"IMAGE",VIDEO:"VIDEO",AUDIO:"AUDIO"}}; }).call(__self); return api; }));
