import { readerQueryInput } from './ReaderInput';
import posthog from 'posthog-js';

const ENABLED = false;
const POSTHOG_API_KEY = 'phc_oxll0ImUFkDrPzdXJCIWY9Sxp193RupTrTIrysuKAyv';
const POSTHOG_HOST = 'https://posthog.prod-europe.demarque.dev';

export function initPosthog() {
  if (ENABLED) {
    posthog.init(POSTHOG_API_KEY, {
      api_host: POSTHOG_HOST,
      autocapture: false,
    });
  }
}

const flattenObj = (ob: any) => {
  let result: any = {};
  for (const i in ob) {
    if (typeof ob[i] === 'object') {
      const temp = flattenObj(ob[i]);
      for (const j in temp) {
        result[i + '-' + j] = temp[j];
      }
    } else {
      result[i] = ob[i];
    }
  }
  return result;
};

export function captureEvent(eventName: string, properties = {}) {
  if (ENABLED) {
    let event = {
      colibrio: {
        publicationProvider: readerQueryInput.providerId,
        tokenIssuer: readerQueryInput.issuer,
        ...properties,
      },
    };

    posthog.capture(eventName, flattenObj(event));
  }
}
