/*
 * Some assets (epub, pdf's) might come obfuscated.
 * Use this file to add the domains for which obfuscation
 * must be decoded when fetching content.
 */

type obfuscationType = 'BITWISE_NOT' | 'NONE';

interface ObfuscationConfiguration {
  prefix: string;
  obfuscation: obfuscationType;
}

// Add URL prefixes here for obfuscation
// Note that the prefix is to be contained in the
// actual source of the obfuscated assets, not
// in the URL before rewriting, if any.
const obfuscationConfs: ObfuscationConfiguration[] = [
  // when testing the obfuscation proxy locally
  {
    prefix: 'http://localhost/webreader/',
    obfuscation: 'BITWISE_NOT',
  },
  {
    prefix: 'https://orp.cantook.net/webreader/',
    obfuscation: 'BITWISE_NOT',
  },
  {
    prefix: 'https://orp.cantook.net/webreader-s3/',
    obfuscation: 'BITWISE_NOT',
  },
];

export function getObfuscationType(url: string): obfuscationType {
  for (const conf of obfuscationConfs) {
    if (url.startsWith(conf.prefix)) {
      return conf.obfuscation;
    }
  }
  return 'NONE';
}

export function unobfuscate(
  obfuscation: obfuscationType,
  view: Uint8Array
): void {
  switch (obfuscation) {
    case 'BITWISE_NOT': {
      for (let i = 0; i < view.length; i++) {
        view[i] = ~view[i];
      }
      break;
    }
  }
}
