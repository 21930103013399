/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import { HttpRequest } from './HttpRequest';
import { MediaTypeDetector } from '../../../lib/colibrio-publishing-framework/colibrio-core-io-mediatypedetector';
import { IResourceMetadata } from '../IResourceMetadata';

export class HttpHeaderReader {
  static fromUrl(url: string): Promise<IResourceMetadata> {
    // Try fetch some data and figure out the format
    return HttpRequest.sendHeadRequest(url).then(response => {
      return HttpRequest.sendRangeRequest(
        url,
        0,
        Math.min(response.contentLength, 16000)
      ).then(buffer => {
        let mediaType = MediaTypeDetector.detectFromUint8Array(
          new Uint8Array(buffer)
        );

        if (!mediaType) {
          throw new Error('Unable to detect the media-type');
        }

        return {
          mediaType: mediaType,
          size: response.contentLength,
        };
      });
    });
  }
}
