/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Share My World AB - All Rights Reserved
 */
/**
 * Safari on iOS ignores the <meta name="viewport"> diretive "user-scalable=no"
 *
 * You can still disable pinch-zoom on iOS but it is a bit tricky.
 * Disable double-tap zoom by setting "touch-action: manipulation" in your CSS. (Safari only supports the "manipulation" keyword)
 *
 * Disable pinch-zoom gesture by calling event.preventDefault() on "touchmove",
 * but only if the listener is added to an element descendant of <body>
 *
 * This helper class allows you to disable native pinch-zoom by listening on "touchmove" events on all child elements
 * of the document.body element.
 *
 * Note that if your app does not take up the full height of the viewport, you must pass true to the constructor to
 * insert a dummy full-height div so that the body element never is the event target.
 *
 * Also note that you don't need to use this when building a native app as you can instruct WKWebView to obey the viewport meta tag.
 */
export class IosNativePinchZoomPreventer {
  private dummyFullHeightDiv: HTMLElement | null;
  private enabled: boolean = false;
  private handledElements: Set<Element> = new Set();
  private observer: MutationObserver | null = null;

  private pinchZoomPreventer = (evt: Event) => {
    if (evt.cancelable && (evt as TouchEvent).touches.length >= 2) {
      evt.preventDefault();
    }
  };

  /**
   * @param useDummyFullHeightDiv - Set to true if your app does not take up the full height of the browser viewport.
   */
  constructor(useDummyFullHeightDiv?: boolean) {
    if (useDummyFullHeightDiv) {
      this.dummyFullHeightDiv = document.createElement('div');
      this.dummyFullHeightDiv.style.position = 'absolute';
      this.dummyFullHeightDiv.style.zIndex = '' + Number.MIN_SAFE_INTEGER;
      this.dummyFullHeightDiv.style.top = '0';
      this.dummyFullHeightDiv.style.bottom = '0';
      this.dummyFullHeightDiv.style.left = '0';
      this.dummyFullHeightDiv.style.right = '0';
      this.dummyFullHeightDiv.style.opacity = '0';
    } else {
      this.dummyFullHeightDiv = null;
    }
  }

  /**
   * Enables the pinch-zoom preventer on all child elements currently added to document.body.
   * You can call it multiple times to refresh the list of elements that are handled.
   */
  enable() {
    this.disable();
    this.enabled = true;

    if (this.dummyFullHeightDiv) {
      document.body.appendChild(this.dummyFullHeightDiv);
    }

    let childNodes = document.body.childNodes;

    for (let i = 0; i < childNodes.length; i++) {
      let currentChild = childNodes[i];
      if (currentChild.nodeType === 1) {
        this.addElement(currentChild as Element);
      }
    }
  }

  /**
   * Disables the pinch-zoom preventer
   */
  disable() {
    if (!this.enabled) {
      return;
    }
    this.enabled = false;

    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }

    this.handledElements.forEach(element =>
      element.removeEventListener('touchmove', this.pinchZoomPreventer)
    );
    this.handledElements.clear();

    if (this.dummyFullHeightDiv && this.dummyFullHeightDiv.parentNode) {
      this.dummyFullHeightDiv.parentNode.removeChild(this.dummyFullHeightDiv);
    }
  }

  /**
   * Enables the pinch-zoom preventer for all children currently added to document.body.
   * Also creates a MutationObserver to detect when child elements are added/removed from document.body.
   */
  observe() {
    this.enable();
    this.observer = new MutationObserver((mutations: MutationRecord[]) => {
      for (let mutation of mutations) {
        if (mutation.type === 'childList') {
          for (let i = 0; i < mutation.removedNodes.length; i++) {
            let removedNode = mutation.removedNodes[i];
            if (removedNode.nodeType === 1) {
              this.removeElement(removedNode as Element);
            }
          }
          for (let i = 0; i < mutation.addedNodes.length; i++) {
            let addedNode = mutation.addedNodes[i];
            if (addedNode.nodeType === 1) {
              this.addElement(addedNode as Element);
            }
          }
        }
      }
    });

    this.observer.observe(document.body, { childList: true, subtree: false });
  }

  addElement(element: Element): void {
    if (!this.handledElements.has(element)) {
      this.handledElements.add(element);
      element.addEventListener('touchmove', this.pinchZoomPreventer);
    }
  }

  removeElement(element: Element): void {
    if (this.handledElements.has(element)) {
      this.handledElements.delete(element);
      element.removeEventListener('touchmove', this.pinchZoomPreventer);
    }
  }
}
