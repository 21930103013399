/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import { IHttpHeadResponse } from './IHttpHeadResponse';

export class HttpRequest {
  static sendRangeRequest(
    url: string,
    start: number,
    end: number
  ): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((resolve, reject) => {
      let request = HttpRequest.createRequest('GET', url, reject);

      request.setRequestHeader('Range', `bytes=${start}-${end}`);
      request.onload = function(_event) {
        resolve(this.response);
      };
      request.send();
    });
  }

  static sendHeadRequest(url: string): Promise<IHttpHeadResponse> {
    return new Promise<IHttpHeadResponse>((resolve, reject) => {
      let request = HttpRequest.createRequest('HEAD', url, reject);

      request.onload = function(_event) {
        try {
          let contentLengthStr = this.getResponseHeader('Content-Length');
          if (!contentLengthStr) {
            reject('Missing Content-Length header');
            return;
          }
          let contentLength = parseInt(contentLengthStr, 10);
          if (isNaN(contentLength)) {
            reject('Invalid Content-Length: ' + contentLengthStr);
          }

          resolve({
            contentLength: contentLength,
            contentType: this.getResponseHeader('Content-Type'),
          });
        } catch (e) {
          reject(e);
        }
      };

      request.send();
    });
  }

  private static createRequest(
    verb: 'GET' | 'HEAD',
    url: string,
    rejectFn: (arg?: any) => void
  ): XMLHttpRequest {
    let request = new XMLHttpRequest();
    request.open(verb, url, true);
    request.responseType = 'arraybuffer';

    request.onerror = function(_event) {
      rejectFn(this.status + ': ' + this.statusText);
    };

    request.onabort = function(_event) {
      rejectFn(this.status + ': ' + this.statusText);
    };

    request.ontimeout = function(_event) {
      rejectFn('TIMEOUT');
    };

    return request;
  }
}
