import Vue from 'vue';
import { GlobalEventName } from './GlobalEventName';
import { PublicationState } from './model/PublicationState';

export interface ReaderError {
  translationKey: string;
}

class EventEmitter extends Vue {
  public emitStateChange(state: PublicationState) {
    this.$emit(GlobalEventName.PUBLICATION_STATE_CHANGED, state);
  }

  public emit(eventName: GlobalEventName) {
    this.$emit(eventName);
  }
}

export let GlobalEventBus = new EventEmitter();
