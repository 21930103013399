/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import { readerModel } from './ReaderModel';
import { IUserAnnotationHighlight } from './IUserAnnotation';
import { IReaderPublicationNavigationItemReference } from '../lib/colibrio-publishing-framework/colibrio-readingsystem-base';
import {
  AnnotationSelectorType,
  IAnnotationTarget,
} from '../lib/colibrio-publishing-framework/colibrio-core-annotation';

export class AnnotationUtils {
  static getBodyHighlightText(annotation: IUserAnnotationHighlight): string {
    let highlightBody = annotation.body.find(body => {
      return body.purpose === 'highlighting';
    });
    return highlightBody ? highlightBody.value : '';
  }

  static getBodyCommentText(annotation: IUserAnnotationHighlight): string {
    let commentBody = annotation.body.find(body => {
      return body.purpose === 'commenting';
    });
    return commentBody ? commentBody.value : '';
  }

  static getTargetHighlightColor(annotation: IUserAnnotationHighlight): string {
    if (!annotation.stylesheet) {
      return readerModel.annotationStyling.annotationHighlightColorDefault;
    }
    let annotationCss: string = annotation.stylesheet.value;
    let expression = `\\.${annotation.target.styleClass}\\s*{\\s*([\\s\\S]*?)}`;
    let regexp = new RegExp(expression);
    let match = regexp.exec(annotationCss);
    if (!match || !match[1]) {
      return readerModel.annotationStyling.annotationHighlightColorDefault;
    }
    let backgroundColorMatch = /background-color:\s*(.*?)(?:;|$|\n)/.exec(
      match[1]
    );
    if (!backgroundColorMatch || !backgroundColorMatch[1]) {
      return readerModel.annotationStyling.annotationHighlightColorDefault;
    }

    return backgroundColorMatch[1].trim();
  }

  static async fetchNavigationItemReferences(
    annotation: IUserAnnotationHighlight
  ): Promise<IReaderPublicationNavigationItemReference[]> {
    let readingSystem = readerModel.getReadingSystem();

    if (!readingSystem) {
      return [];
    }

    let mainView = readingSystem.getViewByName('mainView');

    if (!mainView) {
      return [];
    }

    let annotationTarget: IAnnotationTarget = {
      selector: {
        type: AnnotationSelectorType.FRAGMENT_SELECTOR,
        value: annotation.target.selector.value,
      },
    };

    let locationTarget = await mainView
      .getReaderPublications()[0]
      .resolveToDocumentLocationTarget(annotationTarget);
    return locationTarget.getReaderDocument().fetchNavigationItemReferences();
  }

  static async fetchRootNavigationItemTitle(
    annotation: IUserAnnotationHighlight
  ): Promise<string | undefined> {
    let readingSystem = readerModel.getReadingSystem();

    if (!readingSystem) {
      return undefined;
    }

    let mainView = readingSystem.getViewByName('mainView');

    if (!mainView) {
      return undefined;
    }

    let annotationTarget: IAnnotationTarget = {
      selector: {
        type: AnnotationSelectorType.FRAGMENT_SELECTOR,
        value: annotation.target.selector.value,
      },
    };

    let locationTarget = await mainView
      .getReaderPublications()[0]
      .resolveToDocumentLocationTarget(annotationTarget);
    let refs = await locationTarget
      .getReaderDocument()
      .fetchNavigationItemReferences();

    return refs.length > 0
      ? refs[0].getNavigationItem().textContent
      : undefined;
  }
}
