/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 0.33.4
 */
'use strict';
(function (r, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-base', './colibrio-core-io-base'], function (a0, a1) { return f(r, a0, a1); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(r, require('./colibrio-core-base'), require('./colibrio-core-io-base')); } else { r.colibrio = r.colibrio || {}; r.colibrio.core = r.colibrio.core || {}; r.colibrio.core.annotation = f(r, colibrio.core.base, colibrio.core.io.base); } }(typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function(__self, __dep__colibrio_core_base, __dep__colibrio_core_io_base) { var api = {}; (function() { 'use strict';api={AnnotationSelectorType:{CSS_SELECTOR:"CssSelector",FRAGMENT_SELECTOR:"FragmentSelector",RANGE_SELECTOR:"RangeSelector",SVG_SELECTOR:"SvgSelector",TEXT_QUOTE_SELECTOR:"TextQuoteSelector",TEXT_POSITION_SELECTOR:"TextPositionSelector",XPATH_SELECTOR:"XPathSelector"},AnnotationTargetSideBias:{AFTER:"AFTER",BEFORE:"BEFORE"},FragmentSelectorType:{HTML:"http://tools.ietf.org/rfc/rfc3236",PDF:"http://tools.ietf.org/rfc/rfc3778",PLAIN_TEXT:"http://tools.ietf.org/rfc/rfc5147",XML:"http://tools.ietf.org/rfc/rfc3023",
RDF_XML:"http://tools.ietf.org/rfc/rfc3870",CSV:"http://tools.ietf.org/rfc/rfc7111",MEDIA:"http://www.w3.org/TR/media-frags/",SVG:"http://www.w3.org/TR/SVG/",EPUB3:"http://www.idpf.org/epub/linking/cfi/epub-cfi.html"}}; }).call(__self); return api; }));
