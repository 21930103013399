/**
 * The entry point to the reader.
 *
 * @packageDocumentation
 */

import Vue from 'vue';
import Component from 'vue-class-component';
import { GlobalEventBus } from '../../GlobalEventBus';
import { GlobalEventName } from '../../GlobalEventName';
import { CheckpointManager, Locator } from '../../model/Checkpoint';
import { publicationManager } from '../../model/PublicationManager';
import {
  DefaultPublicationState,
  PublicationState,
} from '../../model/PublicationState';
import { readerQueryInput } from '../../model/ReaderInput';
import DemarqueLoader from '../colibrio-loader/demarque-loader.vue';
import ColibrioPublicationNav from './publication-nav/colibrio-publication-nav.vue';
import ColibrioPublicationSettings from './publication-settings/colibrio-publication-settings.vue';
import ColibrioPublicationView from './publication-view/colibrio-publication-view.vue';
import ReaderMessagebox from './reader-messagebox.vue';
import { captureEvent } from '../../model/posthog';

/**
 * This class has been heavily modified from the original Colibrio's demo app in
 * order to load the publication using PublicationManager instead of displaying
 * a menu to open files or URLs'. This is the main container and the entry point
 * of the application.
 *
 * It contains the following components:
 *
 * 1) The header toolbar, including information, some action buttons and drawer buttons.
 *
 * 2) The navigations and settings drawers.
 *
 * 3) The viewer itself (where the publication is displayed), *which contains the
 * bottom bar with its own actions*.
 *
 * 4) Many dialog boxes.
 *
 */
@Component({
  components: {
    DemarqueLoader,
    ColibrioPublicationNav,
    ColibrioPublicationView,
    ColibrioPublicationSettings,
    ReaderMessagebox,
  },
})
export default class ColibrioApp extends Vue {
  /**
   * Constrols wheter the navigation drawer is being displayed.
   */
  public internalNavDrawerVisible: boolean = false;

  /**
   * Constrols wheter the settings drawer is being displayed.
   */
  public internalSettingsDrawerVisible: boolean = false;

  /**
   * A copy of the state of the publication.
   */
  public state: PublicationState = new DefaultPublicationState();

  /**
   * Constrols wheter the loading error dialog is being displayed.
   */
  public loadErrorDialog = false;

  /**
   * Constrols wheter the return error dialog is being displayed.
   */
  public returnedDialog = false;

  /**
   * Constrols wheter the return confirmation dialog is being displayed.
   */
  public confirmReturnDialog = false;

  /**
   * The Checkpoint Manager : access to the checkpoint store (last reading
   * location).
   */
  public checkpointManager: CheckpointManager;

  /**
   * The point in the publication where the viewer should be set (i.e. to
   * continue reading from the last stored location).
   */
  public initialLocator?: Locator;

  /**
   * Controls whether the 'downloaded' message dialog is being displayed.
   */
  public downloadedDialog: null | boolean = false;

  public constructor() {
    super();
    this.checkpointManager = new CheckpointManager();
    this.initialLocator = this.checkpointManager.getLastVisitedLocatorLocally();
  }

  public mounted() {
    document.title = this.$i18n.t('title') as string;
    this.setEventHandling();
    publicationManager.loadPublication();
  }

  /**
   * Handles the "download publication" button click.
   */
  public onDownloadClick(): void {
    // download book locally for offline reading
    captureEvent('download-publication');
    publicationManager.downloadFullPublication();
  }

  /**
   * Handles the "return publication" button click.
   */
  public onReturnClick(): void {
    this.confirmReturnDialog = true;
  }

  /**
   * Handles an event from the settings button to toggle the settings drawer.
   */
  public onToggleSettingsDrawer(): void {
    this.internalSettingsDrawerVisible =
      !this.internalSettingsDrawerVisible && this.state.state === 'presenting';
    if (this.settingsDrawerVisible) {
      this.settingsDrawerOpen();
    } else {
      this.settingsDrawerClose();
    }
  }

  /**
   * Handles an event from the nav button to toggle the nav drawer.
   */
  public onToggleNavDrawer(): void {
    this.internalNavDrawerVisible =
      !this.internalNavDrawerVisible && this.state.state === 'presenting';
    if (this.navDrawerVisible) {
      this.navDrawerOpen();
    } else {
      this.navDrawerClose();
    }
  }

  /**
   * Return the publication.
   */
  public returnConfirmed(): void {
    this.confirmReturnDialog = false;
    captureEvent('return-publication');
    publicationManager.returnPublication();
  }

  /**
   * Close the window, if possible.
   */
  public exitWindow(): void {
    if (this.canCloseApp()) {
      window.close();
    }
  }

  /**
   * Wheter one of our input parameters for a manifest url is set.
   */
  public hasManifestParameter(): boolean {
    return !!(
      readerQueryInput.authenticatedManifestUrl ||
      readerQueryInput.transactionId
    );
  }

  /**
   * Return the a message for the result of returning a book.
   */
  public returnedMessage(): string {
    return this.$t('info.returned.ok').toString();
  }

  private setEventHandling() {
    // MENUS
    GlobalEventBus.$on(
      GlobalEventName.APP_SETTINGS_DRAWER_OPEN_INTENT,
      this.settingsDrawerOpen
    );
    GlobalEventBus.$on(
      GlobalEventName.APP_SETTINGS_DRAWER_CLOSE_INTENT,
      this.settingsDrawerClose
    );

    GlobalEventBus.$on(
      GlobalEventName.APP_NAV_DRAWER_OPEN_INTENT,
      this.navDrawerOpen
    );

    GlobalEventBus.$on(
      GlobalEventName.APP_NAV_DRAWER_CLOSE_INTENT,
      this.navDrawerClose
    );

    GlobalEventBus.$on(
      GlobalEventName.APP_NAV_DRAWER_OPENED,
      this.navDrawerOpen
    );
    GlobalEventBus.$on(
      GlobalEventName.APP_NAV_DRAWER_CLOSED,
      this.navDrawerClose
    );
    GlobalEventBus.$on(
      GlobalEventName.APP_SETTINGS_DRAWER_OPENED,
      this.settingsDrawerOpen
    );
    GlobalEventBus.$on(
      GlobalEventName.APP_SETTINGS_DRAWER_CLOSED,
      this.settingsDrawerClose
    );

    GlobalEventBus.$on(GlobalEventName.APP_NAV_DRAWER_NAV_ITEM_CLICKED, () => {
      this.navDrawerVisible = false;
    });

    // State of the publication changed
    GlobalEventBus.$on(
      GlobalEventName.PUBLICATION_STATE_CHANGED,
      (newState: PublicationState) => {
        // show dialogs if the state changed
        if (this.state.state !== 'failed' && newState.state === 'failed') {
          this.loadErrorDialog = true;
        }
        if (this.state.state !== 'returned' && newState.state === 'returned') {
          this.returnedDialog = true;
        }
        if (
          this.state.download_state !== 'downloaded' &&
          newState.download_state === 'downloaded'
        ) {
          this.downloadedDialog = true;
        }

        if (newState.title) {
          document.title = `${this.$i18n.t('title') as string} - ${
            newState.title
          }`;
        }
        // make a copy
        this.state = JSON.parse(JSON.stringify(newState));
      }
    );
  }

  // Drawers: all code concerning opening and closing drawers.
  get navDrawerVisible(): boolean {
    return this.internalNavDrawerVisible;
  }

  set navDrawerVisible(value: boolean) {
    if (this.internalNavDrawerVisible === value) {
      return;
    }

    this.internalNavDrawerVisible = value;
    if (value) {
      GlobalEventBus.$emit(GlobalEventName.APP_NAV_DRAWER_OPENED);
    } else {
      GlobalEventBus.$emit(GlobalEventName.APP_NAV_DRAWER_CLOSED);
    }
  }

  get settingsDrawerVisible(): boolean {
    return this.internalSettingsDrawerVisible;
  }

  set settingsDrawerVisible(value: boolean) {
    if (this.internalSettingsDrawerVisible === value) {
      return;
    }

    this.internalSettingsDrawerVisible = value;
    if (value) {
      GlobalEventBus.$emit(GlobalEventName.APP_SETTINGS_DRAWER_OPENED);
    } else {
      GlobalEventBus.$emit(GlobalEventName.APP_SETTINGS_DRAWER_CLOSED);
    }
  }

  private navDrawerClose() {
    const drawer = (this.$refs.colibrioPublicationNavDrawer as Vue)
      .$el as HTMLElement;
    this.navDrawerVisible = false;
    drawer.style.visibility = 'hidden';
  }

  private navDrawerOpen() {
    const drawer = (this.$refs.colibrioPublicationNavDrawer as Vue)
      .$el as HTMLElement;
    drawer.style.visibility = 'visible';
    this.navDrawerVisible = true;
    window.setTimeout(() => {
      drawer.focus();
    }, 500);
  }

  private settingsDrawerClose() {
    const drawer = (this.$refs.colibrioPublicationSettingsDrawer as Vue)
      .$el as HTMLElement;
    this.settingsDrawerVisible = false;
    drawer.style.visibility = 'hidden';
  }

  private settingsDrawerOpen() {
    const drawer = (this.$refs.colibrioPublicationSettingsDrawer as Vue)
      .$el as HTMLElement;
    drawer.style.visibility = 'visible';
    this.settingsDrawerVisible = true;
    window.setTimeout(() => {
      drawer.focus();
    }, 500);
  }
  // Extras
  private canCloseApp() {
    return !!window.opener;
  }
}
