import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { GlobalEventBus } from '../../../GlobalEventBus';
import { GlobalEventName } from '../../../GlobalEventName';
import { IReaderPublicationNavigationItem } from '../../../lib/colibrio-publishing-framework/colibrio-readingsystem-base';

@Component({
  components: {
    ColibrioPublicationNavItemTree,
  },
})
export default class ColibrioPublicationNavItemTree extends Vue {
  @Prop()
  public navItem!: IReaderPublicationNavigationItem;

  public goToNavItem() {
    if (this.navItem.annotationTarget) {
      GlobalEventBus.$emit(
        GlobalEventName.APP_NAV_DRAWER_NAV_ITEM_CLICKED,
        this.navItem.annotationTarget
      );
    }
  }

  public goToChildNavItem(childNavItem: IReaderPublicationNavigationItem) {
    if (childNavItem.annotationTarget) {
      GlobalEventBus.$emit(
        GlobalEventName.APP_NAV_DRAWER_NAV_ITEM_CLICKED,
        childNavItem.annotationTarget
      );
    }
  }
}
