import Vue from 'vue';
import Component from 'vue-class-component';
import { GlobalEventBus } from '../../../GlobalEventBus';
import { GlobalEventName } from '../../../GlobalEventName';
import { readerModel } from '../../../model/ReaderModel';
import {
  TextDocumentQuery,
  ITextDocumentQueryOptions,
  IDocumentQueryResultItem,
  IDocumentQueryResultPage,
  IDocumentQueryResultSet,
  IDocumentQueryResultTarget,
  IPublicationQueryResultSet,
} from '../../../lib/colibrio-publishing-framework/colibrio-readingsystem-indexengine';
import { captureEvent } from '../../../model/posthog';
import {
  IReaderDocument,
  IReaderDocumentViewEngineEvent,
  IReadingSystemEngine,
  IViewAnnotationLayer,
  IViewAnnotationTarget,
} from '../../../lib/colibrio-publishing-framework/colibrio-readingsystem-base';
import { IAnnotationTarget } from '../../../lib/colibrio-publishing-framework/colibrio-core-annotation';
import { Logger } from '../../../lib/colibrio-publishing-framework/colibrio-core-base';

@Component({
  components: {
    ColibrioPublicationSearchPanel,
  },
})
export default class ColibrioPublicationSearchPanel extends Vue {
  searchTerm: string = '';
  executedQuery: string = '';
  searching = false;
  searchCounter = 0;
  currentSearchResultSet: IDocumentQueryResultSet | undefined = undefined;
  currentSearchResultPage: IDocumentQueryResultPage | undefined = undefined;
  readerDocumentLoadListenersAdded = false;
  searchResultAnnotationLayer: IViewAnnotationLayer | undefined = undefined;
  readerDocumentsToSearchResultAnnotationTargets:
    | Map<IReaderDocument, IViewAnnotationTarget[]>
    | undefined = undefined;
  currentSearchResultPageItems: {
    id: number;
    item: IDocumentQueryResultItem;
  }[] = [];

  doSearch(query: string): void {
    if (query === this.executedQuery) {
      return;
    }
    if (query === '') {
      this.clearSearch();
    } else {
      if (this.searchResultAnnotationLayer) {
        this.searchResultAnnotationLayer.destroy();
        this.searchResultAnnotationLayer = undefined;
      }
      this.readerDocumentsToSearchResultAnnotationTargets = undefined;
      this.searchCounter++;
      this.searching = true;
      this.currentSearchResultSet = undefined;
      this.currentSearchResultPage = undefined;
      this.currentSearchResultPageItems = [];
      this.executedQuery = query;

      let mainView = readerModel.getReadingSystem().getViewByName('mainView');
      if (!mainView) {
        throw 'Could not find view by name "mainView"';
      }
      mainView.getLoadedReaderDocuments().forEach((readerDocument) => {
        this.highlightSearchQueryOnReaderDocument(query, readerDocument).catch(
          (err) => {
            Logger.logError(err);
          }
        );
      });
      if (!this.readerDocumentLoadListenersAdded) {
        this.readerDocumentLoadListenersAdded = true;
        mainView.addEngineEventListener('readerDocumentLoadedInView', this);
        mainView.addEngineEventListener('readerDocumentUnloadedFromView', this);
      }

      this.executeSearchQueryInPublication(query).then(
        (resultSet: IDocumentQueryResultSet) => {
          if (query !== this.executedQuery) {
            // User changed the query
            return;
          }
          this.currentSearchResultSet = resultSet;
          this.populateNextSearchResultPage();
          captureEvent('search');
        }
      );
    }
  }

  clearSearch(): void {
    this.searchCounter++;
    this.searching = false;
    this.currentSearchResultSet = undefined;
    this.currentSearchResultPage = undefined;
    this.currentSearchResultPageItems = [];
    this.searchTerm = '';
    this.executedQuery = '';
    if (this.readerDocumentLoadListenersAdded) {
      this.readerDocumentLoadListenersAdded = false;
      let mainView = readerModel.getReadingSystem().getViewByName('mainView');
      if (mainView) {
        mainView.removeEngineEventListener('readerDocumentLoadedInView', this);
        mainView.removeEngineEventListener(
          'readerDocumentUnloadedFromView',
          this
        );
      }
    }
    if (this.searchResultAnnotationLayer) {
      this.searchResultAnnotationLayer.destroy();
      this.searchResultAnnotationLayer = undefined;
    }
    this.readerDocumentsToSearchResultAnnotationTargets = undefined;
  }

  handleEngineEvent(event: IReaderDocumentViewEngineEvent) {
    switch (event.type) {
      case 'readerDocumentLoadedInView':
        this.highlightSearchQueryOnReaderDocument(
          this.executedQuery,
          event.readerDocument
        ).catch((err) => {
          Logger.logError(err);
        });
        break;

      case 'readerDocumentUnloadedFromView':
        if (this.readerDocumentsToSearchResultAnnotationTargets) {
          let renderedAnnotations =
            this.readerDocumentsToSearchResultAnnotationTargets.get(
              event.readerDocument
            );
          if (renderedAnnotations) {
            renderedAnnotations.forEach((renderedAnnotation) =>
              renderedAnnotation.destroy()
            );
            this.readerDocumentsToSearchResultAnnotationTargets.delete(
              event.readerDocument
            );
          }
        }
        break;
    }
  }

  highlightTerm(
    resultTarget: IDocumentQueryResultTarget,
    offset: number
  ): string {
    let matchedStringStartOffset = resultTarget.charOffset;
    let matchedStringEndOffset =
      matchedStringStartOffset + resultTarget.charLength;
    let textContent = resultTarget.contentBlock.textContent;

    let ellipsisBefore: string;
    let resultStartOffset: number;
    if (offset < matchedStringStartOffset) {
      resultStartOffset = matchedStringStartOffset - offset;
      ellipsisBefore = '...';
    } else {
      resultStartOffset = 0;
      ellipsisBefore = '';
    }

    let ellipsisAfter: string;
    let resultEndOffset: number;
    if (offset < textContent.length - matchedStringStartOffset) {
      resultEndOffset = matchedStringEndOffset + offset;
      ellipsisAfter = '...';
    } else {
      resultEndOffset = textContent.length;
      ellipsisAfter = '';
    }

    // htmlEncode so we don't open up the app for an XSS attack!
    let textBefore = this.htmlEncode(
      textContent.slice(resultStartOffset, matchedStringStartOffset)
    );
    let matchedText = this.htmlEncode(
      textContent.slice(matchedStringStartOffset, matchedStringEndOffset)
    );
    let textAfter = this.htmlEncode(
      textContent.slice(matchedStringEndOffset, resultEndOffset)
    );
    let spanTagStart =
      '<span class="colibrio-publication-search-panel-query-term">';
    let spanTagEnd = '</span>';

    return (
      ellipsisBefore +
      textBefore +
      spanTagStart +
      matchedText +
      spanTagEnd +
      textAfter +
      ellipsisAfter
    );
  }

  private htmlEncode(html: string) {
    return html.replace(/[<>]/g, function (match) {
      if (match === '<') {
        return '&lt;';
      } else {
        return '&gt;';
      }
    });
  }

  populateSearchResultPageByIndex(pageIndex: number): void {
    if (this.currentSearchResultSet) {
      if (
        pageIndex >= 0 &&
        pageIndex < this.currentSearchResultPageItems.length
      ) {
        this.currentSearchResultSet
          .fetchPage(pageIndex)
          .then((resultPage: IDocumentQueryResultPage | null) => {
            this.currentSearchResultPage = resultPage || undefined;
            if (resultPage) {
              let i: number = 0;
              this.currentSearchResultPageItems = resultPage.resultItems.map(
                (item: IDocumentQueryResultItem) => {
                  return {
                    id: i++,
                    item: item,
                  };
                }
              );
            }
          });
      } else {
        console.log(
          'pageIndex out of range ' + this.currentSearchResultPageItems.length
        );
      }
    } else {
      console.log(
        'ColibrioPublicationSearchPanel.getSearchResultPageBuIndex: No active result set'
      );
    }
  }

  populatePreviousSearchResultPage(): void {
    if (this.currentSearchResultSet) {
      let previousPageIndex: number =
        this.currentSearchResultPage &&
        this.currentSearchResultPage.pageIndex > 0
          ? this.currentSearchResultPage.pageIndex - 1
          : 0;

      this.currentSearchResultSet
        .fetchPage(previousPageIndex)
        .then((previousPage: IDocumentQueryResultPage | null) => {
          this.currentSearchResultPage = previousPage || undefined;
          if (previousPage) {
            let i: number = 0;
            this.currentSearchResultPageItems = previousPage.resultItems.map(
              (item: IDocumentQueryResultItem) => {
                return {
                  id: i++,
                  item: item,
                };
              }
            );
          }
          console.log(previousPage);
        });
    } else {
      console.log(
        'ColibrioPublicationSearchPanel.getNextSearchResultPage: No active result set'
      );
    }
  }

  populateNextSearchResultPage(): void {
    if (this.currentSearchResultSet) {
      this.searching = true;

      let nextPageIndex: number =
        this.currentSearchResultPage &&
        this.currentSearchResultPage.hasMorePages
          ? this.currentSearchResultPage.pageIndex + 1
          : 0;

      let executedSearchQuery = this.executedQuery;
      this.currentSearchResultSet
        .fetchPage(nextPageIndex)
        .then((nextPage: IDocumentQueryResultPage | null) => {
          if (executedSearchQuery !== this.executedQuery) {
            // User changed the query while we fetched the next page.
            return;
          }
          this.searching = false;
          this.currentSearchResultPage = nextPage || undefined;
          if (nextPage) {
            let i: number = 0;
            this.currentSearchResultPageItems = nextPage.resultItems.map(
              (item: IDocumentQueryResultItem) => {
                return {
                  id: i++,
                  item: item,
                };
              }
            );
          }
        });
    } else {
      console.log(
        'ColibrioPublicationSearchPanel.getNextSearchResultPage: No active result set'
      );
    }
  }

  async executeSearchQueryInPublication(
    query: string
  ): Promise<IDocumentQueryResultSet> {
    let searchResultSet: IDocumentQueryResultSet;
    let queryOptions: ITextDocumentQueryOptions = {
      expression: query,
      pageSize: 10,
    };
    let matchQuery = new TextDocumentQuery(queryOptions);
    let publicationResultSet: IPublicationQueryResultSet =
      await readerModel.indexEngine.queryPublications();
    searchResultSet = await publicationResultSet.queryDocuments(matchQuery);

    return searchResultSet;
  }

  async highlightSearchQueryOnReaderDocument(
    query: string,
    readerDocument: IReaderDocument
  ): Promise<void> {
    let queryOptions: ITextDocumentQueryOptions = {
      expression: query,
      pageSize: 4000, // Don't highlight more than 4000 occurrences per reader document
    };

    let currentSearchCounterValue = this.searchCounter;
    let matchQuery = new TextDocumentQuery(queryOptions);
    let documentResultSet: IDocumentQueryResultSet =
      readerModel.indexEngine.queryReaderDocument(readerDocument, matchQuery);

    let resultPage: IDocumentQueryResultPage | null =
      await documentResultSet.fetchPage(0);
    if (
      currentSearchCounterValue !== this.searchCounter ||
      resultPage === null
    ) {
      // Abort if a new search has started
      return;
    }

    let queryResultTargets: IDocumentQueryResultTarget[] = [];
    resultPage.resultItems.forEach((resultItem) => {
      resultItem.targets.forEach((target) => {
        queryResultTargets.push(target);
      });
    });

    let annotationTargetPromises: Promise<IAnnotationTarget>[] =
      queryResultTargets.map((target) => {
        return readerDocument.fetchAnnotationTargetForContentBlock(
          target.contentBlock,
          target.charOffset,
          target.charLength
        );
      });

    let annotationTargets = await Promise.all(annotationTargetPromises);
    if (currentSearchCounterValue !== this.searchCounter) {
      // Abort if a new search has started
      return;
    }

    let renderedAnnotationTargets =
      this.getSearchResultAnnotationLayer().addAnnotationTargets(
        annotationTargets
      );
    if (!this.readerDocumentsToSearchResultAnnotationTargets) {
      this.readerDocumentsToSearchResultAnnotationTargets = new Map();
    }

    this.readerDocumentsToSearchResultAnnotationTargets.set(
      readerDocument,
      renderedAnnotationTargets
    );
  }

  goToSearchResultItem(item: IDocumentQueryResultItem): void {
    let readingSystem: IReadingSystemEngine | null =
      readerModel.getReadingSystem();
    if (!readingSystem) {
      return;
    }
    let target = item.targets[0];
    // Find the publication where the result was found. (We only have one publication so we could just do readerPublications[0] but lets do it to show how to do it when we have many readerPublications.
    let readerPublication = readingSystem
      .getReaderPublications()
      .find(
        (readerPublication) =>
          readerPublication.getSourcePublication().getCanonicalUri() ===
          target.publicationCanonicalUri
      );
    if (readerPublication) {
      // Resolve the documentAnnotationTarget to a readerDocument.
      readerPublication
        .resolveToDocumentLocationTarget(target.documentAnnotationTarget)
        .then((documentLocationTarget) => {
          // Find the annotation target that encapsulates the found search term(s).
          return documentLocationTarget
            .getReaderDocument()
            .fetchAnnotationTargetForContentBlock(
              target.contentBlock,
              target.charOffset,
              target.charLength
            );
        })
        .then((annotationTarget) => {
          GlobalEventBus.$emit(
            GlobalEventName.APP_NAV_DRAWER_NAV_ITEM_CLICKED,
            annotationTarget
          );
        });
    }
  }

  private getSearchResultAnnotationLayer(): IViewAnnotationLayer {
    if (!this.searchResultAnnotationLayer) {
      let mainView = readerModel.getReadingSystem().getViewByName('mainView');
      if (!mainView) {
        throw 'Could not find view by name "mainView"';
      }
      this.searchResultAnnotationLayer =
        mainView.createAnnotationLayer('searchResults');

      let element = document.createElement('div');
      element.style.setProperty('mix-blend-mode', 'multiply');
      let supportMixBlendMode =
        element.style.getPropertyValue('mix-blend-mode') === 'multiply';

      let layerStyles: { [property: string]: string };
      if (supportMixBlendMode) {
        layerStyles = {
          'mix-blend-mode': 'multiply',
        };
      } else {
        layerStyles = {
          opacity: '0.5',
        };
      }

      this.searchResultAnnotationLayer.setOptions({
        annotationTargetRangeStyle: {
          'background-color': 'violet',
        },
        annotationLayerStyle: layerStyles,
      });
    }
    return this.searchResultAnnotationLayer;
  }
}
